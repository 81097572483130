import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { ModelType } from "../../../../types/Database"
import { UserContext } from "../../../../context/UserContext"
import { BackendConfigs } from "../../../../config.environment"
import { toast } from "react-toastify"
import { Button, Card, CardActions, TextField, Tooltip, Typography } from "@mui/material"
import { eduCOBOTColorScheme, SettingContext } from "../../../../context/SettingsContext"
import { ContentDialogBox } from "../../../../components/dialog-box"
import { routes } from "../../../../routes/routes"
import { APIResponse } from "../../../../types/Response"
import { ProjectDataType } from "../../../../types/Dashboard"









type Props = {

}


export default function CreateModel({ }: Props) {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const [projectID, setProjectID] = useState("")
    const [projectData, setProjectData] = useState<ProjectDataType | null>(null)

    async function getProject() {
        console.log(projectID, user.userData);
        if (!!user.userData && !!projectID) {
            handleGlobalLoading(true, "Fetching Project")
            // setProjectData(null)
            try {
                const response = await fetch(`${BackendConfigs.url}/GetProjectByProjectIdAndOwnerIdAPI`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        ownerId: user.userData?.id,
                        projectId: projectID,
                    })
                })
                if (response.status === 200) {
                    const result = await response.json() as APIResponse
                    // toast.success(result.message)
                    // console.log(result.data);
                    setProjectData(result.data)
                } else {
                    navigate(routes.DASHBOARD.MAIN)
                }
            } catch (error) {
                toast.error("Error Fetching Project")
                console.log(error);
            }
            handleGlobalLoading(false)
        }
    }

    useEffect(() => {
        setProjectID(searchParams.get("project_id") ?? "")
        console.log(searchParams.get("project_id"));

    }, [searchParams])

    useEffect(() => {
        if (projectID !== "") {

            getProject()
        }
    }, [projectID])
    const { user } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const [isCreateSuccess, setIsCreateSuccess] = useState(false)
    const [createdModelData, setCreatedModelData] = useState<ModelType>()
    const [models, setModels] = useState<ModelType[]>([])
    const [modelName, setModelName] = useState("")
    const [modelDescription, setModelDescription] = useState("")
    const [modelURL, setModelURL] = useState("")
    const [isModelFound, setIsModelFound] = useState<boolean>(false)

    const [modelData, setModelData] = useState<{
        timeStamp: string
        labels: Array<string>
        imageSize: number
    }
    >({
        "timeStamp": "2024-05-19T10:56:06.595Z",
        "labels": [
            "Shiv",
            "Hand"
        ],
        "imageSize": 224
    })

    async function findModel() {
        handleGlobalLoading(true, "verifying model...")
        try {
            const response = await fetch(modelURL + (modelURL.endsWith("/") ? "metadata.json" : "/metadata.json"))
            const result = await response.json()
            if (!!result) {
                setIsModelFound(true)
                setModelData(result)
            }
        } catch (error) {
            console.log(error);
            setIsModelFound(false)
        }
        handleGlobalLoading(false)
    }
    useEffect(() => {
        if (modelURL) {
            findModel();
        }
    }, [modelURL])



    async function createModel() {
        handleGlobalLoading(true, "Creating Model...")
        if (!isModelFound) {
            toast.error("Model not found at this url")
            return
        }
        if (!modelName) {
            toast.error("model name is required")
            return
        }
        if (!modelDescription) {
            toast.error("model description is required")
            return
        }
        if (!modelURL) {
            toast.error("model url is required")
            return
        }
        if (
            !!modelName || !!modelDescription || modelURL || isModelFound || modelData
        ) {
            if (user.isLogin) {
                try {
                    const response = await fetch(`${BackendConfigs.url}/CreateModelAPI`, {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            ownerId: user.userData?.id,
                            name: modelName,
                            description: modelDescription,
                            labels: modelData.labels,
                            modelURL: modelURL,
                        })
                    })
                    if (response.status === 200) {
                        const data = await response.json() as APIResponse
                        if (data.success) {
                            setCreatedModelData(data.data)
                            console.log(data);

                            toast.success("Model Created Successfully")
                            setModelName("")
                            setModelDescription("")
                            handleGlobalLoading(false)
                            setIsCreateSuccess(true)
                            // setTimeout(() => {
                            //     navigate(routes.DASHBOARD.MODELS)
                            // }, 3000)
                        } else {
                            toast.error("Access Denied")
                        }
                    }
                } catch (error) {
                    console.log(error);
                    toast.error("Access Denied")
                }
            }
        } else {
            toast.warn("Please fill all the details")
        }
        handleGlobalLoading(false)
    }
    return (
        <>
            <Card style={{
                padding: "20px",
            }}>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                }}>
                    <img src="https://cdn-icons-png.flaticon.com/512/6385/6385037.png" style={{
                        maxWidth: "200px",
                    }} alt="" />
                </div>
                <div style={{
                    padding: "10px 30px"
                }}>
                    <ol>
                        <li>Click on Train Model.</li>
                        <li>A new pop-up window will open. Add or capture your data and train the model.</li>
                        <li>Click on Export Model to get a shareable link for the model.</li>
                        <li>Once you have the model link, close the window.</li>
                        <li>Enter the model name and description.</li>
                        <li>Paste the Teachable Machine URL.</li>
                        <li>Verify the class names.</li>
                        <li>Click on Create. Your model will be successfully created.</li>
                    </ol>
                </div>

                <div>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                    }}>


                        <Button onClick={() => {
                            window.open("https://teachablemachine.withgoogle.com/train/image", "Teachable Machine", "width=1600,height=1000");
                        }} size='large' variant="contained" color="primary" sx={{
                            textTransform: "none",
                            borderRadius: "6px",
                            fontWeight: "bolder",
                            fontSize: "15px"
                        }}>
                            Train The Model
                        </Button>
                    </div>
                    <div style={{
                        textAlign: "center",
                    }}>
                        OR
                    </div>
                    <div style={{
                        textAlign: "center",
                    }}>
                        Fill following info directly
                    </div>
                </div>
                <div id="ModelCreateContainer" style={{
                    padding: 10
                }}>
                    <TextField id="ModelName"
                        fullWidth
                        label='Enter Model Name'
                        style={{
                            margin: 10
                        }}
                        value={modelName}
                        onChange={(event) => {
                            setModelName(event.target.value)
                        }}
                    />
                    <TextField id="ModelDescription"
                        fullWidth
                        label='Enter Model Description'
                        style={{
                            margin: 10
                        }}
                        value={modelDescription}
                        onChange={(event) => {
                            setModelDescription(event.target.value)
                        }}
                    />
                    <TextField id="ModelURL"
                        fullWidth
                        label='Enter Teachable URL'
                        style={{
                            margin: 10
                        }}
                        value={modelURL}
                        onChange={(event) => {
                            setModelURL(event.target.value)
                        }}
                    />
                </div>
                <div>
                    {
                        isModelFound && modelData && <>
                            <h4 style={{
                                textAlign: "center"
                            }}>Class list from this model</h4>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexWrap: "wrap",
                            }}>
                                {
                                    modelData.labels.map((label, index) => (
                                        <Button size='small' variant='outlined' sx={{
                                            margin: "5px"
                                        }}>{label}</Button>
                                    ))
                                }
                            </div>
                        </>
                    }
                </div>

                <Button id="CreateModelButton" onClick={createModel} size='large' disabled={!isModelFound} variant="contained" color="primary" sx={{
                    textTransform: "none",
                    borderRadius: "6px",
                    fontWeight: "bolder",
                    fontSize: "15px"
                }}>
                    Save Model
                </Button>


            </Card>
            <ContentDialogBox
                isOpen={isCreateSuccess}
                onClose={() => {
                    // setIsCreateSuccess(false)
                    // setIsCreateDialogOpen(false)
                    // setIsDataChanged(true)
                }}
                title={<>
                    <h4 style={{
                        color: eduCOBOTColorScheme.gray,
                        marginLeft: "15px",
                    }}>
                        Model Imported Successfully
                    </h4>

                </>}
                backdropStyle={{
                    backgroundColor: "transparent"
                }}
                paperStyle={{
                    background: "transparent",
                    borderRadius: 20,
                    backdropFilter: "blur(5px)",
                    display: "flex",
                    // alignItems: "center",
                    boxShadow: "0px 11px 15px -7px rgba(0,0,0,0.05),0px 24px 38px 3px rgba(0,0,0,0.05),0px 9px 46px 8px rgba(0,0,0,0.05)"

                }}
                content={<>
                    <div style={{

                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column"
                    }}>
                        <div style={{
                            padding: "100px 20px"
                        }}>

                            <div className="loader1" ></div>

                        </div>
                        {
                            projectID !== "" &&
                            <h4>What would you like to do next?</h4>
                        }
                    </div>
                </>}
                maxWidth={"sm"}
                actions={
                    <>
                        {
                            projectID !== "" &&
                            <Tooltip title={`Import the model into your ${projectData?.project.name} project`}>
                                <Button sx={{
                                    textTransform: "none",
                                    borderRadius: "8px",
                                    // fontWeight: "bolder",
                                    // fontSize: "10px"
                                }} onClick={() => {
                                    // setIsCreateSuccess(false)
                                    // setIsCreateDialogOpen(false)
                                    // setIsDataChanged(true)
                                    navigate(routes.DASHBOARD.PROJECT.SETTINGS(projectID))
                                }} variant="contained" color="primary">
                                    Back to Project
                                </Button>
                            </Tooltip>
                        }
                        <Tooltip title='Run the model to test its functionality.'>

                            <Button sx={{
                                textTransform: "none",
                                borderRadius: "8px",
                                // fontWeight: "bolder",
                                // fontSize: "10px"
                            }} onClick={() => {
                                // setIsCreateSuccess(false)
                                // setIsCreateDialogOpen(false)
                                // setIsDataChanged(true)
                                console.log(projectID);

                                navigate(routes.DASHBOARD.MODEL.WORKSPACE(createdModelData?._id || "") + "?project_id=" + projectID)
                            }} variant="contained" color='secondary'>
                                Run Model
                            </Button>
                        </Tooltip>
                    </>
                }
            />
        </>

    )
}