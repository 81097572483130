import React from 'react'
import CircuitDesigner from '../../../components/circuit-designer'

type Props = {}

function CircuitDesignerPage({ }: Props) {
    return (
        <div>
            <CircuitDesigner />
        </div>
    )
}

export default CircuitDesignerPage