import * as React from 'react';
import DashboardLayout from '../../../layouts/dashboard/DashboardLayout';
import { UserContext } from '../../../context/UserContext';
import { SettingContext } from '../../../context/SettingsContext';
import ProjectListComponent from './ProjectListComponent';

export type ProjectsPageProps = {
}

export default function ProjectsPage(props: ProjectsPageProps) {
    const { user, changeUser } = React.useContext(UserContext)
    const { settings, changeSettings, changeThemeScheme, toggleTheme } = React.useContext(SettingContext)
    const [isDataChanged, setIsDataChanged] = React.useState<any>(false)
    return (
        <div>
            <DashboardLayout title='Projects'>
                <div style={{
                    display: "flex",
                    justifyContent: "end",
                    padding: "10px",
                }}>
                    
                </div>
                <ProjectListComponent setIsDataChanged={setIsDataChanged} key={isDataChanged} />
            </DashboardLayout>
        </div>
    )}

