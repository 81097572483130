import { Button, Card, Paper } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { eduCOBOTColorScheme, SettingContext } from '../../../../../context/SettingsContext'
import { ProjectDataType } from '../../../../../types/Dashboard'
import { format, formatDistance, formatRelative, subDays, subHours } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { LessonDataType } from '../../../../../types/Lesson'
import { getImagePathByComponentName, getSensorsList } from '../Common/CircuitToBlockMapper'
import { DataGrid, GridColDef } from '@mui/x-data-grid';
















type Props = {
    lesson: LessonDataType,
    isBluePrintDescription?: boolean
}

function LessonDescription({ lesson, isBluePrintDescription = false }: Props) {
    const { settings } = useContext(SettingContext)
    const navigate = useNavigate()

    return (
        <div style={{
            minHeight: "30vh",
            display: 'flex',
            margin: "0 auto",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "95%"
        }}>
            <Card style={{
                minHeight: "150px",
                width: "100%",
                margin: "10px",
                padding: "10px",
                boxShadow: "0 0 5px #ccc",
                borderRadius: "10px",
                background: "#0000000A",
            }}>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    flexDirection: settings.screen === "mobile" ? "column" : "row",
                }}>
                    <img src={lesson.thumbnail} alt="" style={{
                        maxHeight: settings.screen === "mobile" ? "200px" : "100px",
                    }} />
                    <div style={{
                        margin: "0 10px",
                        width: "100%"
                    }}>
                        <h4 id='project-title' style={{
                            // margin: "10px 0",
                            // textAlign: "center",
                        }}>{lesson.name}</h4>
                        <h5 id='project-description' style={{
                            color: eduCOBOTColorScheme.gray,
                            fontSize: "12px",
                        }}>{lesson.description}</h5>

                    </div>
                </div>

            </Card>

            <Card style={{
                minHeight: "150px",
                width: "100%",
                margin: "10px",
                padding: "10px",
                boxShadow: "0 0 5px #ccc",
                borderRadius: "10px",
                background: "#0000000A",
            }}>

                <div id='components-list' style={{
                    display: "flex",
                    justifyContent: settings.screen === "mobile" ? "center" : "start",
                    flexWrap: "wrap",
                    margin: "0 10px",

                }}>
                    <h6 style={{
                        width: "100%",
                    }}>Hardware will be used in this lesson</h6>

                    {
                        getSensorsList(lesson.circuitInstructions.finalCircuit).length === 0 && <>

                            <Card style={{
                                width: "100%",
                                minHeight: "120px",
                                display: "flex",
                                padding: "10px",
                                margin: "10px",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                borderRadius: "10px",
                                fontSize: "15px",

                            }}>
                                No Sensors are Linked Yet
                            </Card>
                        </>
                    }
                    {
                        getSensorsList(lesson.circuitInstructions.finalCircuit).map((sensor, index) => {
                            return (
                                <Card style={{
                                    width: "200px",
                                    minHeight: "120px",
                                    display: "flex",
                                    padding: "10px",
                                    margin: "10px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    borderRadius: "10px",
                                    background: "#0000001A",

                                }}>
                                    <img src={getImagePathByComponentName(sensor)} style={{
                                        maxHeight: "100px",
                                        maxWidth: "180px"
                                    }} alt="" />
                                    <h6>{sensor}</h6>
                                </Card>
                            )
                        })
                    }

                </div>
            </Card>


            <Card style={{
                minHeight: "150px",
                width: "100%",
                margin: "10px",
                padding: "10px",
                boxShadow: "0 0 5px #ccc",
                borderRadius: "10px",
                background: "#0000000A",
            }}>
                <div style={{
                    // alignItems: "center",
                    width: "100%",
                }}>
                    <div style={{
                        margin: "10px 0",
                    }}>
                        <h4>Requirements</h4>
                        <ol style={{ padding: "0 20px" }}>

                            {
                                lesson.theory.requirements.map((requirement, index) => {
                                    return (
                                        <li style={{
                                            fontSize: "12px",
                                            color: eduCOBOTColorScheme.gray
                                        }} key={index}>{requirement}</li>
                                    )
                                })
                            }
                        </ol>
                    </div>
                    {
                        lesson.theory.content.map((content, index) => {
                            if (content.type === "heading")
                                return (
                                    <h4 style={{
                                        margin: "10px 0",
                                    }}>{content.value}</h4>
                                )
                            else if (content.type === "paragraph")
                                return (
                                    <p style={{
                                        fontSize: "12px",
                                        color: eduCOBOTColorScheme.gray
                                    }}>{content.value}</p>
                                )
                            else if (content.type === "image")
                                return (
                                    <div  style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        margin: '20px',
                                    }}>

                                        <img src={content.value} style={{
                                            maxHeight: "min(90%, 300px)",
                                            maxWidth: "min(70%, 300px)",
                                            margin:"auto",
                                            borderRadius:"20px"
                                        }} alt="" />
                                    </div>
                                )
                        })
                    }
                    <div>

                        <h4 style={{
                            margin: "10px 0",
                        }}>Pin Configuration</h4>
                        <Paper>
                            <DataGrid
                                rows={
                                    lesson.theory['pin-configuration'].pins.map((pin, index) => {
                                        return {
                                            id: index,
                                            [lesson.theory['pin-configuration'].columns[0]]: pin.component,
                                            [lesson.theory['pin-configuration'].columns[1]]: pin.interfacer
                                        }
                                    })
                                }
                                columns={lesson.theory['pin-configuration'].columns.map((column, index) => {
                                    return {
                                        field: column,
                                        headerName: column
                                    } as GridColDef
                                })}
                                checkboxSelection={false}
                                sx={{ border: 0 }}
                                disableColumnFilter={true}
                                disableColumnSorting={true}
                                disableRowSelectionOnClick={true}
                                disableColumnMenu={true}
                                hideFooterPagination={true}
                                hideFooterSelectedRowCount={true}
                                hideFooter={true}
                                disableColumnSelector={true}
                                disableDensitySelector={true}
                                disableVirtualization={true}
                            />
                        </Paper>










                    </div>



                </div>

            </Card>




        </div>
    )
}

export default LessonDescription