import React, { useEffect } from 'react'
import { ChatBotContainer } from '../components/chatbot'
export default function AIContextProvider(props: {
    children: React.ReactNode
}) {
    useEffect(() => {
    }, [])
    return (
        <div>
            {
                props.children
            }
            <ChatBotContainer/>
        </div>
    )
}
