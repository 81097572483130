import React, { useContext, useState } from 'react'
import { BackendConfigs } from '../../../../config.environment'
import { UserContext } from '../../../../context/UserContext'
import { SettingContext } from '../../../../context/SettingsContext'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { APIResponse } from '../../../../types/Response'
import { routes } from '../../../../routes/routes'
import { Button, Card, Checkbox, TextField } from '@mui/material'
import CreateModel from './Model'

type Props = {}

function CreateModelPage({ }: Props) {
    const { user } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const [projectName, setProjectName] = useState("")
    const [projectDescription, setProjectDescription] = useState("")
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)
    const [isCreateSuccess, setIsCreateSuccess] = useState(false)
    const [selectedEditors, setSelectedEditors] = useState<string[]>([])
    const navigate = useNavigate()


    async function createProject() {
        handleGlobalLoading(true, "Creating Project : " + projectName)
        if (!projectName) {
            toast.error("Project Name is required")
            handleGlobalLoading(false)
            return
        }
        if (!projectDescription) {
            toast.error("Project Description is required")
            handleGlobalLoading(false)
            return
        }
        try {
            const response = await fetch(`${BackendConfigs.url}/CreateProjectAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name: projectName,
                    description: projectDescription,
                    ownerId: user.userData?.id
                })
            })
            if (response.status === 200) {
                const result = await response.json() as APIResponse
                if (result.success) {
                    toast.success(result.message)
                    setIsCreateSuccess(true)
                    const projectId = result.data.project._id
                    setTimeout(() => {
                        navigate(routes.DASHBOARD.PROJECT.DESCRIPTION(projectId))
                    }, 3000)

                } else {
                    toast.error(result.message)
                }
            }
        } catch (error) {
            console.log(error);
        }
        handleGlobalLoading(false)
    }


    return (
        <div style={{
            minHeight: "90vh",
            display: 'flex',
            margin: "0 auto",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: settings.screen === "desktop" ? "row" : "column",
            width: "95%"
        }}>
            <CreateModel />

        </div>
    )
}











export default CreateModelPage