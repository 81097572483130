import { useDrag } from '@use-gesture/react';
import { animated, useSpring } from '@react-spring/web';
import { Button, Card } from '@mui/material';
import { useEffect, useState } from 'react';
import { LessonDataType } from '../../../../../../types/Lesson';
import audioPlayer from '../../../../../../components/audio-player';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
const label = { inputProps: { 'aria-label': 'Color switch demo' } };
const CircuitPlayerComponent = ({
    lessonData,
    circuitJSON,
    setCircuitJSON,
    setRefreshData,
}: {
    lessonData: LessonDataType,
    circuitJSON: string,
    setCircuitJSON: React.Dispatch<React.SetStateAction<string>>,
    setRefreshData: ()=>void
}) => {
    const [isAudioMuted, setIsAudioMuted] = useState(false);
    const [position, setPosition] = useState({ x: 50, y: 50 });
    const [currentStatementIndex, setCurrentStatementIndex] = useState(0);
    const bindLessonCircuitPlayer = useDrag((parameters) => {
        setPosition(() => {
            return {
                x: parameters.offset[0],
                y: parameters.offset[1]
            }
        })
    }, { filterTaps: true, delay: true });

    useEffect(()=>{
        audioPlayer.stop()
        audioPlayer.play(lessonData.circuitInstructions.content[currentStatementIndex].audio,isAudioMuted)
        setCircuitJSON(lessonData.circuitInstructions.content[currentStatementIndex].valid)
        setRefreshData()
    },[currentStatementIndex])
    function previousStatement() {
        if(currentStatementIndex>0){
            setCurrentStatementIndex(currentStatementIndex-1)
        }else{

        }
    }

    function nextStatement() {
        if(currentStatementIndex<lessonData.circuitInstructions.content.length-1){
            setCurrentStatementIndex(currentStatementIndex+1)
        }else{

        }
    }

    function toggleAudioPlayer() {
        if(isAudioMuted){
        }else{
        }
    }

    return (
        <>
            <animated.div {...bindLessonCircuitPlayer()} style={{
                x: position.x,
                y: position.y,
                maxWidth: "80vw",
                width: "100%",
                top: "30%",
                left:"50%",
                touchAction: 'none',
                position: "fixed"
            }} >
                <Card sx={{
                    display: "flex",
                    flexDirection: "column",
                    // alignItems: "center",
                    cursor: "pointer",
                    maxWidth: "600px",
                    borderRadius: "10px",
                    position: "relative",
                    padding: "10px",
                }}>
                    <div style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        zIndex: 9999
                    }}>
                        {
                            !isAudioMuted?
                            <VolumeOffIcon onClick={()=>{
                                audioPlayer.stop()
                                setIsAudioMuted(true)                    
                            }} />:
                            <VolumeUpIcon onClick={()=>{
                                audioPlayer.resume()
                                setIsAudioMuted(false)                    
                            }} />
                        }
                    </div>
                    {
                        
                    }
                    <h3>
                        {
                            lessonData.circuitInstructions.content[currentStatementIndex].title
                        }
                    </h3>
                    <p>
                        {
                            lessonData.circuitInstructions.content[currentStatementIndex].description
                        }
                    </p>
                    <div style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "end"
                    }}>
                        <Button onClick={previousStatement} disabled={currentStatementIndex===0} color='warning' variant='contained' sx={{
                            textTransform: "none",
                            margin: 1
                        }}>
                            Previous
                        </Button>
                        <Button onClick={nextStatement} disabled={currentStatementIndex===lessonData.circuitInstructions.content.length-1} color='success' variant='contained' sx={{
                            textTransform: "none",
                            margin: 1
                        }}>
                            Next
                        </Button>
                    </div>
                </Card>
            </animated.div>
        </>
    )
}
export default CircuitPlayerComponent
