import { Typography } from '@mui/material'
import React from 'react'
import { eduCOBOTColor } from '../../context/SettingsContext'
import { BackendConfigs } from '../../config.environment'
import { APIResponse } from '../../types/Response'
import { ProjectDataType } from '../../types/Dashboard'

type Props = {
    color: string
    setColor: React.Dispatch<React.SetStateAction<"default" | "primary" | "secondary" | "error" | "info" | "success" | "warning" | string>>
}

function CommonColorsSelectionComponent({
    color,
    setColor
}: Props) {
  return (
    <div style={{
        padding:"10px",
        display:"flex",
        flexWrap:"wrap",
        alignItems:"center",
        width:"300px"
    }}>
        {
            eduCOBOTColor.map((color_)=>{
                return (
                    <Typography key={color_} sx={{
                        background:color_,
                        width:"20px",
                        height:"20px",
                        borderRadius:"5%",
                        cursor:"pointer",
                        margin:"1px",
                        border:color===color_ ? "1px solid black" : "none",
                    }} onClick={()=>{
                        setColor(color_ as any)
                    }}/>
                )
            })
        }
    </div>
  )
}
export default CommonColorsSelectionComponent



export async function UpdateValueFromVariableName(secretKey: string, variableName: string, variableValue: string) {
    try {
        const response = await fetch(`${BackendConfigs.url}/ChangeVariableValueAPI`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                secretKey,
                APIKey: "sjhshdbcsjkdbn",
                variableName,
                variableValue
            })
        })
    } catch (error) {

    }
}

export async function GetValueFromVariableName(secretKey: string, variableName: string) {
    try {
        const response = await fetch(`${BackendConfigs.url}/GetVariableValueAPI`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                secretKey,
                APIKey: "sjhshdbcsjkdbn",
                variableName,

            })

        })
        const data = await response.json() as APIResponse
        return data.data.value
    } catch (error) {
        return null
    }
}
export async function GetSensorsDataByProjectIdAndVariableNameAPI(secretKey: string, variableName: string) {
    try {
        const response = await fetch(`${BackendConfigs.url}/GetSensorsDataByProjectIdAndVariableNameAPI`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                projectId:secretKey,
                variableName,
            })
        })
        const data = await response.json() as APIResponse
        return data.data
    } catch (error) {
        return null
    }
}


export function getVariableValueFromVariableName(variableName: string, project: ProjectDataType) {
    const variable = project.project.variables.find((variable) => variable.name === variableName)
    return variable?.value || "none"
}



