import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../../../context/UserContext'
import { eduCOBOTColorScheme, SettingContext } from '../../../../../context/SettingsContext'
import { Button, Card, Container, Tooltip } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { routes } from '../../../../../routes/routes'
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import SettingsIcon from '@mui/icons-material/Settings';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import { BackendConfigs } from '../../../../../config.environment'
import { APIResponse } from '../../../../../types/Response'
import { toast } from 'react-toastify'
import { ProjectDataType } from '../../../../../types/Dashboard'
import MonitorIcon from '@mui/icons-material/Monitor';
import MemoryIcon from '@mui/icons-material/Memory';
import DashboardLayout from '../../../../../layouts/dashboard/DashboardLayout'
import ProjectDescription from './Description'
import ProjectStatistics from './Statistics'
import CodeIcon from '@mui/icons-material/Code';
import { ProjectBottomBarComponent } from '../Common/BottomBar'
type ProjectDescriptionProps = {}

export default function ProjectDescriptionPage({ }: ProjectDescriptionProps) {
    const { user, changeUser } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const navigate = useNavigate()
    const { projectId } = useParams();
    const [projectData, setProjectData] = useState<ProjectDataType | null>(null)
    const [isSettingsOpen, setIsSettingsOpen] = useState(false)
    async function getProject() {
        console.log(projectId, user.userData);
        if (!!user.userData && !!projectId) {
            handleGlobalLoading(true, "Fetching Project")
            setProjectData(null)
            try {
                const response = await fetch(`${BackendConfigs.url}/GetProjectByProjectIdAndOwnerIdAPI`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        ownerId: user.userData?.id,
                        projectId: projectId,
                    })
                })
                if (response.status === 200) {
                    const result = await response.json() as APIResponse
                    // toast.success(result.message)
                    // console.log(result.data);
                    setProjectData(result.data)
                } else {
                    navigate(routes.DASHBOARD.MAIN)
                }
            } catch (error) {
                toast.error("Error Fetching Project")
                console.log(error);
            }
            handleGlobalLoading(false)
        }
    }

    useEffect(() => {
        getProject()
    }, [projectId,user])
    useEffect(() => {

    }, [])
    return (
        <>
            <div style={{
                marginBottom: 50,
                overflow: "scroll"
            }}>
                <Container>

                    {!!projectData && <ProjectDescription project={projectData} />}
                    {!!projectData && projectData.lastInteraction && <ProjectStatistics project={projectData} />}

                </Container>
            </div>
            {
                projectData &&
                <ProjectBottomBarComponent projectData={projectData} />
            }
        </>

    )
}