import * as React from 'react';
import DashboardLayout from '../../../layouts/dashboard/DashboardLayout';
import DashboardMain from './Main';

export type DashboardMainPageProps = {
}

export default function DashboardMainPage(props: DashboardMainPageProps) {
    return (
        <div>
            <DashboardLayout title='Dashboard'>
                <DashboardMain/>
            </DashboardLayout>
        </div>
    );
}
