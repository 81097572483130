import React, { useContext, useEffect, useState } from 'react'
import MicroPythonEditor from '../../../../../../components/micropython-block-editor'
import { ProjectDataType } from '../../../../../../types/Dashboard'
import CodeEditor from '../../../../../../components/code-editor'
import { motion } from 'framer-motion'
import { framerAnimation } from '../../../../../../theme/animation/MotionConfigs'
import { eduCOBOTColorScheme, SettingContext } from '../../../../../../context/SettingsContext'
import { deployCode, getWiFi } from '../../../../../../components/micropython-block-editor/RoboticHandler'
import { Button, Card, Switch, Tooltip, Typography } from '@mui/material'
import MicroPythonToolBox from '../../../../../../components/micropython-block-editor/configs/micropython-toolbox';
import { addDynamicTextReturningBlocks } from '../../../../../../components/micropython-block-editor/configs/code-generator'
import { toast } from 'react-toastify'
import { ConnectionContext, handleMachineCall } from '../../../../../../context/ConnectionContext'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import Grid4x4Icon from '@mui/icons-material/Grid4x4';
import { ContentDialogBox } from '../../../../../../components/dialog-box'

type Props = {
    xml: string
    setXml: (value: string) => void
    code: string
    setCode: (value: string) => void
    project: ProjectDataType
    setIsCircuitDesignerOpen: (value: boolean) => void
    keys?: {
        [key: string]: string
    },
    setNewBlocks?: (newBlocks: string[]) => void,
    saveMicropythonCode?: () => {}
}


function CodeGenerator({
    code,
    setCode,
    xml,
    setXml,
    project,
    keys,
    setNewBlocks,
    saveMicropythonCode
}: Props) {
    const [initialXML, setInitialXML] = React.useState<string>(xml)
    const [microPythonToolBox, setMicroPythonToolBox] = React.useState<typeof MicroPythonToolBox>(MicroPythonToolBox)
    const [newBlocks, setNewControllerBlocks] = useState<string[]>([])

    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const [isBlockEditing, setIsBlockEditing] = React.useState(false)
    const [isWiFi, setIsWiFi] = React.useState<boolean>(getWiFi())
    const [currentProcessMessage, setCurrentProcessMessage] = React.useState<string>("")
    const [console_messages, setConsole_messages] = React.useState<string[]>([
        "started"
    ])
    const [isRunning, setIsRunning] = React.useState<boolean>(false)
    const [isControllerOpen, setIsControllerOpen] = React.useState(false)
    const [isMatrixCodeResolverOpen, setIsMatrixCodeResolverOpen] = React.useState(false)
    const { connection, handleConnectionTab } = useContext(ConnectionContext)
    const [isConnectionWarning, setIsConnectionWarning] = React.useState(false)
    useEffect(() => {
        localStorage.setItem("isWiFi", String(isWiFi))
    }, [isWiFi])
    useEffect(() => {
        // if(project.project.isCreatedFromBlueprint){
        //     setIsBlockEditing(true)
        // }
    }, [])
    // async function getProcessMessages() {
    //     try {
    //         let response = await fetch("http://localhost:8080/process_message", { method: "POST" })
    //         let json = await response.json()
    //         setCurrentProcessMessage(json.message)
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }
    async function getConsoleMessages() {
        try {
            let response = await fetch("http://localhost:8080/console_outputs", { method: "POST" })
            let json = await response.json()
            // console.log(json.outputs);

            setConsole_messages(json.outputs.replace("[", "").replace("]", "").split(",").reverse())
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (!!keys) {
            setMicroPythonToolBox(addDynamicTextReturningBlocks(
                Object.keys(keys).map((key) => {
                    return {
                        name: key,
                        value: keys[key]
                    }
                }) as { name: string; value: string }[],
                microPythonToolBox,
                "IOT-Resources",
                "red",
                "IOT"
            ))
        }
    }, [keys])

    useEffect(() => {
        if (!!project) {
            // if (project.project.variables.length > 0)

            setMicroPythonToolBox((microPythonToolBox) => {
                return addDynamicTextReturningBlocks(
                    project.project.variables.map((variable) => {
                        return {
                            name: variable.name,
                            value: variable.name
                        }
                    }) as { name: string; value: string }[],
                    microPythonToolBox,
                    "IOT-Variables",
                    "red",
                    "IOT"
                )
            })
        }
    }, [project])

    useEffect(() => {
        const interval = setInterval(() => {
            // if (isRunning) {
            console.log("logging in console");
            // getProcessMessages()
            getConsoleMessages()
            // }
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [])
    async function deployCodeOnMachine() {
        setIsRunning(true)
        handleGlobalLoading(true)
        await deployCode(code)
        handleGlobalLoading(false)
        setIsRunning(false)
    }
    return (
        <motion.div
            initial={framerAnimation.right2left.initial}
            animate={framerAnimation.right2left.animate}
            exit={framerAnimation.right2left.exit}
            transition={framerAnimation.right2left.transition}
        >
            <div
                style={{
                    height: "100dvh",
                    width: "100vw",
                    display: "flex",
                }}
            >
                <MicroPythonEditor
                    key={initialXML}
                    setCode={(code) => {
                        // console.log(code)
                        isBlockEditing && setCode(code)
                    }}
                    setXml={(xml) => {
                        // console.log(xml)
                        setXml(xml)
                    }}
                    initialXML={initialXML}
                    setNewBlocks={(value) => {
                        // console.log(value);
                        setNewBlocks && setNewBlocks(value)
                        isControllerOpen && setNewControllerBlocks(value)
                    }} />
                <div style={{
                    height: "100dvh",
                }}>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: "6vh",
                        zIndex: 999999999
                    }}>
                        <div>

                            <Tooltip title="Deploy">

                                <Button id='RunMicroPythonCodeButton' style={{
                                    border: "none",
                                }} onClick={() => {
                                    if (connection.software.isConnected) {

                                        deployCodeOnMachine()
                                    } else {
                                        setIsConnectionWarning(true)
                                    }
                                }}>
                                    <img src="https://webapp.educobot.com/_next/image?url=%2Fassets%2Fgreen_flag.png&w=32&q=75" style={{
                                        width: "20px"
                                    }} alt="" />
                                </Button>
                            </Tooltip>
                            <Tooltip title={isBlockEditing ? "Code Editor" : "Block Editor"}>

                                <Button id='CodeOrBlockToggler' variant='outlined' onClick={() => {
                                    setIsBlockEditing(!isBlockEditing)
                                }} style={{
                                    border: "none",
                                    width: "50px"
                                }}>
                                    {
                                        isBlockEditing ?
                                            <img src="https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/iot-assets/Block_Coding.png" height={20} alt="" />
                                            : <img src="https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/iot-assets/Syntax_Code.png" height={20} alt="" style={{
                                                filter: "invert(1)",
                                            }} />
                                    }
                                </Button>
                            </Tooltip>
                            <Button
                                id='saveCodeButton'
                                variant='contained'
                                size='small'
                                onClick={() => {
                                    saveMicropythonCode && saveMicropythonCode()
                                }}
                                color="primary"
                                sx={{
                                    textTransform: "none",
                                    borderRadius: "6px",
                                    fontWeight: "bolder",
                                    fontSize: "10px"
                                }}
                            >
                                Save Code
                            </Button>
                        </div>
                        <Typography sx={{
                            display: "flex",
                            alignItems: "center",
                        }}>
                            <Tooltip title="Controller">
                                <Button style={{
                                    border: "none",
                                }} onClick={() => {
                                    setIsControllerOpen(!isControllerOpen)
                                }}>
                                    <img src="https://innovator-resourses.s3.ap-south-1.amazonaws.com/assets/Game+Pad.png" style={{
                                        width: "25px"
                                    }} alt="" />
                                </Button>
                            </Tooltip>
                            {
                                code.includes("max7219") &&
                                <Tooltip title="Controller">
                                    <Button style={{
                                        border: "none",
                                    }} onClick={() => {
                                        setIsMatrixCodeResolverOpen(!isMatrixCodeResolverOpen)
                                    }}>
                                        <Grid4x4Icon />
                                    </Button>
                                </Tooltip>
                            }
                            <Tooltip title="Connection">
                                <Button variant='outlined' onClick={() => {
                                    handleConnectionTab(!connection.isConnectionTab)
                                }} style={{
                                    border: "none",
                                    width: "50px",
                                    marginRight: 30
                                }}>
                                    <SettingsInputAntennaIcon />
                                </Button>
                            </Tooltip>
                            <div id='CableOrWifiToggleButton' style={{
                                display: "flex",
                                alignItems: "center",
                            }}>

                                <h6>Cable</h6>
                                <Switch defaultChecked={isWiFi} onChange={() => setIsWiFi(!isWiFi)} checked={isWiFi} />
                                <h6>Wi-Fi</h6>
                            </div>
                        </Typography>


                    </div>
                    <div style={{
                        height: "94dvh",
                    }}>
                        <CodeEditor code={code}
                            setCode={setCode} isDisabled={isBlockEditing} />

                    </div>
                </div>
                {
                    settings.globalLoading.loading && isRunning && <>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "50%",
                            position: "fixed",
                            bottom: "0",
                            right: "0",
                            background: "#224FBC",
                            zIndex: 9999999999999,
                        }}>
                            <h4 style={{ textAlign: "center", width: "100%", background: "#B9B9B9", padding: "10px" }}>
                                Terminal
                            </h4>
                            <div style={{

                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                justifyContent: "center",
                            }}>


                                <Card style={{
                                    width: "100%",
                                    display: "flex", flexDirection: "column",
                                    margin: "auto",
                                    height: "25dvh",
                                    padding: "20px",
                                    overflow: "auto",
                                    background: "#224FBC",
                                }}>
                                    {
                                        console_messages && console_messages.map((message, index) => <p key={index} style={{
                                            color: "#B9B9B9"
                                        }}>&gt; {message}</p>)
                                    }

                                </Card>
                            </div>
                        </div>
                    </>
                }
                {
                    !connection.software.isConnected &&
                    <ContentDialogBox
                        isOpen={isConnectionWarning}
                        onClose={() => { setIsConnectionWarning(false) }}
                        content={
                            <>
                                <h2 style={{
                                    textAlign: "center",
                                    margin: "10px",
                                    color: eduCOBOTColorScheme.gray
                                }}>Shield Driver not Running</h2>
                                <img src="https://innovator-resourses.s3.ap-south-1.amazonaws.com/assets/eduCOBOT+Sheild.png" style={{
                                    maxWidth: "120px",
                                    margin: "20px auto",
                                    display: "block",

                                }} alt="" />
                                <h4 style={{
                                    textAlign: "center",
                                    color: eduCOBOTColorScheme.gray
                                }}>Ensure eduCOBOT driver is running. Click connection icon to check status.</h4>
                            </>
                        }
                        title=''
                        maxWidth="xs"
                    />
                }
                <ContentDialogBox
                    isOpen={isControllerOpen}
                    onClose={() => { setIsControllerOpen(false) }}
                    content={
                        <>
                            <ControllerComponent newBlocks={newBlocks} />
                        </>
                    }
                    title='Your Controller'
                    maxWidth="xs"
                />
                <ContentDialogBox
                    isOpen={isMatrixCodeResolverOpen}
                    onClose={() => { setIsMatrixCodeResolverOpen(false) }}
                    content={
                        <>
                            <MatrixDataResolver />
                        </>
                    }
                    title='ByteArray Producer'
                    maxWidth="lg"
                />
            </div>
        </motion.div>
    )
}

export default CodeGenerator



export function MatrixDataResolver() {
    const [row, setRow] = useState(8)
    const [column, setColumn] = useState(8)
    const [DataArray, setDataArray] = useState<string[]>([])
    const [isChanged, setIsChanged] = useState(false)
    useEffect(() => {
        setDataArray(Array(row * column).fill("0"));
    }, [])
    return <>
        <Typography display={"flex"} justifyContent={"center"} flexDirection={"column"} alignItems={"center"}>
            <Typography>


            </Typography>
            <Typography style={{
                display: "grid",
                gridTemplateColumns: Array(column).fill("1fr").join(" "),
            }}>
                {
                    Array(row * column).fill(1).map((_, i) => {
                        return <div key={i} style={{
                            width: "30px",
                            height: "30px",
                            border: "1px solid black",
                            cursor: "pointer",
                            background: DataArray[i] === "1" ? "black" : "white",
                        }}
                            onClick={() => {
                                console.log(i);
                                // console.log(DataArray[i]);
                                setIsChanged(!isChanged);
                                setDataArray((prev) => {
                                    let newArray = [...prev]
                                    newArray[i] = prev[i] === "1" ? "0" : "1";
                                    return newArray
                                })
                            }}
                        ></div>
                    })
                }

            </Typography>
            <Typography key={isChanged ? "1" : "0"} style={{
                display: "grid",
                gridTemplateColumns: Array(column).fill("1fr").join(" "),
                position: "relative",
                marginTop: "20px",
            }}>
                {
                    Array(row * column).fill(1).map((_, i) => {
                        return <>
                            <div key={i} style={{
                                width: "20px",
                                height: "20px",
                                cursor: "pointer",
                                textAlign: "center",
                            }}
                            >{DataArray[i]} </div>
                            {
                                (i + 1) % 8 === 0 && <Button onClick={() => {
                                    navigator.clipboard.writeText(DataArray.slice(i - 8 + 1, i + 1).join(""));
                                    toast.info(DataArray.slice(i - 8 + 1, i + 1).join("") + " is copied to clipboard")
                                }} sx={{
                                    // width: "20px",
                                    margin: 0,
                                    padding: 0,
                                    // minWidth: "20px",
                                    position: "absolute", right: "-60px",
                                    top: `${Math.floor(i / 8) * 20}px`,
                                }}>
                                    <ContentCopyIcon sx={{
                                        width: "15px"
                                    }} />
                                </Button>
                            }
                        </>
                    })
                }

            </Typography>
        </Typography>
    </>
}


export function ControllerComponent({
    newBlocks
}: {
    newBlocks: string[]
}) {

    const { connection } = useContext(ConnectionContext)
    async function handleButtonClick(value: string) {
        handleMachineCall(connection, value)
    }
    return (
        <Typography style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            gap: 10,
            rowGap: 50,
        }}>
            {
                newBlocks.map(block => {
                    const newBlock = block.replaceAll("'", "")
                    return <Button variant='contained' color={'info'} style={{
                        width: "150px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "auto",
                        borderRadius: "15px"
                    }} sx={{
                        textTransform: "none"
                    }} onClick={() => {
                        handleButtonClick(newBlock)
                    }} >
                        {newBlock}
                    </Button>
                })
            }
        </Typography>
    )
}