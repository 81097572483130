import React from 'react'
import DashboardIcon from '@mui/icons-material/Dashboard';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import ConstructionIcon from '@mui/icons-material/Construction';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { Blocks, Container, SettingsIcon } from 'lucide-react';
import { routes } from '../../routes/routes';


function DashboardNavbarIcon(icon: string, size: string = "20px") {
    switch (icon) {
        case routes.DASHBOARD.MAIN:
            return <DashboardIcon style={{
                fontSize: size,
            }} />

        case routes.DASHBOARD.PROJECTS:
            return <DeveloperBoardIcon style={{
                fontSize: size,
            }} />

        case routes.DASHBOARD.LESSONS:
            return <LibraryBooksIcon style={{
                fontSize: size,
            }}/>

        case routes.DASHBOARD.TOOLS:
            return <Blocks size={size} />

        case routes.DASHBOARD.SETTINGS:
            return <SettingsIcon style={{
                fontSize: size,
            }} />
        default:
            return <ConstructionIcon style={{
                fontSize: size,
            }} />
    }
}

export default DashboardNavbarIcon

export const DashboardNavConfig = {
    navigation: [
        {
            title: "Dashboard",
            link: routes.DASHBOARD.MAIN,
        },
        {
            title: "My Projects",
            link: routes.DASHBOARD.PROJECTS
        },
        {
            title: "Curriculum",
            link: routes.DASHBOARD.LESSONS
        },
        {
            title: "Models",
            link: routes.DASHBOARD.MODELS
        },
        {
            title: "Tools",
            link: routes.DASHBOARD.TOOLS
        },
        {
            title: "Settings",
            link: routes.DASHBOARD.SETTINGS
        },
    ]
}