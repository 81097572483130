import React, { useContext, useEffect, useState } from 'react'
import { fromLonLat } from "ol/proj";
import { Coordinate } from "ol/coordinate";
import { Point } from "ol/geom";
import "ol/ol.css";
import './index.scss'
import { RMap, ROSM, RLayerVector, RFeature, ROverlay, RStyle } from "rlayers";
import { LocationContext } from '../../context/LocationContext';

const coords: Record<string, Coordinate> = {
    origin: [2.364, 48.82],
    ArcDeTriomphe: [2.295, 48.8737],
};
const coords2: Record<string, Coordinate> = {
    origin: [5.364, 48.82],
    ArcDeTriomphe: [5.295, 48.8737],
};
type Props = {
    coordinates: {
        latitude: number,
        longitude: number,
        label: string
    }[]
}

function MapComponent({ }: Props) {
    const { location } = useContext(LocationContext)
    const [labels, setLabels] = useState<{
        latitude: number,
        longitude: number,
        label: string
    }[]>([])
    useEffect(() => {
        console.log(location);
        if (location.isLocationAvailable) {
            setLabels((preValue) => {
                return [...preValue, {
                    latitude: location.latitude,
                    longitude: location.longitude,
                    label: "Your Current Location"
                }]
            })
        }
    }, [location])
    if (labels.length === 0) return <></>
    return (
        <div style={{ minHeight: "80vh", width: "100%", border: "1px solid gray" }}>
            <RMap
                className="Map-Component"
                initial={{ center: fromLonLat([labels[0].longitude, labels[0].latitude]), zoom: 11 }}
            >
                <ROSM />
                {
                    labels.map((label, index) => {
                        return <RLayerVector key={index} zIndex={10}>
                            <RStyle.RStyle>
                                <RStyle.RIcon src={"https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/svg/location.svg"} anchor={[0.5, 0.8]} />
                            </RStyle.RStyle>
                            <RFeature
                                geometry={new Point(fromLonLat([label.longitude, label.latitude]))}
                                onClick={(e) => {
                                    console.log(e);
                                }}
                            />

                        </RLayerVector>
                    })
                }

            </RMap>
        </div >
    )
}

export default MapComponent