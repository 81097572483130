import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom'
import { registerLicense } from '@syncfusion/ej2-base';
import { ServicesConfigs } from './config.environment';
registerLicense(ServicesConfigs.syncfusion.KEY);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </React.StrictMode>
);