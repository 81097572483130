export const routes={
    ROOT:'/',
    DASHBOARD:{
        MAIN:"/dashboard/main",
        PROJECTS:"/dashboard/projects",
        PROJECT:{
            CREATE:"/dashboard/project/create",
            DESCRIPTION:(projectId:string)=>"/dashboard/project/description/"+projectId,
            SETTINGS:(projectId:string)=>"/dashboard/project/settings/"+projectId,
            WORKSPACE:{
                CIRCUIT:(projectId:string)=>"/dashboard/project/workspace/circuit/"+projectId,
                MONITOR:(projectId:string)=>"/dashboard/project/workspace/monitor/"+projectId,
                MICROPYTHON:(projectId:string)=>"/dashboard/project/workspace/micropython/"+projectId,
                AI:(projectId:string)=>"/dashboard/project/workspace/ai/"+projectId,
                LOGIC_GATE:(projectId:string)=>"/dashboard/project/workspace/logic-gate/"+projectId,
            }
        },
        LESSONS:"/dashboard/lessons",
        MATERIALS:"/dashboard/materials",
        LESSON:{
            THEORY:(lessonId:string)=>"/dashboard/lesson/theory/"+lessonId,
            SETTINGS:(lessonId:string)=>"/dashboard/lesson/settings/"+lessonId,
            WORKSPACE:{
                ASSEMBLY:(lessonId:string)=>"/dashboard/lesson/workspace/assembly/"+lessonId,
                CIRCUIT:(lessonId:string)=>"/dashboard/lesson/workspace/circuit/"+lessonId,
                MICROPYTHON:(lessonId:string)=>"/dashboard/lesson/workspace/micropython/"+lessonId,
                MONITOR:(lessonId:string)=>"/dashboard/lesson/workspace/monitor/"+lessonId,
                AI:(lessonId:string)=>"/dashboard/lesson/workspace/ai/"+lessonId,
                MCQ:(lessonId:string)=>"/dashboard/lesson/workspace/quiz/"+lessonId,
                EXAM:(lessonId:string)=>"/dashboard/lesson/workspace/exan/"+lessonId,
            }
        },
        BLUEPRINTS:"/dashboard/blueprints",
        BLUEPRINT:{
            CREATE:"/dashboard/project/create",
            DESCRIPTION:(blueprintId:string)=>"/dashboard/project/description/"+blueprintId,
            SETTINGS:(blueprintId:string)=>"/dashboard/project/settings/"+blueprintId,
            WORKSPACE:{
                CIRCUIT:(blueprintId:string)=>"/dashboard/project/workspace/circuit/"+blueprintId,
                MONITOR:(blueprintId:string)=>"/dashboard/project/workspace/monitor/"+blueprintId,
                MICROPYTHON:(blueprintId:string)=>"/dashboard/project/workspace/micropython/"+blueprintId,
                AI:(blueprintId:string)=>"/dashboard/project/workspace/ai/"+blueprintId,
                LOGIC_GATE:(blueprintId:string)=>"/dashboard/project/workspace/logic-gate/"+blueprintId,
            }
        },
        TOOLS:"/dashboard/tools",
        MODELS:"/dashboard/models",
        MODEL:{
            CREATE:"/dashboard/model/create",
            WORKSPACE:(modelId:string)=>"/dashboard/model/workspace/"+modelId,
        },
        SETTINGS:"/dashboard/settings",
    },
    SHARE:{
        PROJECT_MONITOR_SHARE:(projectId:string)=>"/sharing/project/monitor/"+projectId
    },
    TOOLS:{
        MICROPYTHON_EDITOR:"/tools/micropython-editor",
        CIRCUIT_DESIGNER:"/tools/circuit-designer",
        AIEditor:"/tools/ai-editor",
        MONITOR_DESIGNER:"/tools/monitor-designer",
        LOGIC_GATE_SIMULATOR:"/tools/logic-gate-simulator",
    },
    MODELS:{
        PRE_TRAINED:{
            OBJECT_DETECTION:"/models/pre-trained/object-detection",
            FACE_DETECTION:"/models/pre-trained/face-detection",
            POSE_DETECTION:"/models/pre-trained/pose-detection",
            HAND_GESTURE_DETECTION:"/models/pre-trained/face-detection",
        },
        CUSTOM_MODELS:"/models/custom-models/:modelID",
    },
    NOTFOUND:'/404',
    WILDCARD:"*",
}