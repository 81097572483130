import React from 'react';
import './App.css';
import { HelmetProvider } from 'react-helmet-async';
import { StyledEngineProvider } from "@mui/material/styles";
import ConfiguredThemeProvider from "./theme/ThemeProvider";
import { ServicesConfigs } from './config.environment';
import AIContextProvider from './context/AIContext';
import ConnectionContextProvider from './context/ConnectionContext';
import UserContextProvider from './context/UserContext';
import LocationContextProvider from './context/LocationContext';
import SettingsContextProvider from './context/SettingsContext';
import PreviewContextProvider from './context/PreviewContext';
import Router from './routes';
import "./styles/index.scss"
import TourContextProvider from './context/TourContextProvider';
import LessonPlayerProvider from './context/LessonPlayer';
console.log(ServicesConfigs.syncfusion.KEY);

function App() {
    return (
        <HelmetProvider>
            <TourContextProvider>
                <PreviewContextProvider>
                    <SettingsContextProvider>
                        <UserContextProvider>
                            <AIContextProvider>
                                <LessonPlayerProvider>
                                    <ConnectionContextProvider>
                                        <LocationContextProvider>



                                            <ConfiguredThemeProvider>
                                                <StyledEngineProvider injectFirst>
                                                    <>

                                                        <Router />

                                                    </>
                                                </StyledEngineProvider>
                                            </ConfiguredThemeProvider>
                                        </LocationContextProvider>
                                    </ConnectionContextProvider>
                                </LessonPlayerProvider>
                            </AIContextProvider>
                        </UserContextProvider>
                    </SettingsContextProvider>
                </PreviewContextProvider>
            </TourContextProvider>
        </HelmetProvider>
    );
}


export default App;