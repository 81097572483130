import { useTheme } from '@emotion/react';
import HorizontalTabs from '../../../components/tabs/HorizontalTabs';
import { useNavigate } from 'react-router-dom';
import React, { useContext, useEffect } from 'react';
import { UserContext } from '../../../context/UserContext';
import { eduCOBOTColorScheme, SettingContext } from '../../../context/SettingsContext';
import { BackendConfigs } from '../../../config.environment';
import { ProjectDataType } from '../../../types/Dashboard';
import { APIResponse } from '../../../types/Response';
import { ContentDialogBox } from '../../../components/dialog-box';
import { Button, Card, CardActions, TextField } from '@mui/material';
import ProjectDescription from '../Projects/Project/Description/Description';
import { toast } from 'react-toastify';
import { routes } from '../../../routes/routes';
import { LessonDataType } from '../../../types/Lesson';

export interface LessonsProps {
}


export default function Lessons(props: LessonsProps) {
    const theme = useTheme();

    const [value, setValue] = React.useState(0);
    const navigate = useNavigate()
    const { user } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const [currentFilter, setCurrentFilter] = React.useState("all")
    const [lessons, setLessons] = React.useState<LessonDataType[]>([])
    const [backupLessons, setBackupLessons] = React.useState<LessonDataType[]>([])
    const [searchText, setSearchText] = React.useState("")
    async function fetchLessons() {
        handleGlobalLoading(true, "Fetching lessons")
        setLessons([])
        try {
            const response = await fetch(`${BackendConfigs.url}/GetLessonsAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
            })
            if (response.status === 200) {
                const result = await response.json() as APIResponse
                setLessons(result.data)
                setBackupLessons(result.data)
            }
        } catch (error) {
            console.log(error);
        }
        handleGlobalLoading(false)
    }
    React.useEffect(() => {
        fetchLessons()
    }, [user])

    useEffect(() => {
        if (currentFilter === "all") {
            setLessons(backupLessons)
        } else {
            setLessons(backupLessons.filter((lesson) => !!lesson.editors.some((editor) => editor.includes(currentFilter))))
        }
    }, [currentFilter])

    useEffect(() => {
        if (searchText === "") {
            setLessons(backupLessons)
        }
        else {
            setLessons(backupLessons.filter((lesson) =>
                lesson.name.toLowerCase().includes(searchText.toLowerCase()) ||
                lesson.description.toLowerCase().includes(searchText.toLowerCase()) ||
                lesson.lessonNumber.toString().includes(searchText.toLowerCase())
            ))
        }
    }, [searchText])

    return (
        <div>
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: settings.screen === "mobile" ? "column-reverse" : "row",
                padding: "15px"
            }}>

                <div style={{
                    width: "100%"
                }}>
                    <Button size='small' variant={currentFilter === "all" ? "contained" : "outlined"} onClick={() => setCurrentFilter("all")}>
                        All
                    </Button>
                    <Button size='small' variant={currentFilter === "theory" ? "contained" : "outlined"} onClick={() => setCurrentFilter("theory")}>
                        Theory
                    </Button>
                    <Button size='small' variant={currentFilter === "assembly" ? "contained" : "outlined"} onClick={() => setCurrentFilter("Assembly")}>
                        Assembly
                    </Button>
                    <Button size='small' variant={currentFilter === "circuit" ? "contained" : "outlined"} onClick={() => setCurrentFilter("circuit")}>
                        Circuit
                    </Button>
                    <Button size='small' variant={currentFilter === "micropython" ? "contained" : "outlined"} onClick={() => setCurrentFilter("micropython")}>
                        Code
                    </Button>
                    <Button size='small' variant={currentFilter === "project" ? "contained" : "outlined"} onClick={() => setCurrentFilter("project")}>
                        Project
                    </Button>





                </div>



                <div style={{
                    width: "100%",
                    margin: "10px 0px"
                }}>
                    <TextField value={searchText} onChange={(e) => setSearchText(e.target.value)} size='small' fullWidth label="Search lessons" variant="outlined" />
                </div>
            </div>



            <div style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "start",
                flexWrap: "wrap",
            }}>
                {
                    lessons.map((lesson) => {
                        return (
                            <LessonCard key={lesson._id} title={lesson.name} image={lesson.thumbnail} lesson={lesson} />
                        )
                    })
                }
                {
                    lessons.length === 0 && <Card style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "30vh",
                        margin: "10px",
                        padding: "10px",
                        boxShadow: "0 0 5px #ccc",
                        borderRadius: "10px"
                    }}>
                        <p style={{ textAlign: "center" }}>No Lesson Found</p>
                    </Card>
                }
            </div>
        </div>
    );
}

function LessonCard({ image, title, lesson }: { image: string, title: string, lesson: LessonDataType }) {
    const navigate = useNavigate()
    const [isHovering, setIsHovering] = React.useState(false)
    return (

        <Card onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)} style={{
            width: "min(300px, 100%)",
            minHeight: "min(250px, 500px)",
            // maxHeight: "max(200px, 500px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            borderRadius: "20px",
            margin: "15px",
            background: isHovering ? eduCOBOTColorScheme.green : "white",
            backdropFilter: "blur(2px)",
            padding: "20px 10px",
            transition: "all 0.2s ease-in-out",
        }}
            sx={{
                ":hover": {
                    scale: "1.1",
                }
            }}
        >
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                gap: "2px",
                padding: "10px"
            }}>
                <div style={
                    {
                        width: "30px",
                        height: "30px",
                        minWidth: "30px",
                        color: "white",
                        backgroundColor: eduCOBOTColorScheme.black,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                    }
                }>
                    {
                        lesson.lessonNumber
                    }
                </div>
                <h4 style={{
                    // width: "100%",
                    color: eduCOBOTColorScheme.black,
                    textAlign: "center",
                }} >
                    {title}
                </h4>
                <div style={
                    {
                        width: "30px",
                        height: "30px",
                        color: "white",
                        backgroundColor: eduCOBOTColorScheme.black,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                    }
                }>
                    G
                </div>
            </div>
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "100%",
                gap: "2px"
            }}>
                {
                    lesson.tags.map((tag, index) => {
                        return (
                            <p key={index} style={{
                                color: isHovering ? eduCOBOTColorScheme.black : eduCOBOTColorScheme.gray,
                                textAlign: "center",
                                background: isHovering ? eduCOBOTColorScheme.white : "#F2F3F5",
                                borderRadius: "15px",
                                margin: "5px 2px",
                                padding: "3px 10px",
                                fontSize: "0.8em",
                            }} >
                                {tag}
                            </p>
                        )
                    })
                }
            </div>
            <img src={image} alt="" style={{
                maxWidth: "300px",
                maxHeight: "160px",
                margin: "10px auto"
            }} />

            <p style={{
                fontSize: "0.8em",
                color: isHovering ? eduCOBOTColorScheme.white : eduCOBOTColorScheme.gray,
                padding: "10px 0px",
            }}>
                {lesson.description}
            </p>
            <div style={{
                width: "100%",
                padding: "10px",
            }}>
                <Button size='small' fullWidth onClick={() => {
                    navigate(routes.DASHBOARD.LESSON.THEORY(lesson.lessonId))
                }} variant={isHovering ? 'contained' : 'outlined'} color={isHovering ? 'inherit' : 'primary'} sx={{
                    textTransform: "none",
                    borderRadius: "8px",
                }}>
                    Open
                </Button>
            </div>
        </Card>
    )
}

