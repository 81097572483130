import React, { useContext, useState } from 'react'
import { ProjectDataType, Variable } from '../../../../../types/Dashboard'
import { Button, Card, Checkbox, Container, Grid, Switch, TextField, Typography } from '@mui/material'
import { UserContext } from '../../../../../context/UserContext'
import { eduCOBOTColorScheme, SettingContext } from '../../../../../context/SettingsContext'
import { BackendConfigs } from '../../../../../config.environment'
import { APIResponse } from '../../../../../types/Response'
import { toast } from 'react-toastify'
import { ContentDialogBox } from '../../../../../components/dialog-box'
import { useNavigate } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete';

type Props = {
    projectData: ProjectDataType,
    refreshProject: () => void
}

function EditorSelectionEditing({ projectData, refreshProject }: Props) {
    const { user } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const navigate = useNavigate()
    const [selectedEditors, setSelectedEditors] = useState<string[]>(projectData.project.editors)

    async function updateProject() {
        if (selectedEditors.length === 0) {
            toast.error("At least one editor is required")
            return
        }
        try {
            const response = await fetch(`${BackendConfigs.url}/ChangeEditorsAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    ownerId: user.userData?.id,
                    projectId: projectData.project._id,
                    editors: selectedEditors,
                })
            })
            if (response.status === 200) {
                const result = await response.json() as APIResponse
                if (result.success) {
                    toast.success(result.message)
                    refreshProject()
                } else {
                    toast.error(result.message)
                }
            } else {
                toast.error("Details can't be updated1")
            }
        } catch (error) {
            console.log(error);
            toast.error("Details can't be updated2")
        }
        handleGlobalLoading(false)
    }

    return (
        <div id='EditorSelections' style={{
            width: "95%",
            margin: "10px auto",
        }}>
            <Container sx={{
                padding: "10px",
            }}>

                <div>
                    <h4>Select your required Development Environment</h4>
                    <div>
                        <Checkbox
                            checked={selectedEditors.length === 4}
                            onChange={(e) => {
                                if (selectedEditors.length === 4) {

                                    setSelectedEditors([])
                                } else {
                                    setSelectedEditors(["micropython-editor", "ai-editor", "circuit-designer", "iot-monitor"])

                                }
                            }}
                            id='all'
                        />
                        <label htmlFor="all">Select All</label>
                    </div>
                    <div>
                        <Checkbox
                            checked={selectedEditors.includes("micropython-editor")}
                            onChange={(e) => {
                                selectedEditors.includes("micropython-editor") ? setSelectedEditors(selectedEditors.filter((editor) => editor !== "micropython-editor")) : setSelectedEditors([...selectedEditors, "micropython-editor"])
                            }}
                            id='micropython-editor'
                        />
                        <label htmlFor="micropython-editor">Robotics</label>
                    </div>

                    <div>
                        <Checkbox
                            checked={selectedEditors.includes("circuit-designer")}
                            onChange={(e) => {
                                selectedEditors.includes("circuit-designer") ? setSelectedEditors(selectedEditors.filter((editor) => editor !== "circuit-designer")) : setSelectedEditors([...selectedEditors, "circuit-designer"])
                            }}
                            id='iot'
                        />
                        <label htmlFor="circuit-designer">Circuit Designer</label>
                    </div>
                    <div>
                        <Checkbox
                            checked={selectedEditors.includes("iot-monitor")}
                            onChange={(e) => {
                                selectedEditors.includes("iot-monitor") ? setSelectedEditors(selectedEditors.filter((editor) => editor !== "iot-monitor")) : setSelectedEditors([...selectedEditors, "iot-monitor"])
                            }}
                            id='iot-monitor'
                        />
                        <label htmlFor="iot-monitor">IOT Monitor</label>
                    </div>
                    <div>
                        <Checkbox
                            checked={selectedEditors.includes("ai-editor")}
                            onChange={(e) => {
                                selectedEditors.includes("ai-editor") ? setSelectedEditors(selectedEditors.filter((editor) => editor !== "ai-editor")) : setSelectedEditors([...selectedEditors, "ai-editor"])
                            }}
                            id='ai-editor'
                        />
                        <label htmlFor="ai-editor">AI</label>
                    </div>
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "20px",
                }}>

                    <Button
                        id='EditorSelectionSavingButton'
                        variant='contained'
                        size='small'
                        onClick={() => {
                            updateProject()

                        }}
                        color="primary"
                        sx={{
                            textTransform: "none",
                            borderRadius: "6px",
                            fontWeight: "bolder",
                            fontSize: "10px"
                        }}
                    >
                        Save Editor Configurations
                    </Button>

                </div>
            </Container>
        </div>
    )
}

export default EditorSelectionEditing