import React, { useEffect } from 'react'
import "driver.js/dist/driver.css";
import { useNavigate, useNavigation } from 'react-router-dom';
import HelpComponent from '../components/help-component';
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import { PageWiseGuides } from '../utils/PageWiseGuides';
import { routes } from '../routes/routes';
import { useLocation } from 'react-router-dom';



function isGuideShown(identifier: string): boolean {
    const data = localStorage.getItem('identifiers')
    if (!!data) {
        let identifiers = JSON.parse(data)
        if (identifiers.includes(identifier)) {
            return true;
        }
    }
    return false;
}




export default function TourContextProvider(props: {
    children: React.ReactNode
}) {
    const [currentGuide, setCurrentGuide] = React.useState<any[]>([]);
    const location = useLocation();
    function getTourForPage() {
        if (location.pathname.includes(routes.DASHBOARD.PROJECT.DESCRIPTION(""))) {
            return <HelpComponent handleTourGuideHelp={handleTourGuideHelp} />
        } else if (location.pathname.includes(routes.DASHBOARD.PROJECT.SETTINGS(""))) {
            return <HelpComponent handleTourGuideHelp={handleTourGuideHelp} />
        } else if (location.pathname.includes(routes.DASHBOARD.PROJECT.WORKSPACE.CIRCUIT(""))) {
            return <HelpComponent handleTourGuideHelp={handleTourGuideHelp} />
        } else if (location.pathname.includes(routes.DASHBOARD.PROJECT.WORKSPACE.MICROPYTHON(""))) {
            return <HelpComponent handleTourGuideHelp={handleTourGuideHelp} />
        } else if (location.pathname.includes(routes.DASHBOARD.MAIN)) {
            return <HelpComponent handleTourGuideHelp={handleTourGuideHelp} />
        } else if (location.pathname.includes(routes.DASHBOARD.PROJECT.WORKSPACE.MONITOR(""))) {
            return <HelpComponent handleTourGuideHelp={handleTourGuideHelp} />
        } else if (location.pathname.includes(routes.DASHBOARD.PROJECT.WORKSPACE.AI(""))) {
            return <HelpComponent handleTourGuideHelp={handleTourGuideHelp} />
        } else if (location.pathname.includes(routes.DASHBOARD.PROJECT.SETTINGS(""))) {
            return <HelpComponent handleTourGuideHelp={handleTourGuideHelp} />
        } else if (location.pathname.includes(routes.DASHBOARD.PROJECT.CREATE)) {
            return <HelpComponent handleTourGuideHelp={handleTourGuideHelp} />
        } else if (location.pathname.includes(routes.DASHBOARD.MODEL.CREATE)) {
            return <HelpComponent handleTourGuideHelp={handleTourGuideHelp} />
        }
    }
    async function handleTourGuideHelp() {
        console.log("help button clicked");
        const url = window.location.href;
        console.log(url);

        if (window.location.href.includes(routes.DASHBOARD.PROJECT.DESCRIPTION(""))) {
            driver({
                showProgress: true,
                steps: PageWiseGuides.PROJECT.OVERVIEW.guides.filter((guide) => guide.elementID == "" || !!document.querySelector(guide.elementID)).map(guide => {

                    return {
                        element: guide.elementID,
                        popover: {
                            title: guide.title,
                            description: guide.description,
                            side: "left",
                            align: 'start',
                        }
                    }
                })
            }).drive()

        } else if (window.location.href.includes(routes.DASHBOARD.PROJECT.SETTINGS(""))) {
            driver({
                showProgress: true,
                steps: PageWiseGuides.PROJECT.PROJECT_SETTINGS.guides.filter((guide) => guide.elementID == "" || !!document.querySelector(guide.elementID)).map(guide => {
                    return {
                        element: guide.elementID,
                        popover: {
                            title: guide.title,
                            description: guide.description,
                            side: "left",
                            align: 'start',
                        }
                    }
                })

            }).drive()
        } else if (window.location.href.includes(routes.DASHBOARD.PROJECT.WORKSPACE.CIRCUIT(""))) {
            driver({
                showProgress: true,
                steps: PageWiseGuides.PROJECT.CIRCUIT_DESIGNER.guides.filter((guide) => guide.elementID == "" || !!document.querySelector(guide.elementID)).map(guide => {
                    return {
                        element: guide.elementID,
                        popover: {
                            title: guide.title,
                            description: guide.description,
                            side: "left",
                            align: 'start',
                        }
                    }
                })

            }).drive()
        } else if (window.location.href.includes(routes.DASHBOARD.PROJECT.WORKSPACE.MICROPYTHON(""))) {
            driver({
                showProgress: true,
                steps: PageWiseGuides.PROJECT.MICROPYTHON_EDITOR.guides.filter((guide) => guide.elementID == "" || !!document.querySelector(guide.elementID)).map(guide => {
                    return {
                        element: guide.elementID,
                        popover: {
                            title: guide.title,
                            description: guide.description,
                            side: "left",
                            align: 'start',
                        }
                    }
                })

            }).drive()
        } else if (window.location.href.includes(routes.DASHBOARD.MAIN)) {
            driver({
                showProgress: true,
                steps: PageWiseGuides.PROJECT.DASHBOARD_MAIN.guides.filter((guide) => guide.elementID == "" || !!document.querySelector(guide.elementID)).map(guide => {
                    return {
                        element: guide.elementID,
                        popover: {
                            title: guide.title,
                            description: guide.description,
                            side: "left",
                            align: 'start',
                        }
                    }
                })

            }).drive()
        } else if (window.location.href.includes(routes.DASHBOARD.PROJECT.WORKSPACE.MONITOR(""))) {
            driver({
                showProgress: true,
                steps: PageWiseGuides.PROJECT.IOT_MONITOR.guides.filter((guide) => guide.elementID == "" || !!document.querySelector(guide.elementID)).map(guide => {
                    return {
                        element: guide.elementID,
                        popover: {
                            title: guide.title,
                            description: guide.description,
                            side: "left",
                            align: 'start',
                        }
                    }
                })

            }).drive()
        } else if (window.location.href.includes(routes.DASHBOARD.PROJECT.WORKSPACE.AI(""))) {
            driver({
                showProgress: true,
                steps: PageWiseGuides.PROJECT.AI_EDITOR.guides.filter((guide) => guide.elementID == "" || !!document.querySelector(guide.elementID)).map(guide => {
                    return {
                        element: guide.elementID,
                        popover: {
                            title: guide.title,
                            description: guide.description,
                            side: "left",
                            align: 'start',
                        }
                    }
                })

            }).drive()
        } else if (window.location.href.includes(routes.DASHBOARD.PROJECT.SETTINGS(""))) {
            driver({
                showProgress: true,
                steps: PageWiseGuides.PROJECT.PROJECT_SETTINGS.guides.filter((guide) => guide.elementID == "" || !!document.querySelector(guide.elementID)).map(guide => {
                    return {
                        element: guide.elementID,
                        popover: {
                            title: guide.title,
                            description: guide.description,
                            side: "left",
                            align: 'start',
                        }
                    }
                })

            }).drive()

        } else if (window.location.href.includes(routes.DASHBOARD.PROJECT.CREATE)) {
            driver({
                showProgress: true,
                steps: PageWiseGuides.PROJECT.CREATE_PROJECT.guides.filter((guide) => guide.elementID == "" || !!document.querySelector(guide.elementID)).map(guide => {
                    return {
                        element: guide.elementID,
                        popover: {
                            title: guide.title,
                            description: guide.description,
                            side: "left",
                            align: 'start',
                        }
                    }
                })

            }).drive()
        } else if (window.location.href.includes(routes.DASHBOARD.MODEL.CREATE)) {
            driver({
                showProgress: true,
                steps: PageWiseGuides.PROJECT.CREATE_MODEL.guides.filter((guide) => guide.elementID == "" || !!document.querySelector(guide.elementID)).map(guide => {
                    return {
                        element: guide.elementID,
                        popover: {
                            title: guide.title,
                            description: guide.description,
                            side: "left",
                            align: 'start',
                        }
                    }
                })

            }).drive()
        }
    }
    async function autoShowGuides() {
        console.log("help button clicked");
        const url = window.location.href;
        console.log(url);

        if (window.location.href.includes(routes.DASHBOARD.PROJECT.DESCRIPTION(""))) {
            driver({
                showProgress: true,
                steps: PageWiseGuides.PROJECT.OVERVIEW.guides.filter((guide) => !!document.querySelector(guide.elementID)).map(guide => {

                    return {
                        element: guide.elementID,
                        popover: {
                            title: guide.title,
                            description: guide.description,
                            side: "left",
                            align: 'start',
                        }
                    }
                })
            }).drive()

        } else if (window.location.href.includes(routes.DASHBOARD.PROJECT.SETTINGS(""))) {
            driver({
                showProgress: true,
                steps: PageWiseGuides.PROJECT.PROJECT_SETTINGS.guides.filter((guide) => !!document.querySelector(guide.elementID)).map(guide => {
                    return {
                        element: guide.elementID,
                        popover: {
                            title: guide.title,
                            description: guide.description,
                            side: "left",
                            align: 'start',
                        }
                    }
                })

            }).drive()
        } else if (window.location.href.includes(routes.DASHBOARD.PROJECT.SETTINGS(""))) {

        } else if (window.location.href.includes(routes.DASHBOARD.PROJECT.SETTINGS(""))) {

        } else if (window.location.href.includes(routes.DASHBOARD.PROJECT.SETTINGS(""))) {

        } else if (window.location.href.includes(routes.DASHBOARD.PROJECT.SETTINGS(""))) {

        } else if (window.location.href.includes(routes.DASHBOARD.PROJECT.SETTINGS(""))) {

        } else if (window.location.href.includes(routes.DASHBOARD.PROJECT.SETTINGS(""))) {

        }
    }
    // useEffect(() => {
    //     driver().destroy()
    //     setTimeout(() => {
    //         handleTourGuideHelp()
    //     }, 2000)
    // }, [])
    return (
        <div>
            {
                props.children
            }
            {
                getTourForPage()
            }
        </div>
    )
}


