import React, { useContext, useEffect } from 'react'
import { BlocklyWorkspace } from "react-blockly";
import { SettingContext } from '../../context/SettingsContext';
import javascriptGenerator from './configs/code-generator';
import JavascriptBlockToolBox from './configs/javascript-toolbox';
import javascriptResolver from './configs/code-generator';
import DarkThemeBlockly from '../../theme/blockly/dark';
import Blockly from './configs/javascript-blocks';

type Props = {
    JavascriptBlockToolBox:typeof JavascriptBlockToolBox,
    initialXML?: string,
    setCode: (code: string) => void,
    setXml: (xml: string) => void,
}
function JavaScriptBlockEditor({
    JavascriptBlockToolBox,
    initialXML,
    setCode,
    setXml,
}: Props) {
    const { settings } = useContext(SettingContext)
    const [initialXMLValue, setInitialXML] = React.useState(initialXML)
    // useEffect(() => {
    //     setTimeout(() => {
    //         if (!!localStorage.getItem("javascript-code")) {
    //             setCode(localStorage.getItem("javascript-code") as string);
    //         }
    //         if (!!localStorage.getItem("javascript-xml")) {
    //             let xml = localStorage.getItem("javascript-xml") as string
    //             setCode(xml);
    //             setInitialXML(xml);
    //         }
    //     }, 2000);
    // }, [initialXML])

    return (
        <div id='JavascriptEditor' style={{
            height: "100%",
            width: settings.screen === "mobile" ? "100%" : "50vw",
            display: "flex",
        }}>
            <BlocklyWorkspace
            key={initialXMLValue}
                toolboxConfiguration={
                    JavascriptBlockToolBox
                }
                initialXml={initialXMLValue}
                className="micro-python-editor"
                workspaceConfiguration={{
                    horizontalLayout: false,
                    trashcan: true,
                    zoom: {
                        controls: true,
                        wheel: true,
                        startScale: 1.0,
                        maxScale: 3,
                        minScale: 0.3,
                        scaleSpeed: 1.2,
                        pinch: true,
                    },
                    move: {
                        scrollbars: {
                            horizontal: true,
                            vertical: true
                        },
                        drag: true, wheel: false
                    },
                    renderer: "zelos",
                    theme: settings.themeMode==="dark"? DarkThemeBlockly:Blockly.Themes.Classic,

                }}
                onWorkspaceChange={(workspace) => {
                    const code= javascriptResolver.getJavascriptCode(workspace)
                    setCode(code)
                    localStorage.setItem("javascript-code", code)

                }}
                onXmlChange={(xml) => {
                    // console.log(xml);
                    setXml(xml);
                    localStorage.setItem("javascript-xml", xml)

                }}
            />
        </div>
    )
}

export default JavaScriptBlockEditor