import React, { useContext, useState } from 'react'
import { BackendConfigs } from '../../../../../config.environment'
import { UserContext } from '../../../../../context/UserContext'
import { eduCOBOTColorScheme, SettingContext } from '../../../../../context/SettingsContext'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { APIResponse } from '../../../../../types/Response'
import { routes } from '../../../../../routes/routes'
import { Button, Card, Checkbox, TextField } from '@mui/material'
import { ContentDialogBox } from '../../../../../components/dialog-box'
import { DNA } from 'react-loader-spinner'

type Props = {}

function CreateProjectPage({ }: Props) {
    const { user } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const [projectName, setProjectName] = useState("")
    const [projectDescription, setProjectDescription] = useState("")
    const [isCreateSuccess, setIsCreateSuccess] = useState(false)
    const [selectedEditors, setSelectedEditors] = useState<string[]>([])
    const navigate = useNavigate()


    async function createProject() {
        handleGlobalLoading(true, "Creating Project : " + projectName)
        if (!projectName) {
            toast.error("Project Name is required")
            handleGlobalLoading(false)
            return
        }
        if (!projectDescription) {
            toast.error("Project Description is required")
            handleGlobalLoading(false)
            return
        }
        if (selectedEditors.length === 0) {
            handleGlobalLoading(false)
            toast.error("At least one selection is required")
            return
        }
        try {
            const response = await fetch(`${BackendConfigs.url}/CreateProjectAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name: projectName,
                    description: projectDescription,
                    editors: selectedEditors.length > 0 ? selectedEditors : ["micropython-editor"],
                    ownerId: user.userData?.id
                })
            })
            if (response.status === 200) {
                const result = await response.json() as APIResponse
                if (result.success) {
                    toast.success(result.message)
                    setIsCreateSuccess(true)
                    const projectId = result.data.project._id
                    navigate(routes.DASHBOARD.PROJECT.DESCRIPTION(projectId))
                    // setTimeout(() => {
                    // }, 3000)

                } else {
                    toast.error(result.message)
                }
            }
        } catch (error) {
            console.log(error);
        }
        handleGlobalLoading(false)
    }


    return (
        <>
            <div style={{
                minHeight: "90vh",
                display: 'flex',
                margin: "0 auto",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: settings.screen === "desktop" ? "row" : "column",
                width: "95%"
            }}>

                <Card sx={{
                    padding: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "10px 5px",
                    flexDirection: "column",
                    width: "90%",
                    height: "100%"
                }}>
                    <div>
                        <img src="/Innovator_Bulb.png" style={{
                            maxWidth: "200px"
                        }} alt="" />
                    </div>
                    <div id='ProjectCreateContainer' style={{
                        padding: 10,
                        width: "100%"
                    }}>
                        <TextField
                            id='ProjectName'
                            fullWidth
                            label='Enter Project Name'
                            style={{
                                margin: 10
                            }}
                            value={projectName}
                            onChange={(e) => {
                                if (e.target.value.length > 50) {
                                    alert("Length should not be grater than 20 characters")
                                } else {
                                    setProjectName(e.target.value)
                                }
                            }}
                        />
                        <TextField
                            id='ProjectDescription'
                            fullWidth
                            label='Enter Project Description'
                            style={{
                                margin: 10
                            }}
                            value={projectDescription}
                            onChange={(e) => {
                                setProjectDescription(e.target.value)
                            }}
                            multiline
                            rows={4}
                        />
                        <div id='SelectedEditors'>
                            <h4>Select your required Development Environment (min. 1)</h4>
                            <div>
                                <Checkbox
                                    checked={selectedEditors.length === 4}
                                    onChange={(e) => {
                                        if (selectedEditors.length === 4) {

                                            setSelectedEditors([])
                                        } else {
                                            setSelectedEditors(["micropython-editor", "ai-editor", "circuit-designer", "iot-monitor"])

                                        }
                                    }}
                                    id='all'
                                />
                                <label htmlFor="all">Select All</label>
                            </div>
                            <div>
                                <Checkbox
                                    checked={selectedEditors.includes("micropython-editor")}
                                    onChange={(e) => {
                                        selectedEditors.includes("micropython-editor") ? setSelectedEditors(selectedEditors.filter((editor) => editor !== "micropython-editor")) : setSelectedEditors([...selectedEditors, "micropython-editor"])
                                    }}
                                    id='micropython-editor'
                                />
                                <label htmlFor="micropython-editor">Robotics</label>
                            </div>

                            <div>
                                <Checkbox
                                    checked={selectedEditors.includes("circuit-designer")}
                                    onChange={(e) => {
                                        selectedEditors.includes("circuit-designer") ? setSelectedEditors(selectedEditors.filter((editor) => editor !== "circuit-designer")) : setSelectedEditors([...selectedEditors, "circuit-designer"])
                                    }}
                                    id='iot'
                                />
                                <label htmlFor="circuit-designer">Circuit Designer</label>
                            </div>
                            <div>
                                <Checkbox
                                    checked={selectedEditors.includes("iot-monitor")}
                                    onChange={(e) => {
                                        selectedEditors.includes("iot-monitor") ? setSelectedEditors(selectedEditors.filter((editor) => editor !== "iot-monitor")) : setSelectedEditors([...selectedEditors, "iot-monitor"])
                                    }}
                                    id='iot-monitor'
                                />
                                <label htmlFor="iot-monitor">IOT Monitor</label>
                            </div>
                            <div>
                                <Checkbox
                                    checked={selectedEditors.includes("ai-editor")}
                                    onChange={(e) => {
                                        selectedEditors.includes("ai-editor") ? setSelectedEditors(selectedEditors.filter((editor) => editor !== "ai-editor")) : setSelectedEditors([...selectedEditors, "ai-editor"])
                                    }}
                                    id='ai-editor'
                                />
                                <label htmlFor="ai-editor">AI</label>
                            </div>
                        </div>

                        <div style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "end"
                        }}>
                            <Button id='CreateProjectButton' variant="contained" color="success" sx={{
                                textTransform: "none",
                                borderRadius: "6px",
                                fontWeight: "bolder",
                                // fontSize: "10px",
                                padding: "5px 50px"
                            }} size='small' onClick={() => {
                                createProject()
                            }}>
                                Create Project
                            </Button>
                        </div>
                    </div>

                </Card>
            </div>
            <ContentDialogBox
                isOpen={isCreateSuccess}
                onClose={() => {
                    // setIsCreateSuccess(false)
                    // setIsCreateDialogOpen(false)
                    // setIsDataChanged(true)
                }}
                title={<>
                    <h4 style={{
                        color:eduCOBOTColorScheme.gray,
                        marginLeft: "15px",
                    }}>
                    Project Created Successfully
                    </h4>
                
                </>}
                backdropStyle={{
                    backgroundColor:"transparent"
                }}
                paperStyle={{
                    background: "transparent",
                    borderRadius: 20,
                    backdropFilter: "blur(5px)",
                    display: "flex",
                    // alignItems: "center",
                    boxShadow:"0px 11px 15px -7px rgba(0,0,0,0.05),0px 24px 38px 3px rgba(0,0,0,0.05),0px 9px 46px 8px rgba(0,0,0,0.05)"

                }}
                content={<>
                    <div style={{

                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                    }}>
                        {/* <img src="https://cdn-icons-gif.flaticon.com/10282/10282582.gif" width={"250px"} alt="" /> */}
                        {/* <DNA
                            visible={true}
                            height="200"
                            width="200"
                            ariaLabel="dna-loading"
                            wrapperStyle={{}}
                            wrapperClass="dna-wrapper"
                        /> */}
                        <div style={{
                            padding:"100px 20px"
                        }}>

                            <div className="loader1" ></div>
                        </div>
                        <h4 style={{
                            color: eduCOBOTColorScheme.gray,
                        }}>Hang tight... setting up your project workspace!</h4>
                    </div>
                </>}
                maxWidth={"sm"}
            />
        </>

    )
}











export default CreateProjectPage