import { Button, Card, Tooltip } from "@mui/material";
import * as React from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { ContentDialogBox } from "../dialog-box";
import { eduCOBOTColorScheme } from "../../context/SettingsContext";
export interface DownloadsComponentProps { }

export function DownloadsComponent(props: DownloadsComponentProps) {
    const [downloadVisible, setIsDownloadVisible] = React.useState(false);
    return (
        <>
            <Tooltip title="Download Innovator Software">
                <Button
                    id="SoftWareDownloadButton"
                    onClick={() => {
                        setIsDownloadVisible(!downloadVisible);
                    }}
                >
                    <DownloadIcon />
                </Button>
            </Tooltip>
            <ContentDialogBox
                isOpen={downloadVisible}
                onClose={() => {
                    setIsDownloadVisible(false);
                }}

                title={<>
                    <h4 style={{
                        color: eduCOBOTColorScheme.white,
                        marginLeft: "15px",
                    }}>
                        Download Innovator Driver
                    </h4>

                </>}
                backdropStyle={{
                    // backgroundColor:"transparent"
                }}
                paperStyle={{
                    background: "#FFFFFF44",
                    borderRadius: 20,
                    backdropFilter: "blur(5px)",
                    display: "flex",
                    // alignItems: "center",
                    boxShadow: "0px 11px 15px -7px rgba(0,0,0,0.05),0px 24px 38px 3px rgba(0,0,0,0.05),0px 9px 46px 8px rgba(0,0,0,0.05)"

                }}
                content={
                    <>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                gap: "10px",
                            }}
                        >
                            <Card
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "20px 50px",
                                    gap: "10px",
                                    background: "#FFFFFF8A",
                                    backdropFilter: "blur(50px)",
                                    borderRadius: "15px"
                                }}
                            >
                                <img
                                    src="/images/Windows.png"
                                    style={{
                                        width: "150px",
                                    }}
                                    alt=""
                                />
                                <h3>
                                    Version : 3.5.0
                                </h3>
                                <Button
                                    sx={{
                                        textTransform: "none",
                                    }}
                                    fullWidth
                                    variant="contained"
                                    href="https://educobot-robotics.s3.ap-south-1.amazonaws.com/driver-software/Innovator-Driver.msi"
                                >
                                    Download
                                </Button>
                            </Card>
                            <Card
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "20px 50px",
                                    gap: "10px",
                                    background: "#FFFFFFAA",
                                    backdropFilter: "blur(50px)",
                                    borderRadius: "15px"
                                }}
                            >
                                <img
                                    src="/images/Apple.png"
                                    style={{
                                        width: "150px",
                                    }}
                                    alt=""
                                />
                                <h3>
                                    Version : 3.5.0
                                </h3>
                                <Button
                                    sx={{
                                        textTransform: "none",
                                    }}
                                    fullWidth
                                    variant="contained"
                                    href="https://educobot-robotics.s3.ap-south-1.amazonaws.com/driver-software/eduCOBOT-Robotics-Driver-MAC-3.5.zip"
                                >
                                    Download
                                </Button>
                            </Card>
                        </div>
                    </>
                }
            />
        </>
    );
}
