import { Button, Tooltip } from '@mui/material';
import * as React from 'react';
import InfoIcon from '@mui/icons-material/Info';
export interface Props {
    handleTourGuideHelp: () => {}
}

export default function HelpComponent({ handleTourGuideHelp }: Props) {
    return (
        <>
            <div style={{
                position: "fixed",
                bottom: 65,
                right: 10,
                width: "70px",
                zIndex: 999999

            }}>
                <Tooltip title="Start guided tour to explore features." placement='top' style={{
                    zIndex:9999999999
                }}>

                    <Button onClick={() => {
                        handleTourGuideHelp()
                    }}>
                        <InfoIcon />
                    </Button>
                </Tooltip>
            </div>
        </>
    );
}
