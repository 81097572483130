import React, { useContext, useState } from 'react'
import './editor.scss'
import AceEditor from "react-ace";
import "ace-builds/webpack-resolver";

import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/webpack-resolver";
import './editor.scss'



import { SettingContext } from '../../context/SettingsContext';

type Props = {
    code: string,
    setCode: (code: string) => void,
    language?:"javascript"|"python"
    isDisabled?: boolean
}

export default function CodeEditor({
    code,
    setCode,
    language="python",
    isDisabled=false
}: Props) {
    const {settings}=useContext(SettingContext)
    const [editorConfigs, setEditorConfigs] = useState({
        mode: language,
        font: 18,
        theme: settings.themeMode==="dark"?"monokai":"tomorrow",
        tab: 4,
    })
    return (
        <div id='CodeEditor' style={{
            minWidth:"49.9vw",
            width:"100%",
            height:"100%",
            // backgroundColor: "gray"
        }}>
            <AceEditor
                readOnly={isDisabled}
                placeholder="..start coding here.."
                mode={editorConfigs.mode}
                theme={editorConfigs.theme}
                name="Robotics-IDE"
                focus={!isDisabled}
                onChange={(value) => {
                    setCode(value)
                }}
                fontSize={editorConfigs.font}
                highlightActiveLine={true}
                showPrintMargin={false}
                value={code}
                setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                    showLineNumbers: true,
                    tabSize: editorConfigs.tab,
                    wrap: true,
                    displayIndentGuides: true,
                    wrapBehavioursEnabled: true,
                    spellcheck:true
                }}
                style={{
                    width: '100%',
                    border: "1px solid gray",
                    height: "100%",
                    // background: "red",
                    background:"transparent",
                }}
            />
        </div>
    )
}