import { Button, Tooltip } from '@mui/material';
import * as React from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import CodeIcon from '@mui/icons-material/Code';
import MonitorIcon from '@mui/icons-material/Monitor';
import MemoryIcon from '@mui/icons-material/Memory';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { routes } from '../../../../../routes/routes';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArticleIcon from '@mui/icons-material/Article';
import { LessonDataType } from '../../../../../types/Lesson';
export interface Props {
    lesson: LessonDataType;
}

export function LessonBottomBarComponent({
    lesson
}: Props) {
    const navigate = useNavigate()
    return (
        <>
            <div style={{
                position: "fixed",
                bottom: "0",
                height: "40px",
                left: 0
            }}>
                <Tooltip title="Go to Dashboard">
                    <Button style={{
                        background: "white"
                    }} onClick={() => {
                        navigate(routes.DASHBOARD.LESSONS);
                    }}>
                        <DashboardIcon />
                    </Button>
                </Tooltip>
            </div>
            <div id='LessonNavigation' style={{
                position: "fixed",
                bottom: "0",
                height: "50px",
                border: "1px solid #00000022",
                width: "min(100%,500px)",
                maxWidth: "100vw",
                left: "50%",
                transform: "translate(-50%,0)",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                gap: "5px",
                zIndex: 999999,
                background: "#0000001A",
                padding: "5px",
            }}>

                <Tooltip title="Theory">
                    <Button id='Home' variant={window.location.href.includes("theory") ? "outlined" : undefined} style={{
                        background: "white"
                    }} onClick={() => {
                        navigate(routes.DASHBOARD.LESSON.THEORY(lesson.lessonId));
                    }}>
                        <ArticleIcon />
                    </Button>
                </Tooltip>
                {
                    lesson.editors.includes("assembly") && <>

                        <Tooltip title="Assembly">
                            <Button id='Assembly' variant={window.location.href.includes("assembly") ? "outlined" : undefined} style={{
                                background: "white"
                            }} onClick={() => {
                                navigate(routes.DASHBOARD.LESSON.WORKSPACE.ASSEMBLY(lesson.lessonId));
                            }}>
                                <DashboardIcon />
                            </Button>
                        </Tooltip>
                    </>
                }
                {
                    lesson.editors.includes("quiz") && <>

                        <Tooltip title="Quiz">
                            <Button id='Quiz' variant={window.location.href.includes("quiz") ? "outlined" : undefined} style={{
                                background: "white"
                            }} onClick={() => {
                                navigate(routes.DASHBOARD.LESSON.WORKSPACE.MCQ(lesson.lessonId));
                            }}>
                                <DashboardIcon />
                            </Button>
                        </Tooltip>
                    </>
                }
                {
                    lesson.editors.includes("exam") && <>

                        <Tooltip title="Exam">
                            <Button id='Exam' variant={window.location.href.includes("exam") ? "outlined" : undefined} style={{
                                background: "white"
                            }} onClick={() => {
                                navigate(routes.DASHBOARD.LESSON.WORKSPACE.MCQ(lesson.lessonId));
                            }}>
                                <DashboardIcon />
                            </Button>
                        </Tooltip>
                    </>
                }
                {
                    lesson.editors.includes("circuit-designer") && <>
                        <Tooltip title="Circuit Designer">
                            <Button id='CircuitDesigner' variant={window.location.href.includes("circuit") ? "outlined" : undefined} style={{
                                background: "white"
                            }} onClick={() => {
                                navigate(routes.DASHBOARD.LESSON.WORKSPACE.CIRCUIT(lesson.lessonId));
                            }}>
                                {/* <MemoryIcon /> */}
                                <img src="https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/Circuit.svg" width={25} alt="" />
                            </Button>
                        </Tooltip>
                    </>
                }

                {
                    lesson.editors.includes("micropython-editor") && <>
                        <Tooltip title="Micropython Code Editor">

                            <Button id='MicroPythonCodeEditor' variant={window.location.href.includes("micropython") ? "outlined" : undefined} style={{
                                background: "white"
                            }} onClick={() => {
                                navigate(routes.DASHBOARD.LESSON.WORKSPACE.MICROPYTHON(lesson.lessonId));
                            }}>
                                <img src="https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/IDE.svg" width={25} alt="" />
                            </Button>
                        </Tooltip>

                    </>
                }
                {
                    lesson.editors.includes("iot-monitor") && <>
                        <Tooltip title="IOT Monitor">
                            <Button id='IOTMonitor' variant={window.location.href.includes("monitor") ? "outlined" : undefined} style={{
                                background: "white"
                            }} onClick={() => {
                                navigate(routes.DASHBOARD.LESSON.WORKSPACE.MONITOR(lesson.lessonId));
                            }}>
                                <MonitorIcon />
                            </Button>
                        </Tooltip>
                    </>
                }
                {
                    lesson.editors.includes("ai-editor") && <>
                        <Tooltip title="AI">

                            <Button id='AIEditor' variant={window.location.href.includes("ai") ? "outlined" : undefined} style={{
                                background: "white"
                            }} onClick={() => {
                                navigate(routes.DASHBOARD.LESSON.WORKSPACE.AI(lesson.lessonId));
                            }}>
                                <SmartToyIcon />
                            </Button>
                        </Tooltip>

                    </>
                }
                {/* <Tooltip  title="Settings">

                <Button id='Settings' variant={window.location.href.includes("settings") ? "outlined" : undefined} style={{
                    background:"white"
                    }} onClick={() => {
                        navigate(routes.DASHBOARD.LESSON.SETTINGS(lesson.lessonId));
                        }}>
                        <SettingsIcon />
                        </Button>
                        </Tooltip> */}
            </div>
        </>
    );
}
