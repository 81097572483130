export class JavascriptAudioPlayer {
    audio = new Audio();

    play(fileName: string,isMute:boolean=false) {
        this.audio = new Audio(fileName.includes('https') ? fileName : `/audios/${fileName}`);
        if(isMute){
            this.audio.volume = 0.0;
        }
        this.audio.pause();
        this.audio.play();
    }
    stop() {
        this.audio.volume = 0.0;
        this.audio.pause();
    }
    resume(){
        this.audio.volume = 1.0;
        this.audio.play();
    }
}


const audioPlayer = new JavascriptAudioPlayer();
export default  audioPlayer