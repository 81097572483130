import React, { createContext, Dispatch, useContext, useEffect, useReducer, useState } from 'react'
import { useGeolocated } from "react-geolocated";
import { SettingContext } from './SettingsContext';
import { ContentDialogBox } from '../components/dialog-box';


export type LocationStateType = {
    isLocationAvailable: boolean,
    locationBy:"ip" | "gps",
    latitude: number,
    longitude: number,
    city: string,
    country: string,
    region: string,
    postalCode: string,
    timezone: string,
}

export type LocationActionType = {
    type: "updateLocation",
    value: LocationStateType
}

const locationInitialState: LocationStateType = {
    locationBy: "ip",
    isLocationAvailable: false,
    latitude: 0,
    longitude: 0,
    city: "",
    country: "",
    region: "",
    postalCode: "",
    timezone: "",
}

function locationReducer(state: LocationStateType, action: LocationActionType): LocationStateType {
    switch (action.type) {
        case "updateLocation":
            return {
                ...state,
                locationBy: action.value.locationBy,
                isLocationAvailable: action.value.isLocationAvailable,
                latitude: action.value.latitude,
                longitude: action.value.longitude,
                city: action.value.city,
                country: action.value.country,
                region: action.value.region,
                postalCode: action.value.postalCode,
                timezone: action.value.timezone,
            }
        default:
            return state
    }
}
export const LocationContext = createContext({
    location: locationInitialState,
    changeLocation: (() => { }) as Dispatch<LocationActionType>,
})

function LocationContextProvider(props: {
    children: React.ReactNode
}) {
    const [location, changeLocation] = useReducer(locationReducer, locationInitialState)
    const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
        positionOptions: {
            enableHighAccuracy: false,
        },
        userDecisionTimeout: 5000,
    });
    async function getLocationByIP() {
        
    }
    useEffect(() => {
        console.log(coords, isGeolocationAvailable, isGeolocationEnabled);
        if(isGeolocationAvailable && isGeolocationEnabled){
            console.log("Location Found");
            navigator.geolocation.getCurrentPosition((position) => {
                console.log(position);
                changeLocation({
                    type:"updateLocation",
                    value:{
                        ...location,
                        isLocationAvailable:isGeolocationAvailable,
                        locationBy:"gps",
                        latitude:position.coords.latitude,
                        longitude:position.coords.longitude
                    }
                })  
            });
        }
    }, [coords, isGeolocationAvailable, isGeolocationEnabled])
    return (
        <LocationContext.Provider value={{ location, changeLocation }}>
            {props.children}
            {
                (!isGeolocationAvailable || !isGeolocationEnabled) && <LocationRequestCard />
            }
        </LocationContext.Provider>
    )
}
export default LocationContextProvider




export function LocationRequestCard() {
    const [isLocationGiven, setIsLocationGiven] = useState(false)
    const { settings } = useContext(SettingContext)
    if (!isLocationGiven)
        return (
            <>
                {
                    settings.screen === "desktop" &&
                    <div style={{
                        position: "fixed",
                        top: "10px",
                        left: "30%",
                        zIndex: 9999999
                    }}>
                        <img src="https://cdn-icons-png.flaticon.com/512/25/25339.png" style={{
                            maxWidth: 100,
                            animation:"popping-animation 1s infinite",
                        }} alt="" />
                    </div>
                }
                <ContentDialogBox
                    isOpen={!isLocationGiven}
                    onClose={() => setIsLocationGiven(true)}
                    title="Location Request"
                    maxWidth="lg"
                    content={
                        <>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                paddingBottom: "50px",
                            }}>
                                <p style={{
                                    textAlign: "center"
                                }}>For Better Security and Performance, Please give access to your location</p>
                                <img src="https://img.freepik.com/premium-vector/location-support-icon_192037-3240.jpg" style={{ width: "300px" }} alt="" />
                                <h6>Click on "Allow" in the popup window</h6>
                            </div>
                        </>
                    }
                />
            </>
        )
    return <></>
}