const PORT = process.env.PORT || 3000;



const NODE_ENVIRONMENT = process.env.NODE_ENV || "production"


const DEPLOYMENT_TYPE = NODE_ENVIRONMENT === "development" ? "development" : "production"
console.log(process.env.DEPLOYMENT_TYPE);

const BackendURL = DEPLOYMENT_TYPE === "production" ? "https://backend.innovator.educobot.com" : process.env.DEPLOYMENT_TYPE === "testing" ? "https://backend.innovator.educobot.com" : "http://localhost:9000"
const FrontendURL = DEPLOYMENT_TYPE === "production" ? "https://iot.educobot.com" : process.env.DEPLOYMENT_TYPE === "testing" ? "https://iot.educobot.com" : "http://localhost:3010"


export const MainConfigs = {
    NODE_ENVIRONMENT,
    DEPLOYMENT_TYPE,
}



export const BackendConfigs = {
    url: BackendURL,
    educobotBackend:"https://backend.educobot.com",
    apis: {
        auth: {

        },
        users: {

        }
    }
};

export const FrontendConfigs = {
    url: FrontendURL,
};


export const ServicesConfigs = {
    payment: {

    },
    storage: {
        aws: {
            AWS_ACCESS_ID: process.env.AWS_ACCESS_ID || "AKIATV35CQSBHKHM7CNB",
            AWS_SECRET_ACCESS_ID: process.env.AWS_SECRET_ACCESS_ID || "v16XDQ5aK06R1uwTDeRCsg62gA+p1p3qeBuWq3EG",
            AWS_REGION: process.env.AWS_REGION || "ap-south-1",
            S3_BUCKET_NAME: process.env.S3_BUCKET_NAME || "educobot-robotics",
        }
    },
    syncfusion: {
        KEY: "Ngo9BigBOggjHTQxAR8/V1NCaF5cWWJCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXhfdHRcQ2ZYWUZzX0c="
    }
}
console.log(ServicesConfigs);
