import * as React from 'react';
import DashboardLayout from '../../../layouts/dashboard/DashboardLayout';
import APIKeySettings from './APIKeySettings';
import LanguageSettings from './LanguageSettings';

export type SettingsPageProps = {
}

export default function SettingsPage(props: SettingsPageProps) {
    return (
        <div>
            <DashboardLayout title='Settings'>
                <APIKeySettings/>
                <LanguageSettings/>
            </DashboardLayout>
        </div>
    );
}
