import React, { useContext, useEffect, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Card, CardActions } from '@mui/material';
import { eduCOBOTColorScheme } from '../../../../../context/SettingsContext';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../../../routes/routes';
import { UserContext } from '../../../../../context/UserContext';
import { SettingContext } from '../../../../../context/SettingsContext';
import { BackendConfigs } from '../../../../../config.environment';
import { APIResponse } from '../../../../../types/Response';
import { toast } from 'react-toastify';
import { ProjectDataType } from '../../../../../types/Dashboard';
import { color } from '../../../../../theme/index';
import { ModelType } from '../../../../../types/Database';
import { ContentDialogBox } from '../../../../../components/dialog-box';

type Props = {
    projectData: ProjectDataType,
    refreshProject: () => void
}


function ModelConnectionComponent({
    projectData,
    refreshProject
}: Props) {
    const navigate = useNavigate()
    const { user } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const [modelsList, setModelsList] = React.useState<ModelType[]>([])
    const [modelConnectSuccess, setModelConnectSuccess] = useState(false)
    async function fetModels() {
        handleGlobalLoading(true, "Fetching Projects")
        setModelsList([])
        try {
            const response = await fetch(`${BackendConfigs.url}/GetAllModelsByOwnerIdAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    ownerId: user.userData?.id
                })
            })
            if (response.status === 200) {
                const result = await response.json() as APIResponse
                setModelsList(result.data)

            }
        } catch (error) {
            console.log(error);
        }
        handleGlobalLoading(false)
    }


    async function connectTheModel(modelId: string) {
        handleGlobalLoading(true, "Connecting Model")
        try {
            const response = await fetch(`${BackendConfigs.url}/ConnectModelToWorkspaceAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    projectId: projectData.project._id,
                    modelId

                })
            })
            if (response.status === 200) {
                setModelConnectSuccess(true)
                refreshProject()
            }
        } catch (error) {
            console.log(error);
        }
        handleGlobalLoading(false)
    }



    useEffect(() => {

        fetModels()
    }, [])
    return (
        <div id='ModelConnectionSection' style={{
            width: "95%",
            margin: "auto",
            padding: "10px",
            // border:"1px solid gray",
            minHeight: "45vh",
        }}>

            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px"
            }}>

                <h4>
                    Connect Any model to this project
                </h4>
                <Button id='CreateProjectButton' variant="contained" color="secondary" sx={{
                    textTransform: "none",
                    borderRadius: "6px",
                    fontWeight: "bolder",
                    // fontSize: "10px",
                    padding: "5px 20px"
                }} size='small' onClick={() => {
                    navigate(routes.DASHBOARD.MODEL.CREATE + "?project_id=" + projectData.project._id)
                }}>
                    Create Model
                </Button>
            </div>
            <div>
                {
                    !!projectData.aiWorkspace.model ? <>
                        <Card style={{
                            padding: "10px",
                            borderRadius: "15px",
                            background: "#0000001A",
                        }}>
                            <p style={{
                                textAlign: "center",
                            }}>This Project is Connected to</p>
                            <h3 style={{
                                textAlign: "center",
                            }}>
                                {projectData.aiWorkspace.model.name}
                            </h3>
                        </Card>

                    </> : <>
                        <Card style={{
                            padding: "10px"
                        }}>
                            <p style={{
                                textAlign: "center",
                            }}>No models are connected to this project</p>

                        </Card>
                    </>
                }

            </div>
            <div style={{
                display: "flex",
                justifyContent: settings.screen === "mobile" ? "center" : "start",
                alignItems: "start",
                flexWrap: "wrap",
                maxHeight: "45vh",
                overflowY: "auto",
                margin: "0 10px"
            }}>
                {
                    modelsList && modelsList.length > 0 && modelsList.map(project => {
                        return <ModelCard key={project._id} data={project} reFetch={fetModels} isConnected={projectData.aiWorkspace.model && projectData.aiWorkspace.model._id == project._id} connectModel={connectTheModel} />
                    })
                }
                {
                    modelsList.length === 0 && <Card style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "30vh",
                        margin: "10px",
                        padding: "10px",
                        boxShadow: "0 0 5px #ccc",
                        borderRadius: "10px"
                    }}>

                        <p style={{ textAlign: "center" }}>No Models Found</p>
                        {/* <Button onClick={() => {
                            navigate(routes.DASHBOARD.MODEL.CREATE + "?project_id=" + projectData.project._id)
                        }}
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{
                                textTransform: "none"
                            }}
                        >
                            Create Model
                        </Button> */}
                    </Card>
                }
            </div>
            <ContentDialogBox
                isOpen={modelConnectSuccess}
                onClose={() => {
                    setModelConnectSuccess(false)
                }}
                title={<>
                    <h4 style={{
                        color: eduCOBOTColorScheme.white,
                        marginLeft: "15px",
                        fontSize: "1.3em",
                    }}>
                        Model Connected Successfully
                    </h4>

                </>}
                backdropStyle={{
                    // backgroundColor:"transparent"
                }}
                paperStyle={{
                    background: "#FFFFFF44",
                    borderRadius: 20,
                    backdropFilter: "blur(5px)",
                    display: "flex",
                    // alignItems: "center",
                    boxShadow: "0px 11px 15px -7px rgba(0,0,0,0.05),0px 24px 38px 3px rgba(0,0,0,0.05),0px 9px 46px 8px rgba(0,0,0,0.05)"

                }}                
                content={<>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column"
                    }}>
                        <div style={{
                            padding:"20px",
                        }}>
                            <div className="loader2"></div>
                        </div>
                        {/* <img src="https://cdn-icons-png.flaticon.com/512/1372/1372569.png" width={"200px"} alt="" /> */}
                        {/* <h3>Let's build the AI logic. Click the Robot Icon to start!</h3> */}
                        <ul style={{
                            padding: "20px",
                            color: eduCOBOTColorScheme.white,
                            fontSize: "1.1em",
                        }}>
                            <li>
                                Build the component code in the Micro Python IDE, then click the Robot Icon to build AI logic
                            </li>
                            <li>
                                If the code is already built, click the Robot Icon to start AI logic
                            </li>
                        </ul>
                    </div>
                </>}
                maxWidth={"sm"}

            />
        </div>
    )
}




function ModelCard({ data, reFetch, connectModel, isConnected }: {
    data: ModelType,
    reFetch: () => void,
    connectModel: (modelId: string) => void,
    isConnected: boolean
}) {
    const navigate = useNavigate()
    const { user } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)


    return (
        <>
            <Card style={{
                width: "100%",
                // minHeight: "min(250px, 500px)",
                display: "flex",
                flexDirection: "row",
                // flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
                borderRadius: "20px",
                margin: "10px",
                background: "#0000001A",
                // backdropFilter: "blur(10px)",
                transition: "all 0.3s",
                padding: 10
            }}
            >

                <img src={"https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/website-matches/Innovator-logo.png"} alt="" style={{
                    maxWidth: "35px",
                    maxHeight: "35px",
                    // marginBottom: "20px",
                }} />
                <h3 style={{
                    width: "100%",
                    color: eduCOBOTColorScheme.black,
                    padding: "10px",
                }} >
                    {
                        data.name
                    }
                </h3>
                <Button size='small' style={{
                    textTransform: "none",
                    padding: "5px 20px"
                }} variant='contained' color={isConnected ? "success" : "primary"} onClick={() => {
                    connectModel(data._id)
                }}>
                    {
                        isConnected ? "Connected" : "Connect"
                    }
                </Button>
            </Card>



        </>

    )
}

export default ModelConnectionComponent