export const Languages = [
    {
        label: "English (U.K)",
        value: "British English",
        icon: "/imgs/flags_icon/united-kingdom.png",
    },
    {
        label: "English (Ind)",
        value: "Indian English",
        icon: "/imgs/flags_icon/india.png",
    },
    {
        label: "English (E.Africa)",
        value: "African English",
        icon: "/imgs/flags_icon/central-african-republic.png",
    },
    {
        label: "English (U.S.A)",
        value: "American English",
        icon: "/imgs/flags_icon/usa.png",
    },
    {
        label: "Marathi",
        value: "Marathi",
        icon: "/imgs/flags_icon/india.png",
    },
    {
        label: "Hindi",
        value: "Hindi",
        icon: "/imgs/flags_icon/india.png",
    },
    {
        label: "Spanish",
        value: "Spanish",
        icon: "/imgs/flags_icon/usa.png",
    },
    {
        label: "French",
        value: "French",
        icon: "/imgs/flags_icon/usa.png",
    },
    {
        label: "Chinese",
        value: "Chinese",
        icon: "/imgs/flags_icon/usa.png",
    },
    {
        label: "Italian",
        value: "Italian",
        icon: "/imgs/flags_icon/usa.png",
    },
    {
        label: "Swahili",
        value: "Swahili",
        icon: "/imgs/flags_icon/usa.png",
    },
];
