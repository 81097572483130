const { GoogleGenerativeAI } = require("@google/generative-ai");




export class GoogleGeminiGenerativeAPIService {
    model: any;
    previousPrompts: string[];
    responses: string[]
    constructor() {
        const genAI = new GoogleGenerativeAI("AIzaSyAC-i44HAN43gYXERb-y305oGI4GcGtDQo");
        this.model = genAI.getGenerativeModel({ model: "gemini-1.5-flash" });
        this.previousPrompts = []
        this.responses = []
    }
    async getPromptResponse(prompt:string) {
        console.log(prompt);
        
        const result = await this.model.generateContent([prompt]);
        console.log(result.response.text());
        return result.response.text();
    }

}
const googleGeminiGenerativeAPIService=new GoogleGeminiGenerativeAPIService();
export default googleGeminiGenerativeAPIService;