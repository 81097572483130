import * as React from 'react';
import DashboardLayout from '../../../layouts/dashboard/DashboardLayout';
import Lessons from './Lessons';

export type LessonsPageProps = {
}

export default function LessonsPage(props: LessonsPageProps) {
    return (
        <div>
            <DashboardLayout title='Lessons'>
                <Lessons/>
            </DashboardLayout>
        </div>
    );
}
