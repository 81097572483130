import {
    Button,
    Container,
    Stack,
    ToggleButton,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useContext } from "react";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import DehazeIcon from "@mui/icons-material/Dehaze";
import { Link, useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import {
    eduCOBOTColorScheme,
    SettingContext,
} from "../../context/SettingsContext";
import { SettingsIcon } from "lucide-react";
import { DownloadsComponent } from "../../components/downloads";
import { routes } from "../../routes/routes";
import AddIcon from "@mui/icons-material/Add";
type Props = {
    title: string;
    toggleNavBarOpen?: () => void;
};

export default function DashboardTopBar({
    title = "eduCOBOT Innovator",
    toggleNavBarOpen,
}: Props) {
    const { settings, changeSettings, changeThemeScheme, toggleTheme } =
        useContext(SettingContext);
    const navigate = useNavigate()
    return (
        <div
            style={{
                maxHeight: "10vh",
            }}
        >
            <Typography
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px",
                    position: "relative",
                    top: 0,
                    zIndex: "1000",
                    background: "white",
                }}
            >
                <h3
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: eduCOBOTColorScheme.gray,
                    }}
                >
                    {settings.screen === "mobile" && (
                        <Typography
                            component={"div"}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "20px",
                            }}
                            onClick={() => {
                                toggleNavBarOpen && toggleNavBarOpen();
                            }}
                        >
                            <DehazeIcon />
                        </Typography>
                    )}
                    {title}
                </h3>
                <div>
                    <Stack
                        direction="row"
                        spacing={0}
                        sx={{
                            alignItems: "center",
                        }}
                    >
                        <div>
                            <Tooltip title="Create Model">
                                <Button
                                    id="ModelCreateButton"
                                    size="large"
                                    variant="text"
                                    sx={{
                                        textTransform: "none",
                                    }}
                                    onClick={() => {
                                        navigate(routes.DASHBOARD.MODEL.CREATE)
                                    }}
                                >
                                    <img src="https://icons.veryicon.com/png/o/miscellaneous/iconpack-00/new-project-4.png" style={{
                                        width: "25px",
                                    }} />
                                </Button>
                            </Tooltip>
                        </div>
                        <div>
                            <Tooltip title="Create Project">
                                <Button
                                    id="ProjectCreateButton"
                                    size="large"
                                    variant="text"
                                    sx={{
                                        textTransform: "none",
                                    }}
                                    onClick={() => {
                                        navigate(routes.DASHBOARD.PROJECT.CREATE)
                                    }}
                                >
                                    <img src="https://uxwing.com/wp-content/themes/uxwing/download/business-professional-services/add-project-icon.png" style={{
                                        width: "25px",
                                    }} />
                                </Button>
                            </Tooltip>
                        </div>
                        <div>
                            <Tooltip title="go to https://educobot.com">
                                <Button
                                    size="small"
                                    color="primary"
                                    component={Link}
                                    to={"https://educobot.com/auth/login"}
                                    style={{ fontSize: "10px", color: eduCOBOTColorScheme.black }}
                                >
                                    <HomeIcon
                                        style={
                                            {
                                                // fontSize: "10px"
                                            }
                                        }
                                    />
                                </Button>
                            </Tooltip>
                        </div>
                        <div>
                            <DownloadsComponent />
                        </div>
                    </Stack>
                </div>
            </Typography>
        </div>
    );
}
