import * as React from 'react';
import { Card } from '@mui/material';
import Markdown from "markdown-to-jsx"
import HighlightedCode from './HighlightedCode';

export interface Props {
    text: string,
    fromUser: boolean,
}

export default function ChatComponent({
    text,
    fromUser,
}: Props) {
    function returnSegmentedTexts() {
        return text.split("```").map(function (text, index) {
            if (index % 2 == 0)
                return {
                    type: "text",
                    value: text
                }
            else
                return {
                    type: "code",
                    value: text.split("\n").slice(1).join("\n"),
                    language: text.split("\n").slice(0, 1)[0]
                }
        })
    }








    React.useEffect(() => {
        console.log(text);
        console.log(returnSegmentedTexts());
    }, [])
    return (
        <div style={{
            width: "100%",
            display: "flex",
            padding: "10px",
            justifyContent: !fromUser ? "start" : "end",
        }}>
            <Card style={{
                maxWidth: "60%",
                padding: "10px 20px",
                margin: "10px 20px",
                borderRadius: "10px",
                fontSize: "0.8rem",
                background: "#C9E0EF",
                backdropFilter: "blur(10px)",
                overflow: "visible"
            }}  >
                <div style={{
                    position: "absolute",
                    width: "20px",
                    height: "20px",
                    background: "#C9E0EF",
                    top: "10px",
                    ...(fromUser?{
                        right: "-8px",
                    }:{
                        left: "-8px",
                    }),
                    rotate: "45deg"
                }} />
                {
                    returnSegmentedTexts().map((segment, index) => {
                        if (segment.type === "text")
                            return (
                                <div key={index}>
                                    <Markdown>
                                        {segment.value}
                                    </Markdown>
                                </div>
                            )
                        else
                            return (
                                <div key={index}>
                                    <HighlightedCode
                                        language={segment.language as string}
                                        children={segment.value}
                                    />
                                </div>
                            )
                    })
                }
            </Card>
        </div>
    );
}
