import React, { createContext, useEffect } from 'react'
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import { Button, Tooltip } from '@mui/material';
import { BookText } from 'lucide-react';
import { CircuitJSONs } from '../utils/CircuitJSONs';


export const LessonPlayerContext = createContext({
    updateCircuit: (func: any) => { },
    UpdateMicroPythonCode: (func: any) => { },
    UpdateJavascriptCode: (func: any) => { },
    UpdateMonitor: (func: any) => { },

})



export default function LessonPlayerProvider(props: {
    children: React.ReactNode
}) {
    let updateCircuitFunction: any


    useEffect(() => {




    }, [])
    function startLesson() {
        console.log(updateCircuitFunction);
        const driverObj = driver({
            overlayColor:"transparent",
            showProgress: true,
            steps: [
                {
                    element: '#lesson-tour-position', popover: {
                        title: 'Let`s Connect Esp32 shield to touch sensor', description: '', side: "left", align: 'start',

                    },
                },
                {
                    element: '#lesson-tour-position', popover: {
                        title: 'Drag and drop ESP32 into workspace', description: '', side: "left", align: 'start',
                        // onPrevClick: () => {
                        // },
                        // onNextClick: () => {
                        // },
                        onPopoverRender: () => {
                            updateCircuitFunction(CircuitJSONs.STEP_1)

                        }

                    },
                },
                {
                    element: '#lesson-tour-position', popover: {
                        title: 'Now drag and drop touch sensor on workspace', description: '', side: "left", align: 'start',
                        // onPrevClick: () => {
                        //     updateCircuitFunction(CircuitJSONs.STEP_1)
                        // },
                        // onNextClick: () => {
                        // }
                        onPopoverRender: () => {
                            updateCircuitFunction(CircuitJSONs.STEP_2)

                        }
                    }
                },
                {
                    element: '#lesson-tour-position', popover: {
                        title: 'Now Connect Digital pin on esp32 shield of touch sensor port to dout pin on touch sensor', description: '', side: "left", align: 'start', 
                        onPopoverRender: () => {
                            updateCircuitFunction(CircuitJSONs.STEP_3)

                        }
                    }
                },
                {
                    element: '#lesson-tour-position', popover: {
                        title: 'Do same for GND Pin ', description: '', side: "left", align: 'start', 
                        onPopoverRender: () => {
                            updateCircuitFunction(CircuitJSONs.STEP_4)

                        }
                    }
                },
                {
                    element: '#lesson-tour-position', popover: {
                        title: 'Do same For VCC pin', description: '', side: "left", align: 'start', 
                        onPopoverRender: () => {
                            updateCircuitFunction(CircuitJSONs.STEP_5)

                        }
                    }
                },
                {
                    element: '#lesson-tour-position', popover: {
                        title: 'Now change color of wires', description: '', side: "left", align: 'start', 
                        onPopoverRender: () => {
                            updateCircuitFunction(CircuitJSONs.STEP_6)

                        }
                    }
                },
            ]
        });
        driverObj.drive();

    }
    return (
        <LessonPlayerContext.Provider value={{
            updateCircuit: (func: any) => { updateCircuitFunction = func; },
            UpdateMicroPythonCode: () => { },
            UpdateJavascriptCode: () => { },
            UpdateMonitor: () => { },
        }} >

            <div>
                {
                    props.children
                }
                {/* <div style={{
                    position: "fixed",
                    bottom: 105,
                    right: 10,
                    width: "70px",
                    zIndex: 999999

                }}>
                    <Button onClick={() => {
                        startLesson()
                    }}>
                        <BookText />
                    </Button>
                </div> */}
            </div>
        </LessonPlayerContext.Provider>
    )
}
