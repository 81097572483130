import { Button, Card } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { eduCOBOTColorScheme, SettingContext } from '../../../../../context/SettingsContext'
import { ProjectDataType } from '../../../../../types/Dashboard'
import { format, formatDistance, formatRelative, subDays, subHours } from 'date-fns'
import { useNavigate } from 'react-router-dom'


export function getImagePathByComponentName(name: string) {
    console.log(name);

    if (name === "eduCOBOT-Shield") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/Sheild_with_ESP32.svg"
    }
    if (name === "New-eduCOBOT-Shield") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/New_Sheild.svg"
    }
    if (name === "esp32") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/ESP32_Vertical.svg"
    }
    if (name === "IR-Sensor") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/IR+Sensor.svg"
    }
    if (name === "Touch-Sensor") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/Touch+Sensor.svg"
    }
    if (name === "LDR-Sensor") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/LDR_PCB.svg"
    }
    if (name === "DHT11-Sensor") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/DHT11.svg"
    }
    if (name === "MQ2-Sensor") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/Smoke_Sensor.svg"
    }
    if (name === "RFID") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/RFID.svg"
    }
    if (name === "UltraSonic") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/Ultrasonic.svg"
    }
    if (name === "Keypad") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/Key_Pad.svg"
    }
    if (name === "LCD") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/LCD.svg"
    }
    if (name === "LED") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/LED_PCB.svg"
    }
    if (name === "Buzzer") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/Buzzer_PCB.svg"
    }
    if (name === "OLED") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/OLED.svg"
    }
    if (name === "Servo") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/SERVO_MOTOR.svg"
    }
    if (name === "Accelerometer") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/ADXL345.svg"
    }
    if (name === "BO-Motor") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/BO+Motor.svg"
    }
    if (name === "Push-Button") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/Tactile+Switch.svg"
    }
    if (name === "RGB-LED") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/RGB_LED.svg"
    }
    if (name === "DC-Motor") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/DC+Motor.svg"
    }
    return ""
}















type Props = {
    project: ProjectDataType,
    isBluePrintDescription?: boolean
}

function ProjectDescription({ project, isBluePrintDescription = false }: Props) {
    const [isModuleActive, setIsModuleActive] = useState(false)
    const [isInactiveInfo, setIsInactiveInfo] = useState(false)
    const { settings } = useContext(SettingContext)
    const navigate = useNavigate()
    function handleActiveStatus() {
        if (!project) return
        if (!project.lastInteraction) return
        if (Date.now() - parseInt(project.lastInteraction) < 300000) {
            setIsModuleActive(true)
        }
    }
    useEffect(() => {
        handleActiveStatus()
    }, [project])
    return (
        <div style={{
            minHeight: "30vh",
            display: 'flex',
            margin: "0 auto",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "95%"
        }}>
            <Card style={{
                minHeight: "150px",
                width: "100%",
                margin: "10px",
                padding: "10px",
                boxShadow: "0 0 5px #ccc",
                borderRadius: "10px",
                background: "#0000000A",
            }}>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    flexDirection: settings.screen === "mobile" ? "column" : "row",
                }}>
                    <img src={project.project.blueprintImage} alt="" style={{
                        maxHeight: settings.screen === "mobile" ? "200px" : "100px",
                    }} />
                    <div style={{
                        margin: "0 10px",
                        width: "100%"
                    }}>
                        {
                            project.project.isCreatedFromBlueprint &&
                            <Button size='small' variant="outlined" color='warning' style={{
                                fontSize: "9px",
                                textTransform: "none",
                                margin: "5px 0",
                                padding: "2px 10px",
                            }}>
                                Created from Blueprint
                            </Button>
                        }
                        <h4 id='project-title' style={{
                            // margin: "10px 0",
                            // textAlign: "center",
                        }}>{project.project.name}</h4>
                        {
                            !isBluePrintDescription &&

                            <p id='project-creation-time' style={{
                                fontSize: "10px",
                                fontWeight: "bold",
                                color: eduCOBOTColorScheme.blue,
                                textDecoration: 'underline',
                                margin: "10px 0",
                                // textAlign:"center"
                            }}>
                                Created   {formatDistance(new Date(parseInt(project.project.createdAt)), new Date(), { addSuffix: true })}
                            </p>
                        }
                        <h5 id='project-description' style={{
                            color: eduCOBOTColorScheme.gray,
                            fontSize: "12px",
                        }}>{project.project.description}</h5>
                    </div>
                </div>

            </Card>






            <Card style={{
                minHeight: "150px",
                width: "100%",
                margin: "10px",
                padding: "10px",
                boxShadow: "0 0 5px #ccc",
                borderRadius: "10px",
                background: "#0000000A",
            }}>

                <div id='components-list' style={{
                    display: "flex",
                    justifyContent: settings.screen === "mobile" ? "center" : "start",
                    flexWrap: "wrap",
                    margin: "0 10px",

                }}>
                    <h6 style={{
                        width: "100%",
                    }}>Hardware in Use</h6>

                    {
                        project.circuit.sensorsList.length === 0 && <>

                            <Card style={{
                                width: "100%",
                                minHeight: "120px",
                                display: "flex",
                                padding: "10px",
                                margin: "10px",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                borderRadius: "10px",
                                fontSize: "15px",

                            }}>
                                No Sensors are Linked Yet
                            </Card>
                        </>
                    }
                    {
                        project.circuit.sensorsList.map((sensor, index) => {
                            return (
                                <Card style={{
                                    width: "200px",
                                    minHeight: "120px",
                                    display: "flex",
                                    padding: "10px",
                                    margin: "10px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    borderRadius: "10px",
                                    background: "#0000001A",

                                }}>
                                    <img src={getImagePathByComponentName(sensor)} style={{
                                        maxHeight: "100px",
                                        maxWidth: "180px"
                                    }} alt="" />
                                    <h6>{sensor}</h6>
                                </Card>
                            )
                        })
                    }

                </div>
            </Card>
            <Card style={{
                minHeight: "150px",
                width: "100%",
                margin: "10px",
                padding: "10px",
                boxShadow: "0 0 5px #ccc",
                borderRadius: "10px",
                background: "#0000000A",
            }}>
                {
                    !isBluePrintDescription &&

                    <div style={{
                        width: "100%",
                        minHeight: "20vh",
                        display: 'flex',
                        margin: "0 10px",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column"
                    }} id='device-active-status'>
                        <div style={{
                            minHeight: "20vh",
                            width: "100%",
                            display: 'flex',
                            margin: "0 10px",
                            justifyContent: "start",
                            alignItems: "center",
                            flexDirection: "row",
                            flexWrap: "wrap",
                        }} >
                            <h6 style={{
                                width: "100%",
                            }}>Hardware Status</h6>
                            <Card onMouseOver={() => {
                            setIsInactiveInfo(true)
                        }} onMouseLeave={() => {
                            setIsInactiveInfo(false)
                        }} style={{
                                width: "200px",
                                height: "120px",
                                display: "flex",
                                justifyContent: "space-around",
                                flexDirection: "column",
                                alignItems: "center",
                                margin: "10px",
                                padding: "10px",
                                boxShadow: "0 0 5px #ccc",
                                borderRadius: "10px",
                                background: "#0000001A",
                                cursor: "pointer",
                            }}>
                                <div style={{
                                    fontSize: "12px",
                                }}>
                                    Device Status : {
                                        isModuleActive ? "Active" : "inactive"
                                    }
                                </div>
                                <img src={isModuleActive ? "https://cdn-icons-png.flaticon.com/128/8622/8622132.png" : "https://vectorified.com/images/status-icon-33.png"} width={40} alt="" />
                            </Card>
                        </div>
                        {
                            !isModuleActive && isInactiveInfo &&
                            <div style={{
                                position: "absolute",
                            }}>
                                <div style={{
                                    color: "red",
                                }}>

                                    Connection Inactive.
                                </div>
                                <ul>
                                    Please check the following:
                                    <li>

                                        1. Click the 'EN' button on the ESP32.
                                    </li>
                                    <li>

                                        2. Verify the logic of your code.
                                    </li>
                                    <li>

                                        3. Check your WiFi or LAN connectivity.
                                    </li>
                                    <li>

                                        4. Ensure the correct WiFi name and password are in the code.
                                    </li>
                                </ul>
                            </div>
                        }
                    </div>
                }
            </Card>
        </div>
    )
}

export default ProjectDescription