import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../../../context/UserContext'
import { eduCOBOTColorScheme, SettingContext } from '../../../../../context/SettingsContext'
import { Button, Card, Container, Tooltip } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { routes } from '../../../../../routes/routes'
import { BackendConfigs } from '../../../../../config.environment'
import { APIResponse } from '../../../../../types/Response'
import { toast } from 'react-toastify'
import { LessonBottomBarComponent } from '../Common/BottomBar'
import { LessonDataType } from '../../../../../types/Lesson'
import LessonDescription from './Description'
type LessonTheoryPageProps = {}

export default function LessonTheoryPage({ }: LessonTheoryPageProps) {
    const { user, changeUser } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const navigate = useNavigate()
    const { lessonId } = useParams();
    const [lessonData, seLessonData] = useState<LessonDataType | null>(null)
    async function getProject() {
        console.log(lessonId, user.userData);
        if (!!user.userData && !!lessonId) {
            handleGlobalLoading(true, "Fetching Lesson")
            seLessonData(null)
            try {
                const response = await fetch(`${BackendConfigs.url}/GetLessonByIdAPI`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        userId: user.userData?.id,
                        lessonId: lessonId,
                    })
                })
                if (response.status === 200) {
                    const result = await response.json() as APIResponse
                    toast.success(result.message)
                    seLessonData(result.data)
                } else {
                    navigate(routes.DASHBOARD.MAIN)
                }
            } catch (error) {
                toast.error("Error Fetching Lesson")
                console.log(error);
            }
            handleGlobalLoading(false)
        }
    }

    useEffect(() => {
        getProject()
    }, [lessonId,user])
    useEffect(() => {

    }, [])
    return (
        <>
            <div style={{
                marginBottom: 50,
                overflow: "scroll"
            }}>
                <Container>
                    {!!lessonData && <LessonDescription lesson={lessonData} />}
                </Container>
            </div>
            {
                lessonData &&
                <LessonBottomBarComponent lesson={lessonData} />
            }
        </>

    )
}