

import React from 'react'
import { motion } from "framer-motion"
import { framerAnimation } from '../../theme/animation/MotionConfigs'
import { Card } from '@mui/material'
import { eduCOBOTColorScheme } from '../../context/SettingsContext'
type Props = {}

function GlobalLoaderComponent({ height = "100", width = "100", visible = true, animation = framerAnimation.left2right, message = "Processing..." }) {
    return (
        <motion.div
            initial={framerAnimation.right2left.initial}
            animate={framerAnimation.right2left.animate}
            exit={framerAnimation.right2left.exit}
            transition={framerAnimation.right2left.transition}
        >
            <div className="container">
                <div className="loader">
                    <div className="loaderDot"></div>
                    <div className="loaderDot"></div>
                    <div className="loaderDot"></div>
                    <div className="loaderDot"></div>
                    <div className="loaderDot"></div>
                    <div className="loaderDot"></div>
                </div>
            </div>
        </motion.div>
    )
}
export default GlobalLoaderComponent

