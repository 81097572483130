import { Button, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'
import { UserContext } from '../../../../../context/UserContext'
import { SettingContext } from '../../../../../context/SettingsContext'
import { BackendConfigs } from '../../../../../config.environment'
import { toast } from 'react-toastify'
import { APIResponse } from '../../../../../types/Response'
import { ProjectDataType } from '../../../../../types/Dashboard'

type Props = {
    project: ProjectDataType['project'],
    refreshProject: () => void
}

function DescriptionEditing({
    project,
    refreshProject
}: Props) {
    const { user } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const [projectName, setProjectName] = useState(project.name)
    const [projectDescription, setProjectDescription] = useState(project.description)

    async function updateProject() {
        handleGlobalLoading(true, "Updating Project : " + projectName)
        if (!projectName) {
            toast.error("Project Name is required")
            return
        }
        if (!projectDescription) {
            toast.error("Project Description is required")
            return
        }
        try {
            const response = await fetch(`${BackendConfigs.url}/ChangeProjectDetailsAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    projectName: projectName,
                    projectDescription: projectDescription,
                    ownerId: user.userData?.id,
                    projectId: project._id
                })
            })
            if (response.status === 200) {
                const result = await response.json() as APIResponse
                if (result.success) {
                    toast.success(result.message)
                    refreshProject()
                } else {
                    toast.error(result.message)
                }
            }else {
                toast.error("Details can't be updated1")
            }
        } catch (error) {
            console.log(error);
            toast.error("Details can't be updated2")
        }
        handleGlobalLoading(false)
    }

    return (
        <div>
            <div id='DescriptionEditingSection' style={{
                padding: 10,
                width: "95%",
                margin: "20px auto",
            }}>
                <TextField
                    fullWidth
                    label='Enter Project Name'
                    style={{
                        margin: "10px 0",
                        fontSize: "15px",
                        background: "#0000001A",
                    }}
                    value={projectName}
                    onChange={(e) => {
                        if (e.target.value.length > 50) {
                            alert("Length should not be grater than 50 characters")
                        } else {
                            setProjectName(e.target.value)
                        }
                    }}
                    id='ProjectName'
                />
                <TextField
                    fullWidth
                    label='Enter Project Description'
                    style={{
                        margin: "10px 0",
                        fontSize: "15px",
                        background: "#0000001A",
                    }}
                    value={projectDescription}
                    onChange={(e) => {
                        setProjectDescription(e.target.value)
                    }}
                    multiline
                    rows={4}
                    id="ProjectDescription"
                />
                <div style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "end"
                }}>
                    <Button id='SaveProjectDetails' variant="contained" color="success" sx={{
                        textTransform: "none",
                        borderRadius: "6px",
                        fontWeight: "bolder",
                        // fontSize: "10px",
                        padding: "5px 50px"
                    }} size='small' onClick={() => {
                        updateProject()
                    }}>
                        Update Details
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default DescriptionEditing


