// Robi Chatbot

import { Button, Card, Switch, TextField, Typography } from '@mui/material';
import * as React from 'react';
import googleGeminiGenerativeAPIService from '../../services/gemini-ai';
import { toast } from 'react-toastify'
import SendIcon from '@mui/icons-material/Send';
import ChatComponent from './ChatComponent';
import { ThreeDots } from 'react-loader-spinner';

export type Props = {
}

export function ChatBotContainer(props: Props) {
    const chatScrollRef = React.useRef<HTMLDivElement>();
    const [isActive, setIsActive] = React.useState(false);
    const [textInput, setTextInput] = React.useState("");
    const [isThinking, setIsThinking] = React.useState(false)
    const [isMicroPythonRestricted, setIsMicroPythonRestricted] = React.useState(false)
    const [interactions, setInteractions] = React.useState<{
        text: string,
        fromUser: boolean,
    }[]>([


    ])
    function scrollToEnd() {
        if (chatScrollRef && chatScrollRef.current) {

            const scroll_height = chatScrollRef.current.scrollHeight
            chatScrollRef.current.scrollTo(0, scroll_height)
        }
    }
    async function getResponse() {
        setIsThinking(true);
        if (textInput.length < 5) {
            toast.warning("Question is too short")
        } else {
            setTextInput("")
            setInteractions(prev => [...prev, { text: textInput, fromUser: true }])
            // scrollToEnd()
            const response = await googleGeminiGenerativeAPIService.getPromptResponse(isMicroPythonRestricted ? textInput + " in micropython in simple way" : textInput + " in simple way")
            console.log(response);
            setInteractions(prev => [...prev, { text: response, fromUser: false }])
            // scrollToEnd()
        }
        setIsThinking(false);
    }
    React.useEffect(() => {
        scrollToEnd()
    }, [interactions])
    return (
        <div style={

            isActive ? {
                position: "fixed",
                top: 0,
                left: 0,
                height: "100vh",
                width: "100vw",
                // overflow:"scroll",
                zIndex: 9999999
            } : {
                position: "fixed",
                bottom: 5,
                right: 5,
                width: "70px",
                zIndex: 99999999
            }
        }>
            <div style={{
                display: "grid",
                gridTemplateColumns: "6fr 3fr",
                height: isActive ? "100vh" : "auto",
                background: isActive ? "#47C5DD" : "",
            }}>
                {
                    isActive &&
                    <div style={{
                        height: "100vh",
                        background: "linear-gradient(to right, #a9d1e2, #a9d1e2)",
                        display: "flex",
                        // justifyContent: "center",
                        flexDirection: "column",
                        // alignItems: "center",
                        cursor: "pointer"
                    }}>



                        <div ref={(ref) => {
                            chatScrollRef.current = ref as HTMLDivElement;
                        }} style={{
                            width: "100%",
                            height: "calc(100vh - 90px)",
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            cursor: "pointer",
                            flexDirection: "column",
                            overflowY: "scroll",
                            scrollBehavior: "smooth",
                        }}>
                            {
                                interactions.map((interaction, index) => {
                                    return <ChatComponent text={interaction.text} key={index} fromUser={interaction.fromUser} />;
                                })
                            }


                            {
                                isThinking && <>
                                    <div style={{
                                        width: "100%",
                                        padding: "20px 50px",
                                    }}>

                                        <ThreeDots
                                            visible={true}
                                            height="80"
                                            width="80"
                                            color="#2065D1"
                                            radius="9"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                        />
                                    </div>
                                </>
                            }





                        </div>
                        <div style={{
                            width: "100%",
                            height: "80px",
                            // minHeight: "80px",
                            display: "flex",

                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                            padding: 10
                        }}>
                            <div
                                style={{
                                    width: "95%",
                                    height: "100%",
                                    display: "flex",
                                    border: "1px solid #00000055",
                                    borderRadius: 10,
                                    padding: 5,
                                    background: "#7996a2"
                                }}
                            >

                                <input disabled={isThinking} type='text' value={textInput} onChange={(event) => {
                                    setTextInput(event.target.value)
                                }} style={{
                                    width: "100%",
                                    border: "none",
                                    outline: "none",
                                    color: "white",

                                    fontSize: 18,
                                    padding: 5,
                                    marginLeft: 10,
                                    cursor: "pointer",

                                }}
                                    placeholder="What you want to know?"
                                />
                                <Button size='small' onClick={getResponse} sx={{
                                    // height: "50px",
                                }} variant="text" color="primary">
                                    <SendIcon />
                                </Button>
                            </div>
                        </div>


























                    </div>
                }

                <Typography sx={{
                    transition: "all 0.1s ease-out",
                    transformOrigin: "right bottom",
                    display: "flex",
                    flexDirection: "column",
                    background: "#47C5DD",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: isActive?2:"1px",
                    maxWidth: isActive ? "100%" : "50px",
                    maxHeight: isActive ? "100%" : "50px",
                    borderRadius: !isActive ? "10px" : "0",
                }}>
                    {
                        isActive &&
                        <Typography style={{
                            textTransform: "none",
                            display: "flex",
                            flexDirection: "column",
                            position:"relative"
                        }} variant='h2'>
                            <span style={{
                                fontSize: "0.5em",
                                position: "relative",
                                left:"40px",
                                top:"20px"
                            }}>

                                I am
                            </span>

                            <span>

                                {/* <span style={{ color: "#1899cf" }}>

                                    Sponge
                                </span>
                                <span style={{ color: "#e73f3d", fontWeight: "bolder" }}>
                                    BOT
                                </span> */}
                                <img src="https://storage.googleapis.com/educobot-statics/SpongeBOT.svg" style={{
                                    width:"300px"
                                }} alt="" />
                            </span>
                        </Typography>
                    }
                    <img onClick={() => {
                        setIsActive(!isActive);
                    }} src="https://storage.googleapis.com/educobot-statics/SpongeBOT_Blue.svg" style={{
                        maxWidth: isActive ? "90%" : "100px",
                        maxHeight: isActive ? "90%" : "100px",
                        cursor: "pointer",
                        transition: "all 2s ease-out",
                        transformOrigin: "right bottom",
                        borderRadius: "10px",
                    }} alt="" />
                    {
                        isActive && <>
                            <h3 style={{
                                textTransform: "none",
                                margin: "30px 10px",
                                textAlign: "center",
                            }}>If the component isn't responding, press Enable on the ESP32 or restart the Innovator driver.</h3>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "10px"
                            }}>
                                <Button onClick={() => {
                                    setIsMicroPythonRestricted(true);
                                }}>
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/MicroPython_new_logo.svg/768px-MicroPython_new_logo.svg.png" style={{
                                        maxWidth: "25px"
                                    }} alt="" />
                                </Button>
                                <div onDragStartCapture={() => {
                                    setIsMicroPythonRestricted(!isMicroPythonRestricted);
                                }} onDrag={() => {
                                    setIsMicroPythonRestricted(!isMicroPythonRestricted);
                                }} onDragStart={() => {
                                    setIsMicroPythonRestricted(!isMicroPythonRestricted);
                                }} onClick={() => {
                                    setIsMicroPythonRestricted(!isMicroPythonRestricted);
                                }} style={{
                                    width: "100px",
                                    height: "35px",
                                    border: "1px solid #00000055",
                                    backgroundColor: "#A9D1E2",
                                    borderRadius: "40px",
                                    position: "relative",
                                    cursor: "pointer",
                                }}>
                                    <div style={{
                                        position: "absolute",
                                        left: !isMicroPythonRestricted ? "67.5px" : "1.5px",
                                        top: "2px",
                                        width: "30px",
                                        height: "30px",
                                        border: "1px solid #00000055",
                                        backgroundColor: "#202C62",
                                        borderRadius: "40px",
                                        transition: "all 0.2s linear",
                                    }}></div>
                                </div>
                                <Button onClick={() => {
                                    setIsMicroPythonRestricted(false);
                                }}>
                                    <img src="/images/arduino.png" style={{
                                        maxWidth: "50px"
                                    }} alt="" />
                                </Button>
                            </div>


                        </>
                    }
                </Typography>
            </div>
        </div>
    );

}
