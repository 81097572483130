import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../../../../context/UserContext';
import { SettingContext } from '../../../../../../context/SettingsContext';
import { useNavigate, useParams } from 'react-router-dom';
import { ProjectDataType, Variable } from '../../../../../../types/Dashboard';
import { BackendConfigs } from '../../../../../../config.environment';
import { toast } from 'react-toastify';
import { APIResponse } from '../../../../../../types/Response';
import CodeGenerator from './CodeGenerator';
import { Button, Card, Container, Grid, TextField, Tooltip, Typography } from '@mui/material';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import DeleteIcon from '@mui/icons-material/Delete';
import { routes } from '../../../../../../routes/routes';
import { AddBlocksToWorkspaceByCircuit, getSensorsList } from './CircuitToBlockMapper';
import MonitorIcon from '@mui/icons-material/Monitor';
import { AlertDialogBox, ContentDialogBox } from '../../../../../../components/dialog-box';
import { ProjectBottomBarComponent } from '../../Common/BottomBar';
type Props = {}


function getLastUsedScreen() {
    let isCircuitDesignerOpen = localStorage.getItem("isCircuitDesignerOpen")
    if (isCircuitDesignerOpen) {
        if (isCircuitDesignerOpen === "true") {
            return true
        } else {
            return false
        }
    }
    return false
}


function MicroPythonWorkspacePage({ }: Props) {
    const { user, changeUser } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const navigate = useNavigate()
    const { projectId } = useParams();
    const [projectData, setProjectData] = useState<ProjectDataType | null>(null)
    const [xml, setXml] = useState<string>("")
    const [code, setCode] = useState<string>("")
    const [newBlocks, setNewBlocks] = useState<string[]>([])
    const [circuitJSON, setCircuitJSON] = useState<string>("")
    const [isCircuitDesignerOpen, setIsCircuitDesignerOpen] = useState<boolean>(getLastUsedScreen())
    const [isSavingTab, setIsSavingTab] = useState<boolean>(false)
    const [variables_list, setVariables_list] = useState<Variable[]>([])
    const [refreshData, setRefreshData] = useState<boolean>(false)
    const [refreshWorkspace, setRefreshWorkspace] = useState<boolean>(false)
    const [keys, setKeys] = useState<{
        [key: string]: string
    }>({

    })
    useEffect(() => {
        localStorage.setItem("isCircuitDesignerOpen", String(isCircuitDesignerOpen))
    }, [isCircuitDesignerOpen])
    const [isAPIKeyDialogOpen, setIsAPIKeyDialogOpen] = useState<boolean>(false)
    async function getProject() {
        console.log(projectId, user.userData);
        if (!!user.userData && !!projectId) {
            handleGlobalLoading(true, "Fetching Project")
            // setProjectData(null)
            try {
                const response = await fetch(`${BackendConfigs.url}/GetProjectByProjectIdAndOwnerIdAPI`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        ownerId: user.userData?.id,
                        projectId: projectId,
                    })
                })
                if (response.status === 200) {
                    const result = await response.json() as APIResponse
                    // toast.success(result.message)
                    if (result.success && result.data.project) {

                        console.log(result.data);
                        setProjectData(result.data)
                        setCode((result.data as ProjectDataType).micropython.microPythonCode)
                        setXml((result.data as ProjectDataType).micropython.microPythonXML)
                        setCircuitJSON((result.data as ProjectDataType).circuit.circuitJSON)
                        setVariables_list((result.data as ProjectDataType).project.variables)
                        setKeys((preKeys) => {
                            return {
                                ...preKeys,
                                secretKey: (result.data as ProjectDataType).project._id
                            }
                        })
                    } else {
                        navigate(routes.DASHBOARD.MAIN)
                    }
                } else {
                    navigate(routes.DASHBOARD.MAIN)
                }
            } catch (error) {
                console.log(error);
                toast.error("Error Fetching Project")
            }
            handleGlobalLoading(false)
        }
    }
    async function getAPIKey() {
        if (!user.userData?.id) return
        try {
            const response = await fetch(`${BackendConfigs.url}/GetAPIKeyAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    ownerId: user.userData?.id
                })
            })
            if (response.status === 200) {
                const result = await response.json() as APIResponse
                console.log(result);

                if (result.success) {
                    setKeys((preKeys) => {
                        return {
                            ...preKeys,
                            apiKey: result.data.apiKey,
                        }
                    })
                    return
                } else {
                    toast.error(result.message)
                    setIsAPIKeyDialogOpen(true)
                }
            }
            else {
                setIsAPIKeyDialogOpen(true)
            }
        } catch (error) {
            console.log(error);

        }
    }
    useEffect(() => {
        getProject()
    }, [user, projectId])
    useEffect(() => {
        getAPIKey()
    }, [user])

    async function saveCircuit(circuitJSON: string) {
        handleGlobalLoading(true, "Saving Circuit")
        try {
            const response = await fetch(`${BackendConfigs.url}/ChangeCircuitJsonAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    projectId: projectData?.project._id,
                    circuitJSON,
                    sensorsList: getSensorsList(circuitJSON)
                })
            })
            const result = await response.json() as APIResponse
            if (result.success) {
                toast.success(result.message)
            } else {
                toast.error(result.message)
            }
        } catch (error) {
            console.log(error);
            toast.error("Error saving circuit")
        }
        handleGlobalLoading(false)
    }

    async function saveCode() {
        handleGlobalLoading(true, "Saving Code")
        try {
            const response = await fetch(`${BackendConfigs.url}/ChangeMicroPythonCodeAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    projectId: projectData?.project._id,
                    ownerId: user.userData?.id,
                    code
                })
            })
            const result = await response.json() as APIResponse
            if (result.success) {
                toast.success(result.message)
            } else {
                toast.error(result.message)
            }
        } catch (error) {
            console.log(error);
            toast.error("Error saving code")
        }
        handleGlobalLoading(false)
    }

    async function saveMicropythonCode() {
        handleGlobalLoading(true, "Saving Code")
        try {
            const response = await fetch(`${BackendConfigs.url}/UpdateMicropythonCodebaseAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    projectId: projectData?.project._id,
                    ownerId: user.userData?.id,
                    micropythonXML:xml,
                    micropythonCode:code,
                    externalControllerBlocks:newBlocks
                })
            })
            const result = await response.json() as APIResponse
            if (result.success) {
                toast.success(result.message)
            } else {
                toast.error(result.message)
            }
        } catch (error) {
            console.log(error);
            toast.error("Error saving Blocks")
        }
        handleGlobalLoading(false)
    }


    async function saveVariables() {
        handleGlobalLoading(true, "Saving Variables")
        try {
            const response = await fetch(`${BackendConfigs.url}/ChangeVariablesAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    projectId: projectData?.project._id,
                    ownerId: user.userData?.id,
                    variables: variables_list.filter((variable) => variable.name !== "")
                })
            })
            const result = await response.json() as APIResponse
            if (result.success) {
                toast.success(result.message)
                getProject()
            } else {
                toast.error(result.message)
            }
        } catch (error) {
            console.log(error);
            toast.error("Error saving variables")
        }
        handleGlobalLoading(false)
    }
    async function saveAll() {
        handleGlobalLoading(true, "Saving All Stuffs")
        await saveCircuit(circuitJSON)
        await saveCode()
        await saveVariables()
        handleGlobalLoading(false)
    }
    // useEffect(() => {
    //     console.log(circuitJSON);
    // }, [circuitJSON])

    return (
        <>
            <div>

                {
                    projectData ? <>
                        <div key={refreshData ? "1" : "2"}>
                            {
                                <CodeGenerator key={refreshWorkspace ? "1" : "2"} project={projectData} code={code} setCode={setCode} xml={xml} setXml={setXml} setIsCircuitDesignerOpen={setIsCircuitDesignerOpen} keys={keys} setNewBlocks={(newBlocks)=>{
                                    setNewBlocks(newBlocks)
                                }} saveMicropythonCode={saveMicropythonCode}/>
                            }
                        </div>

                    </> : <></>
                }

                <div style={{ display: "flex", justifyContent: "end", flexDirection: "column", alignItems: 'center', height: "100vh", position: "fixed", top: "0", right: 0 }}>
                    <Typography style={{
                        backgroundColor: "white",
                        border: "0.5px solid #00000014",
                        display: "flex",
                        flexDirection: "column",
                    }}>
                        
                        
                    </Typography>
                </div>
                <ContentDialogBox
                    isOpen={isSavingTab}
                    onClose={() => setIsSavingTab(false)}
                    title='Save Changes'
                    content={<>

                    </>}
                    actions={<>
                        
                        <Button
                            variant='contained'
                            size='small'
                            onClick={() => {
                                saveVariables()
                                setIsSavingTab(false)

                            }}
                            color="primary"
                            sx={{
                                textTransform: "none",
                                borderRadius: "6px",
                                fontWeight: "bolder",
                                fontSize: "10px"
                            }}
                        >
                            Save Variables
                        </Button>
                        <Button
                            variant='contained'
                            size='small'
                            onClick={() => {
                                saveAll()
                                setIsSavingTab(false)
                            }}
                            color="primary"
                            sx={{
                                textTransform: "none",
                                borderRadius: "6px",
                                fontWeight: "bolder",
                                fontSize: "10px"
                            }}
                        >
                            Save All
                        </Button>
                    </>}
                />
                <AlertDialogBox
                    isOpen={isAPIKeyDialogOpen}
                    onClose={() => setIsAPIKeyDialogOpen(false)}
                    title='API Key'
                    message='API key not found, please create'
                    successAction={
                        <Button
                            variant='contained'
                            size='small'
                            onClick={() => {
                                setIsAPIKeyDialogOpen(false)
                                navigate(routes.DASHBOARD.SETTINGS)
                            }}
                            color="primary"
                            sx={{
                                textTransform: "none",
                                borderRadius: "10px",
                                padding: "5px 30px",
                            }}
                        >
                            Create
                        </Button>
                    }

                />
            </div>
            {
                projectData &&
                <ProjectBottomBarComponent projectData={projectData} />
            }
        </>

    )
}

export default MicroPythonWorkspacePage