import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { routes } from './routes'
import HomePage from '../pages/Home/index';
import DashboardMainPage from '../pages/Dashboard/Main';
import ProjectsPage from '../pages/Dashboard/Projects';
import BlueprintsPage from '../pages/Dashboard/Lessons';
import ToolsPage from '../pages/Dashboard/Tools';
import SettingsPage from '../pages/Dashboard/Settings';
import DashboardModelsPage from '../pages/Dashboard/Models';
import MicroPythonEditorPage from '../pages/Tools/MicropythonEditor';
import ProjectDescriptionPage from '../pages/Dashboard/Projects/Project/Description';
import CreateProjectPage from '../pages/Dashboard/Projects/Project/CreateProject';
import ProjectSettingsPage from '../pages/Dashboard/Projects/Project/ProjectSettings';
import CircuitryWorkspacePage from '../pages/Dashboard/Projects/Project/Workspace/CircuitryWorkspace';
import MicroPythonWorkspacePage from '../pages/Dashboard/Projects/Project/Workspace/MicropythonWorkspace';
import MonitoringWorkspacePage from '../pages/Dashboard/Projects/Project/Workspace/MonitoringWorkspace';
import AIEditorPage from '../pages/Dashboard/Projects/Project/Workspace/AIWorkspace';
import CreateModelPage from '../pages/Dashboard/Models/CreateModel';
import ModelWorkSpacePage from '../pages/Dashboard/Models/Workspace';
import LessonsPage from '../pages/Dashboard/Lessons';
import LessonTheoryPage from '../pages/Dashboard/Lessons/Lesson/Theory';
import LessonAssemblyPage from '../pages/Dashboard/Lessons/Lesson/Workspace/Assemby';
import CircuitryLessonWorkspacePage from '../pages/Dashboard/Lessons/Lesson/Workspace/Circuitary';
import MicropythonLessonWorkspacePage from '../pages/Dashboard/Lessons/Lesson/Workspace/Micropython';
import CircuitDesignerPage from '../pages/Tools/CircuitDesigner/CircuitDesigner';

function Router() {
    return (
        <Routes>
            <Route path={routes.ROOT} element={<Navigate to={routes.DASHBOARD.MAIN} replace={true} />} />





            <Route path={routes.DASHBOARD.MAIN} element={<DashboardMainPage />} />


            <Route path={routes.DASHBOARD.PROJECTS} element={<ProjectsPage />} />
            <Route path={routes.DASHBOARD.PROJECT.CREATE} element={<CreateProjectPage />} />
            <Route path={routes.DASHBOARD.PROJECT.DESCRIPTION(':projectId')} element={<ProjectDescriptionPage />} />
            <Route path={routes.DASHBOARD.PROJECT.SETTINGS(':projectId')} element={<ProjectSettingsPage />} />
            <Route path={routes.DASHBOARD.PROJECT.WORKSPACE.CIRCUIT(':projectId')} element={<CircuitryWorkspacePage />} />
            <Route path={routes.DASHBOARD.PROJECT.WORKSPACE.MICROPYTHON(':projectId')} element={<MicroPythonWorkspacePage />} />
            <Route path={routes.DASHBOARD.PROJECT.WORKSPACE.MONITOR(':projectId')} element={<MonitoringWorkspacePage />} />
            <Route path={routes.DASHBOARD.PROJECT.WORKSPACE.AI(':projectId')} element={<AIEditorPage />} />


            <Route path={routes.DASHBOARD.MODEL.CREATE} element={<CreateModelPage />} />
            <Route path={routes.DASHBOARD.MODEL.WORKSPACE(":modelId")} element={<ModelWorkSpacePage />} />
            <Route path={routes.DASHBOARD.MODELS} element={<DashboardModelsPage />} />






            <Route path={routes.DASHBOARD.TOOLS} element={<ToolsPage />} />
            <Route path={routes.DASHBOARD.SETTINGS} element={<SettingsPage />} />



            <Route path={routes.TOOLS.MICROPYTHON_EDITOR} element={<MicroPythonEditorPage />} />
            <Route path={routes.TOOLS.CIRCUIT_DESIGNER} element={<CircuitDesignerPage />} />



            <Route path={routes.DASHBOARD.LESSONS} element={<LessonsPage />} />
            <Route path={routes.DASHBOARD.LESSON.THEORY(":lessonId")} element={<LessonTheoryPage />} />
            <Route path={routes.DASHBOARD.LESSON.WORKSPACE.ASSEMBLY(":lessonId")} element={<LessonAssemblyPage />} />
            <Route path={routes.DASHBOARD.LESSON.WORKSPACE.CIRCUIT(":lessonId")} element={<CircuitryLessonWorkspacePage />} />
            <Route path={routes.DASHBOARD.LESSON.WORKSPACE.MICROPYTHON(":lessonId")} element={<MicropythonLessonWorkspacePage />} />



            {/* <Route path={routes.NOTFOUND} element={<div>Not Found</div>} /> */}
            <Route path={routes.WILDCARD} element={<Navigate to={routes.DASHBOARD.MAIN} replace={true} />} />
        </Routes>
    )
}

export default Router