import React, { useContext, useEffect, useState } from 'react'
import DescriptionEditing from './DescriptionEditing'
import AccessEditing from './AccessEditing'
import { ProjectDataType } from '../../../../../types/Dashboard'
import DeleteProject from './DeleteProject'
import { UserContext, userInitialState } from '../../../../../context/UserContext'
import { SettingContext } from '../../../../../context/SettingsContext'
import { useNavigate, useParams } from 'react-router-dom'
import { BackendConfigs, FrontendConfigs } from '../../../../../config.environment'
import { APIResponse } from '../../../../../types/Response'
import { routes } from '../../../../../routes/routes'
import { toast } from 'react-toastify'
import { ProjectBottomBarComponent } from '../Common/BottomBar'
import { Button, Card, Container, Grid, TextField } from '@mui/material'
// import BluePrintPublisher from './BluePrintPublisher'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VariableEditing from './VariableEditing'
import ModelConnectionComponent from './ModelConnection'
import EditorSelectionEditing from './EditorsSelections'

type Props = {
}

function ProjectSettingsPage({
}: Props) {

    const [projectData, setProjectData] = useState<ProjectDataType | null>(null)

    const { user, changeUser } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const navigate = useNavigate()
    const { projectId } = useParams();

    async function getProject() {
        console.log(projectId, user.userData);
        if (!!user.userData && !!projectId) {
            handleGlobalLoading(true, "Fetching Project")
            // setProjectData(null)
            try {
                const response = await fetch(`${BackendConfigs.url}/GetProjectByProjectIdAndOwnerIdAPI`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        ownerId: user.userData?.id,
                        projectId: projectId,
                    })
                })
                if (response.status === 200) {
                    const result = await response.json() as APIResponse
                    // toast.success(result.message)
                    // console.log(result.data);
                    setProjectData(result.data)
                } else {
                    navigate(routes.DASHBOARD.MAIN)
                }
            } catch (error) {
                toast.error("Error Fetching Project")
                console.log(error);
            }
            handleGlobalLoading(false)
        }
    }

    async function refreshProject() {
        getProject()

    }

    useEffect(() => {
        getProject()
    }, [user, projectId])
    return (
        <>
            <div style={{
                maxHeight: window.innerHeight,
                overflow: "scroll",
                // background: "url(/images/robotics-background.jpeg) no-repeat",
                // backgroundSize: "cover",
            }}>
                <Container>

                    {
                        projectData && <>
                            <AccessEditing project={projectData.project} refreshProject={refreshProject} />
                            <Grid columns={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }} container spacing={2} style={{
                                margin: "0px auto",
                            }}>

                                <Grid item xs={2} sm={2} md={1} lg={1} xl={1} >
                                    <Card style={{
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "10px",
                                        background: "#0000000A",
                                    }}>

                                        <DescriptionEditing project={projectData.project} refreshProject={refreshProject} />
                                    </Card>

                                </Grid>
                                <Grid item xs={2} sm={2} md={1} lg={1} xl={1} >
                                    <Card style={{
                                        width: "100%",
                                        height: "100%",
                                        borderRadius: "10px",
                                        background: "#0000000A",
                                    }}>

                                        <EditorSelectionEditing projectData={projectData} refreshProject={refreshProject} />
                                    </Card>
                                </Grid>

                            </Grid>

                            <Grid columns={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }} container spacing={2} style={{
                                margin: "0px auto",
                            }}>
                                {
                                    projectData.project.editors.includes("iot-monitor") && <>

                                        <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                                            <Card style={{
                                                width: "100%",
                                                height: "100%",
                                                borderRadius: "10px",
                                                background: "#0000000A",
                                            }}>

                                                <VariableEditing projectData={projectData} refreshProject={refreshProject} />
                                            </Card>

                                        </Grid>
                                    </>
                                }
                                {
                                    projectData.project.editors.includes("ai-editor") && <>

                                        <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                                            <Card style={{
                                                width: "100%",
                                                height: "100%",
                                                borderRadius: "10px",
                                                background: "#0000000A",
                                            }}>

                                                <ModelConnectionComponent projectData={projectData} refreshProject={refreshProject} />
                                            </Card>
                                        </Grid>
                                    </>
                                }

                            </Grid>
                            <DeleteProject project={projectData.project} refreshProject={refreshProject} />
                        </>
                    }
                </Container>

            </div>
            {
                projectData &&
                <ProjectBottomBarComponent projectData={projectData} />
            }
        </>
    )
}

export default ProjectSettingsPage