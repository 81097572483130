import { Button, Card, CardActionArea, CardContent } from "@mui/material"
import { LessonDataType } from "../../../../../../types/Lesson"


export default function AssemblyPlayerComponent({
    lessonData
}: {
    lessonData: LessonDataType
}) {





















    return (
        <Card style={{
            minHeight: "80vh",
            width: "100%",
            margin: "10px",
            padding: "10px",
            boxShadow: "0 0 5px #ccc",
            borderRadius: "10px",
            background: "#0000000A",
        }}>
            <CardContent style={{
                minHeight: "70vh",
            }}>
                <img src="https://commercialledlights.com/blog/wp-content/uploads/2021/07/LED-Diode-Diagram.png" style={{
                    width: "100%",
                    height: "100%",
                }} alt="" />
            </CardContent>
            <CardActionArea style={{
                height: "80px",
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
            }}>
                <Button variant="contained" color="warning" sx={{ textTransform: "none", borderRadius: "6px", fontWeight: "bolder", margin:1 }}>
                    Previous
                </Button>
                <Button color="success" variant="contained" sx={{ textTransform: "none", borderRadius: "6px", fontWeight: "bolder", margin:1 }}>
                    Next
                </Button>

            
            </CardActionArea>
        </Card>
    )
}