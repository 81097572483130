import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../../../../context/UserContext'
import { eduCOBOTColorScheme, SettingContext } from '../../../../../../context/SettingsContext'
import { Button, Card, Container, Tooltip } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { routes } from '../../../../../../routes/routes'
import { BackendConfigs } from '../../../../../../config.environment'
import { APIResponse } from '../../../../../../types/Response'
import { toast } from 'react-toastify'
import { LessonBottomBarComponent } from '../../Common/BottomBar'
import { LessonDataType } from '../../../../../../types/Lesson'
import AssemblyPlayerComponent from './AssemblyPlayer'
import CircuitPlayerComponent from '../Circuitary/CircuitPlayer'
type LessonAssemblyPageProps = {}

export default function LessonAssemblyPage({ }: LessonAssemblyPageProps) {
    const { user, changeUser } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const navigate = useNavigate()
    const { lessonId } = useParams();
    const [lessonData, seLessonData] = useState<LessonDataType | null>(null)
    async function getProject() {
        console.log(lessonId, user.userData);
        if (!!user.userData && !!lessonId) {
            handleGlobalLoading(true, "Fetching Lesson")
            seLessonData(null)
            try {
                const response = await fetch(`${BackendConfigs.url}/GetLessonByIdAPI`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        userId: user.userData?.id,
                        lessonId: lessonId,
                    })
                })
                if (response.status === 200) {
                    const result = await response.json() as APIResponse
                    toast.success(result.message)
                    seLessonData(result.data)
                } else {
                    navigate(routes.DASHBOARD.MAIN)
                }
            } catch (error) {
                toast.error("Error Fetching Lesson")
                console.log(error);
            }
            handleGlobalLoading(false)
        }
    }

    useEffect(() => {
        getProject()
    }, [lessonId, user])
    useEffect(() => {

    }, [])
    if (!lessonData) {
        return <></>
    }
    return (
        <>
            <div style={{
                marginBottom: 50,
                overflow: "scroll",
                height: window.innerHeight-50,
            }}>
                <Container style={{
                    height:"100%",
                }}>
                    <Card style={{
                        height: "70px",
                        width: "100%",
                        margin: "10px",
                        padding: "10px",
                        boxShadow: "0 0 5px #ccc",
                        borderRadius: "10px",
                        background: "#0000000A",
                    }}>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            flexDirection: settings.screen === "mobile" ? "column" : "row",
                        }}>
                            <img src={lessonData.thumbnail} alt="" style={{
                                maxHeight: settings.screen === "mobile" ? "200px" : "50px",
                            }} />
                            <div style={{
                                margin: "0 10px",
                                width: "100%"
                            }}>
                                <h4 id='project-title' style={{
                                    // margin: "10px 0",
                                    // textAlign: "center",
                                }}>{lessonData.name}</h4>
                                <h5 id='project-description' style={{
                                    color: eduCOBOTColorScheme.gray,
                                    fontSize: "12px",
                                }}>{lessonData.description}</h5>

                            </div>
                        </div>

                    </Card>
                    <AssemblyPlayerComponent lessonData={lessonData} />
                </Container>
            </div>
            {
                lessonData &&
                <LessonBottomBarComponent lesson={lessonData} />
            }
        </>

    )
}


