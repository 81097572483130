import { Dispatch, createContext, useEffect, useReducer } from "react"
import { getCookieUserData, removeCookieUserData, setCookieUserData } from "../services/CookieStorage/CookieAuthentication";
import { getLocalUserData, removeLocalUserData, setLocalUserData } from "../services/LocalStorage/LocalAuthentication";
import { BackendConfigs, MainConfigs } from "../config.environment";

export type userStateType = {
    isLogin: boolean,
    userData: {
        id: string | null,
        name: string | null,
        contact: {
            type: "email" | "phone",
            value: string
        } | null,
        avatar: string | null,
        role: string | null,
        token: string | null
    } | null
}

type userActionType = {
    type: "login" | "logout" | "update",
    value: {
        userData: userStateType["userData"] | null,
    }
}

export const userInitialState: userStateType = {
    isLogin: false,
    userData: null
}

function userReducer(state: userStateType, action: userActionType): userStateType {
    const user = {
        id: action.value.userData?.id as string,
        name: action.value.userData?.name as string,
        contact: action.value.userData?.contact as { type: "email" | "phone", value: string },
        role: action.value.userData?.role as string,
        token: action.value.userData?.token as string,
        avatar: action.value.userData?.avatar as string
    }
    console.log(user);
    switch (action.type) {
        case "login":
            setLocalUserData(user)
            setCookieUserData(user)
            return { ...state, isLogin: true, userData: action.value.userData || null }
        case "logout":
            removeCookieUserData()
            removeLocalUserData()
            return { ...state, isLogin: false, userData: action.value.userData || null }
        case "update":
            setLocalUserData(user)
            setCookieUserData(user)
            return { ...state, isLogin: true, userData: action.value.userData || null }
        default:
            return state
    }
}

export const UserContext = createContext({
    user: userInitialState,
    changeUser: ((() => { }) as Dispatch<userActionType>)
})


export async function getUserDataAfterVerification(userID: string) {
    try {
        const response = await fetch(`${BackendConfigs.url}/GetUserDataAPI`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userId: userID })
        })
        if (response.status === 200) {
            const data = await response.json()
            console.log(data);
            return data.response
        }
        return false
    } catch (error) {
        console.log(error);
        return false
    }
}




export default function UserContextProvider(props: {
    children: React.ReactNode
}) {
    const [user, changeUser] = useReducer(userReducer, userInitialState)

    async function checkUser() {
        const cookie = await getCookieUserData()
        console.log(cookie);

        const local = getLocalUserData()
        if (!!cookie) {
            changeUser({ type: "login", value: { userData: cookie, } })
        }
        else if (!!local) {
            changeUser({ type: "login", value: { userData: local } })
        }
        else {
            changeUser({ type: "logout", value: { userData: null } })
            window.location.href = 'https://educobot.com/auth/login'
        }
    }
    useEffect(() => {
        console.log(window.location.href);
        if (MainConfigs.NODE_ENVIRONMENT === "development" || window.location.href.includes("monitoring-workspace-application")) {
            changeUser({
                type: "login", value: {
                    userData: {
                        id: "001329.d54007cf2bbc44f5ac86df4e9c28f027.0951",
                        name: "Shivkumar Chauhan",
                        contact: {
                            type: "email",
                            value: "shivkumar@educobot.com"
                        },
                        avatar: null,
                        role: "Teacher",
                        token: null
                    },
                }
            })
        } else {

            checkUser()
        }
    }, [])
    return (
        <UserContext.Provider value={{ user, changeUser }}>
            {props.children}
        </UserContext.Provider>
    )
}
