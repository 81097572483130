import { useEffect, useState } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter'
import js from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import python from 'react-syntax-highlighter/dist/esm/languages/prism/python'
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { toast } from "react-toastify";

type Props = {
    children: string,
    language: string,
}
SyntaxHighlighter.registerLanguage('python', python);

const HighlightedCode = ({ children, language }: Props) => {
    useEffect(() => {
        if (language === "python") {

        } else {

            // SyntaxHighlighter.registerLanguage('javascript', js);
        }
    })

    return (
        <div className="code">
            <CopyToClipboard text={children} onCopy={()=>{
                toast.info(`Code copied to clipboard`)
            }}>
                <SyntaxHighlighter language={language} style={materialDark}>
                    {children}
                </SyntaxHighlighter>
            </CopyToClipboard>
        </div>
    )
}

export default HighlightedCode
