import { routes } from "../routes/routes";

export const PageWiseGuides = {
    PROJECT: {
        OVERVIEW: {
            route: routes.DASHBOARD.PROJECT.DESCRIPTION,
            title: "Project Overview",
            guides: [
                // {
                //     elementID: "",
                //     title: 'Welcome to Innovator!',
                //     description: 'A space where you can train models using Teachable Machine and explore how AI can be used to achieve various processes.',
                //     side: "left",
                //     align: 'start',
                // },
                {
                    elementID: "#project-title",
                    title: '',
                    description: 'Project name.',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#project-creation-time",
                    title: '',
                    description: 'Time since the creation of the project',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#project-description",
                    title: '',
                    description: `Project description`,
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#components-list",
                    title: '',
                    description: 'View all hardware components used in your project here',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#device-active-status",
                    title: '',
                    description: 'View the current status of your hardware: Online, Offline, Active, or Inactive',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#ProjectNavigation",
                    title: '',
                    description: 'Navigate from here between different section in this project',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#Home",
                    title: '',
                    description: 'Go to Project Overview.',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#CircuitDesigner",
                    title: '',
                    description: 'Go to Circuit Designer.',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#MicroPythonCodeEditor",
                    title: '',
                    description: 'Code your project logic using the blocks editor or switch to syntax.',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#IOTMonitor",
                    title: '',
                    description: 'Add monitoring options for your project using available widgets based on your needs',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#AIEditor",
                    title: '',
                    description: 'Code your project logic using trained AI models',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#Settings",
                    title: '',
                    description: 'Modify project name, description, share access (private or public), or delete your project.',
                    side: "left",
                    align: 'start',
                },
            ]
        },
        PROJECT_SETTINGS: {
            route: routes.DASHBOARD.PROJECT.SETTINGS,
            title: "Project Settings",
            guides: [
                {
                    elementID: "#DescriptionEditingSection",
                    title: '',
                    description: 'Here you can edit your project Details',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#ProjectName",
                    title: '',
                    description: 'Project Name',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#ProjectDescription",
                    title: '',
                    description: 'Project Description',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#SaveProjectDetails",
                    title: '',
                    description: 'You can save modified project details using this button',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#AccessEditingSection",
                    title: '',
                    description: 'Toggle to switch project sharing between public and private.',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#ShareCopySection",
                    title: '',
                    description: 'Click to copy the project link and share it with others.',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#DeleteSection",
                    title: '',
                    description: 'You can delete the project using Delete Project Button.',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#EditorSelections",
                    title: '',
                    description: 'Modify the required environment selections',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#EditorSelectionSavingButton",
                    title: '',
                    description: 'Using this button you can save modified editor selections',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#VariableEditingSection",
                    title: '',
                    description: 'Here You can change list of variables for IOT Project',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#AddVariableButton",
                    title: '',
                    description: 'Add a variable to the project',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#SaveUpdatedVariableList",
                    title: '',
                    description: 'Using this button you can save updated variables',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#ModelConnectionSection",
                    title: '',
                    description: 'For AI you can connect model to workspace',
                    side: "left",
                    align: 'start',
                },
            ],
        },
        CREATE_PROJECT: {
            route: routes.DASHBOARD.PROJECT.SETTINGS,
            title: "Project Settings",
            guides:[
                {
                    elementID: "#ProjectCreateContainer",
                    title: '',
                    description: 'Fill Following Details to Create Project',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#ProjectName",
                    title: '',
                    description: 'Enter your project name here.',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#ProjectDescription",
                    title: '',
                    description: `Briefly explain what your project is about, what it aims to achieve, and any key components or technologies you'll be using (e.g., robotics, IoT, AI).`,
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#SelectedEditors",
                    title: '',
                    description: 'Select Your Development Environment (minimum 1 selection required)',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#CreateProjectButton",
                    title: '',
                    description: 'Click here to create your project with the selected development environment',
                    side: "left",
                    align: 'start',
                },
            ]
        },
        CREATE_MODEL: {
            route: routes.DASHBOARD.PROJECT.SETTINGS,
            title: "Project Settings",
            guides:[
                {
                    elementID: "#ModelCreateContainer",
                    title: '',
                    description: 'Fill Following Details to Create Model',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#ModelName",
                    title: '',
                    description: 'Enter your Model name here.',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#ModelDescription",
                    title: '',
                    description: `Briefly explain what your model is about.`,
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#ModelURL",
                    title: '',
                    description: 'Enter Teachable machine url here, that you have copied from Google teachable export options',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#CreateModelButton",
                    title: '',
                    description: 'Click to create a new project',
                    side: "left",
                    align: 'start',
                },
            ]
        },
        CIRCUIT_DESIGNER: {
            route: routes.DASHBOARD.PROJECT.SETTINGS,
            title: "Project Settings",
            guides:[
                {
                    elementID: "#CircuitToolBar",
                    title: '',
                    description: 'Here You will find all required tool to build circuit like lines, shapes, colors and etc.',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#palette-space",
                    title: '',
                    description: 'From Here you can select hardware components to build circuit',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#diagram-space",
                    title: '',
                    description: 'This is main workspace for circuit design',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#CircuitSavingButton",
                    title: '',
                    description: 'Using this button you can save circuit',
                    side: "left",
                    align: 'start',
                },
            ]
        },
        MICROPYTHON_EDITOR: {
            route: routes.DASHBOARD.PROJECT.SETTINGS,
            title: "Project Settings",
            guides:[
                {
                    elementID: "#MicroPythonEditor",
                    title: '',
                    description: 'This is Block based micropython code editor',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#CodeEditor",
                    title: '',
                    description: 'This is syntax based micropython code editor',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#RunMicroPythonCodeButton",
                    title: '',
                    description: 'Click here to deploy code in microcontroller',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#CodeOrBlockToggler",
                    title: '',
                    description: 'To toggle between MicroPython Block based and syntax based editor',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#saveCodeButton",
                    title: '',
                    description: 'Using this Button you can save your code',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#CableOrWifiToggleButton",
                    title: '',
                    description: 'Use this Button to toggle between deploy using Wifi or cable',
                    side: "left",
                    align: 'start',
                },
            ]
        },






        IOT_MONITOR: {
            route: routes.DASHBOARD.PROJECT.SETTINGS,
            title: "Project Settings",
            guides:[
                {
                    elementID: "#WidgetsContainer",
                    title: '',
                    description: 'Select Different Widgets to design your IOT-Monitor',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#Switch",
                    title: '',
                    description: 'Toggle between two states (On/Off) with this switch',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#Gauge",
                    title: '',
                    description: 'Display values on a gauge for visual tracking',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#Label",
                    title: '',
                    description: 'Use this label to display dynamic text on the screen.',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#Text-Input",
                    title: '',
                    description: 'Allow users to enter text or data with this input field',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#Comment",
                    title: '',
                    description: 'Add comments or notes for context on your monitor',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#Line-Chart",
                    title: '',
                    description: 'Visualize data trends over time with a line chart',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#Bar-Chart",
                    title: '',
                    description: 'Visualize data trends over time with bar chart',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#Image",
                    title: '',
                    description: 'Embed and display images in your monitor dashboard',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#monitor-designer-grid-stack",
                    title: '',
                    description: 'This is main workspace where you can design your IOT-Monitor',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#MonitorPreview",
                    title: '',
                    description: 'To preview your IOT-Monitor in public mode use this method',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#SaveMonitorButton",
                    title: '',
                    description: 'Save your IOT-Monitor using this method',
                    side: "left",
                    align: 'start',
                },
            ]

        },

        AI_EDITOR: {
            route: routes.DASHBOARD.PROJECT.SETTINGS,
            title: "Project Settings",
            guides:[
                {
                    elementID: "#JavascriptEditor",
                    title: '',
                    description: 'This is Block based editor to implement logic for AI output',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#CodeEditor",
                    title: '',
                    description: 'This is a syntax representation of your logic',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#AIRunBUtton",
                    title: '',
                    description: 'To run your logic use this button',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#ModelRunButton",
                    title: '',
                    description: 'Using this button you can start the model',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#CameraPreview",
                    title: '',
                    description: 'To see camera preview, use this button',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#SaveAIWorkspaceButton",
                    title: '',
                    description: 'To save your workspace, use this button',
                    side: "left",
                    align: 'start',
                },
            ]
        },
        DASHBOARD_MAIN: {
            route: routes.DASHBOARD.PROJECT.SETTINGS,
            title: "Project Settings",
            guides:[
                {
                    elementID: "#Dashboard",
                    title: '',
                    description: 'View your projects and trained models here!',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#Projects",
                    title: '',
                    description: 'Access your own created projects here!',
                    side: "left",
                    align: 'start',
                },
                // {
                //     elementID: "#Blueprints",
                //     title: '',
                //     description: '',
                //     side: "left",
                //     align: 'start',
                // },
                {
                    elementID: "#Models",
                    title: '',
                    description: 'Access your trained models or sample models here!',
                    side: "left",
                    align: 'start',
                },
                // {
                //     elementID: "#Tools",
                //     title: '',
                //     description: '',
                //     side: "left",
                //     align: 'start',
                // },
                // {
                //     elementID: "#Settings",
                //     title: '',
                //     description: '',
                //     side: "left",
                //     align: 'start',
                // },
                {
                    elementID: "#ModelCreateButton",
                    title: '',
                    description: 'Click here to create new Model!',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#ProjectCreateButton",
                    title: '',
                    description: 'Click here to start new project!',
                    side: "left",
                    align: 'start',
                },
                {
                    elementID: "#SoftWareDownloadButton",
                    title: '',
                    description: 'Download drivers for Windows or macOS to connect and flash your microcontroller.',
                    side: "left",
                    align: 'start',
                }
            ]
        }

    }
}

















/*

[11:14, 19/11/2024] Tarun R Agarwal Sir Educobot: Welcome to Innovator! Manage your robotics, IoT, and AI projects here, or start a new one!
[11:15, 19/11/2024] Tarun R Agarwal Sir Educobot: Create Project - Click here to start new project!
[11:17, 19/11/2024] Tarun R Agarwal Sir Educobot: Download Button - Download drivers for Windows or macOS to connect and flash your microcontroller.
[11:19, 19/11/2024] Tarun R Agarwal Sir Educobot: after clicking Create Project  - Enter your project name here.
[11:20, 19/11/2024] Tarun R Agarwal Sir Educobot: project description - Briefly explain what your project is about, what it aims to achieve, and any key components or technologies you'll be using (e.g., robotics, IoT, AI).
[11:23, 19/11/2024] Tarun R Agarwal Sir Educobot: Select Your Development Environment

"Select All"
"Robotics"
"IoT"
"AI"
"IoT Monitor"
[11:28, 19/11/2024] Tarun R Agarwal Sir Educobot: 1. Start by training your model in Google Teachable Machine.
2. Once training is complete, copy the model link provided.
3. Return to this window and paste the link in the input box.
4. Click 'Submit' to integrate the trained model with your project.
5. Use the model in your robotics or IoT setup to bring your project to life!
[11:29, 19/11/2024] Tarun R Agarwal Sir Educobot: Submit Button
Input Box
[11:31, 19/11/2024] Tarun R Agarwal Sir Educobot: Enter Teachable URL
[11:33, 19/11/2024] Tarun R Agarwal Sir Educobot: Create Project - Click here to create your project with the selected development environment.
[11:35, 19/11/2024] Tarun R Agarwal Sir Educobot: Heading On Project Page - Project Overview
[11:43, 19/11/2024] Tarun R Agarwal Sir Educobot: Tool Tip - 
Project Name
Project Description
Time Since Creation
Hardware in Use - View all hardware components used in your project here.
Hardware Status - View the current status of your hardware: Online, Offline, Active, or Inactive.
[11:46, 19/11/2024] Tarun R Agarwal Sir Educobot: Data Transmission Frequency - This graph shows the frequency of data transmission over time, helping you monitor performance.
[11:48, 19/11/2024] Tarun R Agarwal Sir Educobot: Last data received at [timestamp].
[11:59, 19/11/2024] Tarun R Agarwal Sir Educobot: Home Icon - Go to Project Overview.
Circuit icon - Go to Circuit Designer.
Code Icon - Code your project logic using the blocks editor or switch to syntax.
Monitor Icon - Add monitoring options for your project using available widgets based on your needs.
AI Icon - Code your project logic using trained AI models.
Setting Icon - Modify project name, description, share access (private or public), or delete your project.
[12:01, 19/11/2024] Tarun R Agarwal Sir Educobot: Toggle Button - Toggle to switch project sharing between public and private.
[12:03, 19/11/2024] Tarun R Agarwal Sir Educobot: Private - Set to 'Private' to disable access for others; only you can view the project.

Public - Set to 'Public' to allow anyone with the link to view and monitor the project.
[12:05, 19/11/2024] Tarun R Agarwal Sir Educobot: Click to copy the project link and share it with others.
[12:07, 19/11/2024] Tarun R Agarwal Sir Educobot: Delete Project - Click to permanently delete this project.
[12:16, 19/11/2024] Tarun R Agarwal Sir Educobot: Monitor Button:
Switch between Your Monitor and Public Monitor view.

Save Icon:
Save your changes to the monitor dashboard.

Reset Button:
Revert to the last saved monitor settings.
[12:21, 19/11/2024] Tarun R Agarwal Sir Educobot: Switch:
"Toggle between two states (On/Off) with this switch."

Gauge:
"Display values on a gauge for visual tracking."

Label:
"Use this label to display dynamic text on the screen."

Text Input:
"Allow users to enter text or data with this input field."

Comment:
"Add comments or notes for context on your monitor."

Line Chart:
"Visualize data trends over time with a line chart."

Bar Chart:
"Visualize data trends over time with bar chart."

Images:
"Embed and display images in your monitor dashboard."

Maps:
"Show device location data on maps on your dashboard."
[12:24, 19/11/2024] Tarun R Agarwal Sir Educobot: Core Components - View and manage the microcontrollers and shields for your project.
Input Devices - Browse and add input sensors that detect environmental data.
Output Devices - Explore and select output sensors to trigger actions based on data.
Wiring & Connectors - Access cables and connectors needed to assemble your circuit.
[12:28, 19/11/2024] Tarun R Agarwal Sir Educobot: Save Button - Save your circuit changes and create or modify project variables
[12:29, 19/11/2024] Tarun R Agarwal Sir Educobot: Reset - Reset to the last saved circuit design.
[12:34, 19/11/2024] Tarun R Agarwal Sir Educobot: When in Block Mode:
"Toggle to Syntax Mode."

When in Syntax Mode:
"Toggle to Block-Based Coding."
[12:35, 19/11/2024] Tarun R Agarwal Sir Educobot: When hovering over WiFi:
"Switch to WiFi flashing mode."

When hovering over Cable:
"Switch to Cable flashing mode."
[12:38, 19/11/2024] Tarun R Agarwal Sir Educobot: for Save Icon - Save your code or create and modify variables.
[12:39, 19/11/2024] Tarun R Agarwal Sir Educobot: Reset - Reset your code to the last saved version.

*/