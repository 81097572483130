import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../context/UserContext'
import { SettingContext } from '../../../context/SettingsContext'
import { Button, Card, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { Languages } from '../../../utils/Languages'
import { BackendConfigs } from '../../../config.environment'
import { toast } from 'react-toastify'

type Props = {}





function LanguageSettings({ }: Props) {
    const { user } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const [currentLanguage, setCurrentLanguage] = useState<string>()
    async function getCurrentLanguage() {
        try {
            const response = await fetch(`${BackendConfigs.educobotBackend}/users/get_user_language`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    userID: user.userData?.id
                })
            })
            const data = await response.json() as {
                _id: string,
                defaultLanguage: string
            }
            setCurrentLanguage(data.defaultLanguage)
        } catch (error) {
            // console.log(error);
        }
    }
    async function changeCurrentLanguage(language: string) {
        try {
            const response = await fetch(`${BackendConfigs.educobotBackend}/users/update_user_language`, {
                method: "POST",
                headers: {
                    "Content-Type": "Application/json"
                },
                body: JSON.stringify({
                    "userID": user.userData?.id,
                    "languageName": language
                })
            })

            if (response.status === 200) {
                setCurrentLanguage(language)
                toast.success("Language changed Successfully",)

            } else {
                toast.error("Language change failed")
            }
        } catch (error) {
            // console.log(error);
            toast.error("Language change failed")
        }
    }
    useEffect(() => {
        getCurrentLanguage()
    }, [user])
    return (
        <div>
            <Card style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "10px",
                padding: "10px",
                margin: "10px",
                minHeight: "200px",
            }}>
                {
                    currentLanguage &&
                    <FormControl fullWidth>
                        <h4 style={{
                            padding: "10px"
                        }}>Select you Preferred Language</h4>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            defaultValue={currentLanguage}
                            value={currentLanguage}
                            label="Language"
                            onChange={(event: SelectChangeEvent) => {
                                setCurrentLanguage(event.target.value as string);
                            }}
                        >
                            {
                                Languages.map((language, index) => {
                                    return <MenuItem value={language.value}>{language.label}</MenuItem>
                                })
                            }
                        </Select>
                        <div style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "end",
                            padding: "20px"
                        }}>

                            <Button variant='contained' onClick={() => {
                                changeCurrentLanguage(currentLanguage as string)
                            }} style={{
                                textTransform: "none"
                            }}>
                                Save Preference
                            </Button>
                        </div>
                    </FormControl>
                }

            </Card>


        </div>
    )
}

export default LanguageSettings