import { Button, Tooltip } from '@mui/material';
import * as React from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import CodeIcon from '@mui/icons-material/Code';
import MonitorIcon from '@mui/icons-material/Monitor';
import MemoryIcon from '@mui/icons-material/Memory';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { routes } from '../../../../../routes/routes';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import { ProjectDataType } from '../../../../../types/Dashboard';
import DashboardIcon from '@mui/icons-material/Dashboard';
export interface Props {
    projectData: ProjectDataType;
}

export function ProjectBottomBarComponent({
    projectData
}: Props) {
    const navigate = useNavigate()
    return (
        <div id='ProjectNavigation' style={{
            position: "fixed",
            bottom: "0",
            height: "50px",
            border: "1px solid #00000022",
            width: "min(100%,500px)",
            maxWidth: "100vw",
            left: "50%",
            transform: "translate(-50%,0)",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            gap: "5px",
            zIndex: 999999,
            background: "#0000001A",
            padding: "5px",

        }}>

            <Tooltip  title="Dashboard">

                <Button id='Home' variant={window.location.href.includes("description") ? "outlined" : undefined} style={{
                    background:"white"
                }} onClick={() => {
                    navigate(routes.DASHBOARD.MAIN);
                }}>
                    <DashboardIcon />
                </Button>
            </Tooltip>
            <Tooltip  title="Home">

                <Button id='Home' variant={window.location.href.includes("description") ? "outlined" : undefined} style={{
                    background:"white"
                }} onClick={() => {
                    navigate(routes.DASHBOARD.PROJECT.DESCRIPTION(projectData.project._id));
                }}>
                    <HomeIcon />
                </Button>
            </Tooltip>
            {
                projectData.project.editors.includes("circuit-designer") && <>
                    <Tooltip  title="Circuit Designer">
                        <Button id='CircuitDesigner' variant={window.location.href.includes("circuit") ? "outlined" : undefined} style={{
                            background:"white"
                        }} onClick={() => {
                            navigate(routes.DASHBOARD.PROJECT.WORKSPACE.CIRCUIT(projectData.project._id));
                        }}>
                            {/* <MemoryIcon /> */}
                            <img src="https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/Circuit.svg" width={25} alt="" />
                        </Button>
                    </Tooltip>

                </>
            }

            {
                projectData.project.editors.includes("micropython-editor") && <>
                    <Tooltip  title="Micropython Code Editor">

                        <Button id='MicroPythonCodeEditor' variant={window.location.href.includes("micropython") ? "outlined" : undefined} style={{
                            background:"white"
                        }} onClick={() => {
                            navigate(routes.DASHBOARD.PROJECT.WORKSPACE.MICROPYTHON(projectData.project._id));
                        }}>
                            {/* <CodeIcon /> */}
                            <img src="https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/IDE.svg" width={25} alt="" />
                        </Button>
                    </Tooltip>

                </>
            }

            {
                projectData.project.editors.includes("iot-monitor") && <>
                    <Tooltip  title="IOT Monitor">

                        <Button id='IOTMonitor' variant={window.location.href.includes("monitor") ? "outlined" : undefined} style={{
                            background:"white"
                        }} onClick={() => {
                            navigate(routes.DASHBOARD.PROJECT.WORKSPACE.MONITOR(projectData.project._id));
                        }}>
                            <MonitorIcon />
                        </Button>
                    </Tooltip>

                </>
            }
            {
                projectData.project.editors.includes("ai-editor") && <>
                    <Tooltip  title="AI">

                        <Button id='AIEditor' variant={window.location.href.includes("ai") ? "outlined" : undefined} style={{
                            background:"white"
                        }} onClick={() => {
                            navigate(routes.DASHBOARD.PROJECT.WORKSPACE.AI(projectData.project._id));
                        }}>
                            <SmartToyIcon />
                        </Button>
                    </Tooltip>

                </>
            }
            <Tooltip  title="Settings">

                <Button id='Settings' variant={window.location.href.includes("settings") ? "outlined" : undefined} style={{
                    background:"white"
                }} onClick={() => {
                    navigate(routes.DASHBOARD.PROJECT.SETTINGS(projectData.project._id));
                }}>
                    <SettingsIcon />
                </Button>
            </Tooltip>
        </div>
    );
}
