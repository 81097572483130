import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as tmImage from '@teachablemachine/image';
import GraphComponent from '../../../../components/graph';
import { routes } from '../../../../routes/routes';
import { ModelType } from '../../../../types/Database';
import { UserContext } from '../../../../context/UserContext';
import { BackendConfigs } from '../../../../config.environment';
import { eduCOBOTColorScheme, SettingContext } from '../../../../context/SettingsContext';
import { Button, Grid, Tooltip, Typography } from '@mui/material';
import { APIResponse } from '../../../../types/Response';
import { formatDistance } from 'date-fns';
import DashboardIcon from '@mui/icons-material/Dashboard';

type Props = {}


export function getOutput(preview: any) {
    if (preview && preview.forEach) {
        let maxPercentage = 0
        let output = ""
        preview.forEach((previewItem: any) => {
            if (previewItem.probability > maxPercentage) {
                maxPercentage = previewItem.probability
                output = previewItem.className
            }
        })
        return output
    }
}

const size = 280
const interval = 100
const preview = true
export default function ModelWorkSpacePage({ }: Props) {
    const { modelId } = useParams()
    const { user, changeUser, } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)

    const navigate = useNavigate()
    const [model_url, setUrl] = useState<string | null>()
    const [prediction, setPrediction] = useState<any>(null);
    const previewRef = React.useRef<HTMLDivElement>();
    const requestRef = React.useRef<number>();
    const intervalRef = React.useRef<any>();

    const [modelData, setModelData] = React.useState<ModelType>()


    async function getModel() {
        handleGlobalLoading(true)
        if (user.isLogin && modelId) {
            console.log({
                userId: user.userData?.id,
                modelId: modelId,
            });

            try {
                const response = await fetch(`${BackendConfigs.url}/GetModelByAndModelIdAPI`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        modelId: modelId,
                    })
                })
                if (response.status === 200) {
                    const data = await response.json() as APIResponse
                    console.log(data);
                    if (data.success) {
                        !modelData && setModelData(data.data)
                    } else {
                        navigate(routes.DASHBOARD.MODELS)
                    }
                } else {
                    navigate(routes.DASHBOARD.MODELS)
                }
            } catch (error) {
                navigate(routes.DASHBOARD.MODELS)
                console.log(error);
            }
        }
        handleGlobalLoading(false)
    }
    useEffect(() => {
        if (user.isLogin && modelId) {
            getModel()
        }
    }, [modelId, user])
    async function init() {
        handleGlobalLoading(true)
        if (!!modelData) {
            const modelURL = modelData.fileUrls.modelJson;
            const metadataURL = modelData.fileUrls.modelMetaData;
            const model = await tmImage.load(modelURL, metadataURL);
            console.log(model);

            const flip = true;
            const webcam = new tmImage.Webcam(size * 1.5, size, flip);
            await webcam.setup({
                aspectRatio: 1.5,
            });
            try {
                await webcam.play();
            }
            catch (e) {
                console.log(e);
            }
            if (interval === null) {

                requestRef.current = window.requestAnimationFrame(loop);
            } else {
                intervalRef.current = setTimeout(loop, interval) as any;
            }
            if (preview) {
                previewRef.current && previewRef.current.replaceChildren(webcam.canvas);
            }
            async function loop() {
                if (webcam === null) { } else {
                    webcam.update();
                    await predict();
                }
                if (interval === null) {
                    requestRef.current = window.requestAnimationFrame(loop);
                } else {
                    intervalRef.current = setTimeout(loop, interval);
                }
            }
            async function predict() {
                const prediction = await model.predict(webcam.canvas);
                setPrediction(prediction);
                console.log(prediction);
            }
        }
        setTimeout(() => {
            handleGlobalLoading(false)
        }, 2000)
    }

    useEffect(() => {
        if (!!modelData) {
            init();
        }
        return () => {
            // if (interval === null) {
            cancelAnimationFrame(requestRef.current as number);
            // } else {
            clearTimeout(intervalRef.current);
            // }
            (document.querySelector('#webcam-container') as HTMLDivElement) && (document.querySelector('#webcam-container') as HTMLDivElement).firstChild?.remove();
        }
    }, [modelData]);
    useEffect(() => {
        return () => {
            console.log("unmounting", intervalRef.current);

            clearTimeout(intervalRef.current);
            cancelAnimationFrame(requestRef.current as number);
        }
    }, [])
    let label = [] as any[];
    function getCameraComponent() {
        return React.createElement("div", null, label, React.createElement("div", {
            id: "webcam-container",
            ref: previewRef,

        }));
    }
    return (
        <div>
            <div style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flex: "1",
                overflow: "hidden",
            }}>


                <Grid columns={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }} container spacing={0}>
                    <Grid item xs={2} sm={2} md={1} lg={1} xl={1} >
                        <div style={{
                            display: "flex",
                            justifyContent: settings.screen === "mobile" ? "center" : "end",
                            // alignItems: "center",
                            height: "100%",
                            width: "100%",
                        }}>






                            <Typography style={{
                                minWidth: "300px",
                                border: "1px solid gray",
                                padding: "10px",
                                height: "100%",
                                borderRight: settings.screen === "mobile" ? "inherit" : "none"
                            }}>
                                <h1>
                                    {modelData?.name}
                                </h1>
                                <p>
                                    {modelData?.description}
                                </p>
                                <p style={{
                                    color: eduCOBOTColorScheme.gray,
                                    fontSize: 10
                                }}>
                                    Created {modelData && formatDistance(new Date(parseInt(modelData.createdAt)), new Date(), { addSuffix: true })}
                                </p>
                                <h4 style={{
                                    color: eduCOBOTColorScheme.gray, fontSize: 15,
                                    marginTop: 20
                                }}>Class List on which trained</h4>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "start",
                                    flexWrap: "wrap",
                                    flexDirection: "column",
                                }}>
                                    {
                                        modelData && modelData.labels.map((label, index) => (
                                            <Button size='small' variant='outlined' sx={{
                                                margin: "5px"
                                            }}>{label}</Button>
                                        ))
                                    }
                                </div>
                                <div style={{
                                    position: "absolute",
                                    bottom: "0",
                                    left: "0",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "5px",
                                }}>
                                    <Tooltip title="Go to Dashboard">

                                        <Button onClick={()=>{
                                            navigate(routes.DASHBOARD.MAIN)
                                        }}>
                                            <DashboardIcon />
                                        </Button>
                                    </Tooltip>
                                </div>
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={2} sm={2} md={1} lg={1} xl={1} >
                        <div style={{
                            // borderRadius: "10px",
                            border: "1px solid gray",
                            width: "min-content",
                            overflow: "hidden",
                            borderLeft: "none",
                            height: "100%",

                        }}>
                            <div style={{
                                height: size,
                                width: size * 1.5,
                                border: "1px solid gray"
                            }}>

                                {
                                    preview && getCameraComponent()
                                }
                            </div>
                            {
                                !settings.globalLoading.loading &&
                                <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                }}>
                                    <Button size='small' variant='outlined' sx={{
                                        margin: "10px",
                                        textTransform: "none",
                                        backgroundColor: "white",
                                    }}>{getOutput(prediction)}</Button>
                                </div>
                            }
                            <div style={{
                                maxWidth: size * 1.5
                            }}>
                                {
                                    !!prediction && !settings.globalLoading.loading && <GraphComponent height={200} data={
                                        prediction.map((value: any) => { return parseInt((value.probability * 100).toFixed(2)) })
                                    }
                                        labels={
                                            prediction.map((value: any) => { return value.className })
                                        }
                                        yLabel='% probability'
                                        graphType="bar"
                                    // xLabel='classes'
                                    />
                                }
                            </div>
                        </div>

                    </Grid>

                </Grid>

            </div>
        </div>
    )



}