import { createContext, useReducer, Dispatch, useEffect, useState } from "react"
import { defaultSettingsData, getSettingsData, setSettingsData } from "../services/LocalStorage/LocalSettings"
import { ToastContainer } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import { framerAnimation } from "../theme/animation/MotionConfigs";
import { Hourglass } from "react-loader-spinner";
import { Alert, AlertTitle, Container } from "@mui/material";
import GlobalLoaderComponent from "../components/loaders/ContainerLoaders";

export type SettingStateType = {
    themeMode: "light" | "dark",
    themeScheme: 0 | 1 | 2 | 3,
    screen: "desktop" | "tab" | "mobile",
    language: string,
    globalLoading: {
        loading: boolean,
        message: string,
        loadingType: string,
    }
}
type SettingActionType = {
    type: "themeMode" | "screen" | "language" | "themeScheme" | "handleGlobalLoading",
    value: string | 0 | 1 | 2 | 3 | boolean | {
        loading: boolean, message: string, loadingType: string
    }
}

function getScreenType() {
    if (!!window) {
        if (window.innerWidth > 1100) return "desktop"
        if (window.innerWidth > 768) return "tab"
        if (window.innerWidth > 300) return "mobile"
    }
    return "desktop"
}


export const eduCOBOTColorScheme = {
    "blue": "#00a2e6",
    "green": "#00c849",
    "orange": "#e08746",
    "red": "#d74357",
    "purple": "#78416b",
    "gray": "#637381",
    "black": "#000000",
    "white": "#ffffff",
    "light_gray": "#DDDDDD",
}


export const eduCOBOTColor = [
    "#DDDDDD",
    "#84878A",
    "#848700",
    "#0FBD8C",
    "#A55B80",
    "#4C97FF",
    "#EA0075",
    "#205273",
    "#006400",
    "#a3d5ff",
    "#d3a1a1",
    "#f25793",
    "#0b7669",
    "#0092a6",
    "#ba6d00",
    "#990000",
    "#9f2c79",
    "#b76e79",
    "#316ccd",
    "#400c16",
    "#142c53",
    "#006455",
    "#ba6d55",
]


export function getRandomValueForEducobotColors() {
    const random = Math.floor((Math.random()) * eduCOBOTColor.length)
    return eduCOBOTColor[random]
}




const initialState: SettingStateType = {
    themeMode: "light",
    themeScheme: 0,
    screen: getScreenType(),
    language: "english",
    globalLoading: {
        loading: false,
        message: "Processing.....",
        loadingType: "global",
    }

}

function settingsReducer(state: SettingStateType, action: SettingActionType): SettingStateType {
    const currentSettingsData = getSettingsData() || defaultSettingsData
    switch (action.type) {
        case "themeMode":
            setSettingsData({ ...currentSettingsData, themeMode: action.value as typeof state.themeMode })
            return { ...state, themeMode: action.value as typeof state.themeMode }
        case "screen":
            return { ...state, screen: action.value as typeof state.screen }
        case "handleGlobalLoading":
            return { ...state, globalLoading: { loading: (action.value as typeof state.globalLoading).loading, message: (action.value as typeof state.globalLoading).message, loadingType: (action.value as typeof state.globalLoading).loadingType } }
        case "language":
            const currentSettings = getSettingsData() || defaultSettingsData
            setSettingsData({ ...currentSettings, language: action.value as string })
            return { ...state, language: action.value as string }
        case "themeScheme":
            setSettingsData({ ...currentSettingsData, themeScheme: action.value as typeof state.themeScheme })
            return { ...state, themeScheme: action.value as typeof state.themeScheme }
        default:
            return state
    }
}

export const SettingContext = createContext({
    settings: initialState,
    changeSettings: (() => { }) as Dispatch<SettingActionType>,
    toggleTheme: () => { },
    changeThemeScheme: () => { },
    handleGlobalLoading: (value: boolean, message?: string) => { }
})

export default function SettingContextProvider(props: {
    children: React.ReactNode
}) {
    const [settings, changeSettings] = useReducer(settingsReducer, initialState)
    const [internetStatus, setInternetStatus] = useState<boolean>(true)
    function checkSettings() {
        const settingData = getSettingsData()
        if (settingData) {
            changeSettings({ type: "language", value: settingData.language })
            changeSettings({ type: "themeMode", value: settingData.themeMode })
            changeSettings({ type: "themeScheme", value: settingData.themeScheme })
        } else {
            setSettingsData(initialState)
        }
    }
    useEffect(() => {
        if (!window.navigator.onLine) setInternetStatus(false)
        else setInternetStatus(true)
        window.addEventListener('online', () => setInternetStatus(true))
        window.addEventListener("offline", () => setInternetStatus(false))
        window.addEventListener('resize', () => {
            changeSettings({
                type: "screen",
                value: getScreenType()
            })
        })
        checkSettings()
    }, [])
    function toggleTheme() {
        const currentSettingsData = getSettingsData() || defaultSettingsData
        changeSettings({ type: "themeMode", value: settings.themeMode === "light" ? "dark" : "light" })
        setSettingsData({ ...currentSettingsData, themeMode: settings.themeMode === "light" ? "dark" : "light" })
    }
    function changeThemeScheme() {
        const currentSettingsData = getSettingsData() || defaultSettingsData
        changeSettings({ type: "themeScheme", value: (settings.themeScheme + 1) % 4 as typeof settings.themeScheme })
        setSettingsData({ ...currentSettingsData, themeScheme: (settings.themeScheme + 1) % 4 as typeof settings.themeScheme })
    }
    function handleGlobalLoading(value: boolean, message = "Processing....", loadingType = "global") {
        changeSettings({ type: "handleGlobalLoading", value: { loading: value, message: message as string, loadingType: loadingType } })
    }


    return (
        <SettingContext.Provider value={{ settings, changeSettings, toggleTheme, changeThemeScheme, handleGlobalLoading }}>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme={settings.themeMode === "dark" ? "dark" : "colored"}
                style={{
                    fontSize: "10px",
                    zIndex: 99999999999
                }}
            />
            {
                settings.globalLoading.loading && <GlobalLoader message={settings.globalLoading.message} />
            }
            {
                <InternetStatus key={internetStatus ? "1" : "0"} status={internetStatus} />
            }
            {props.children}
        </SettingContext.Provider>
    )
}

function InternetStatus({ status }: { status: boolean }) {
    const [isAlwaysShow, setIsAlwaysShow] = useState<boolean>(true)
    useEffect(() => {
        if (status) {
            setTimeout(() => {
                setIsAlwaysShow(false)
            }, 3000)
        } else {
            setIsAlwaysShow(true)
        }
    }, [status])
    if (!isAlwaysShow) return null
    return <div style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        zIndex: 99999999999999,
        width: "100vw",
        height: "10vh",
    }}>
        <Container>

            {
                status ? <Alert severity="success">
                    You are online
                </Alert> : <Alert variant='filled' severity="error">
                    You are offline
                </Alert>
            }
        </Container>
    </div>
}



function GlobalLoader({ message }: { message: string }) {
    return <div style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.1)",
        zIndex: 100,
        width: "100vw",
        height: "100vh"
    }}>
        <GlobalLoaderComponent message={message} />
    </div>
}
