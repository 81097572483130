import * as React from 'react';
import DashboardLayout from '../../../layouts/dashboard/DashboardLayout';
import Models from './Models';
import ModelsListComponent from './ModelsListComponent';

export type DashboardModelsPageProps = {
}

export default function DashboardModelsPage(props: DashboardModelsPageProps) {
    return (
        <div>
            <DashboardLayout title='Dashboard'>
                <ModelsListComponent/>
            </DashboardLayout>
        </div>
    );
}
